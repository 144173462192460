<template>
    <div>
        <b-card class="cardHome">
            <template #header>
                <span class="cardHeader">Setze dein neues Passwort </span>
            </template>
            <b-card-text>
                Besser, du änderst dein zufälliges Passwort ...
            </b-card-text>   
            <b-card-text>                
                <div class="input-group mb-2">                    
                <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-key"></i></span>
                </div>
                <input type="password" name="password" class="form-control input_pass" v-model="form.password" placeholder="Passwort" v-on:keyup.enter="setPasswordButtonClick">
            </div>
            <div class="input-group mb-2">
                <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-key"></i></span>
                </div>
                <input type="password" name="repeatPassword" class="form-control input_pass" v-model="form.repeatPassword" placeholder="Passwort wiederholen" v-on:keyup.enter="setPasswordButtonClick">
            </div>
            <div v-if="showError">
                <p>{{error}}</p>
            </div>
            </b-card-text>
            <template #footer>
                <div class="align-r">
                    <b-button variant='outline-secondary' @click="keepPasswordButtonClick">Zufällig gesetztes Passwort behalten</b-button>
                    <b-button class="buttonStandard ml-1" @click="setPasswordButtonClick">Passwort ändern</b-button>
                </div>
            </template>                               
        </b-card>
    </div>
</template>

<script>
import api from '@/services/api'

export default {
    name: 'SetPassword',
    // components: { ComponentName },
    // directives: { DirectiveName },
    props : ['user'],

    data() {
        return {
            form : {
                password : '',
                repeatPassword : '',
            },
            error : null,
        };
    },

    computed : {
        showError : function(){
            return !!this.error;
        }

        
    },

    async mounted() {
    },

    methods: {
        async setPasswordButtonClick(){
            if (this.form.password.length < 1)
            {
                this.error = 'Bitte gib ein neues Passwort ein.';
                return
            }
            if (this.form.password !== this.form.repeatPassword)
            {
                this.error = 'Die Passwörter stimmen nicht überein.';
                return
            }
            await api.patch("user/" + String(this.user._id),{password : this.form.password})
            .then(res => {
                console.log(res);
                this.$store.dispatch("updateRouterView");
            })
            .catch(error => {
                console.log(error.response)
                this.error = error.response.data.errors;                
            })
        },

        async keepPasswordButtonClick(){
            await api.patch("user/" + String(this.user._id),{isRandomPassword : false})
            .then(res => {
                console.log(res);
                this.$store.dispatch("updateRouterView");
            })
            .catch(error => {
                console.log(this.error.response)                
            })
        },
        
    },
};
</script>

<style lang="scss" scoped>

</style>