<template>
    <b-container fluid class="containerHome">
        <b-row>
            <b-col>
                <div class="mt-5 mb-5 ml-2 mr-2 cardHeader" style="font-size:1.8rem; text-align: left;">
                    <b>{{this.$store.getters.groupName}}</b>
                </div>
            </b-col>
        </b-row>

        <OtherGroups v-if="dispOtherGroups"/>

        <b-row>
            <b-col>
                <b-card no-body class="cardHome mt-1">
                    <template #header>
                        <div class="mb-0 cardHeader" style="">Quizer:innen</div>
                        <!-- <div class="mb-0 cardHeader" style="">{{$store.getters.groupName}}</div> -->
                    </template>
                    
                    <b-list-group>
                        <b-list-group-item v-bind:class="{ LastListGroupItem: !$store.getters.isGroupAdmin }">
                        <b-row>
                            <b-col>
                                <div v-for="member in groupAdmins" :key="member._id" class="mb-1">
                                    <b-avatar size="35px" class="avatarAdmin">
                                        {{member.prename.charAt(0) + member.surname.charAt(0)}}
                                    </b-avatar>
                                    {{member.prename + ' ' + member.surname}}
                                    <b-button v-if="showUnAssignAdminIcon(member._id)" class="buttonReject buttonRound ml-1" style="width: 34px; height:34px;" @click="toggleGroupAdmin(member._id)">
                                        <b-icon-arrow-down/>
                                    </b-button>
                                </div>
                                <div v-for="member in groupMembers" :key="member._id" class="mb-1">
                                    <b-avatar size="35px" class="avatarMember">
                                        {{member.prename.charAt(0) + member.surname.charAt(0)}}
                                    </b-avatar>
                                    {{member.prename + ' ' + member.surname}}
                                    <b-button v-if="$store.getters.isGroupAdmin" class="buttonConfirm buttonRound ml-1" style="width: 34px; height:34px;" @click="toggleGroupAdmin(member._id)">
                                        <b-icon-arrow-up/> 
                                    </b-button>
                                    <b-button v-if="$store.getters.isGroupAdmin" class="buttonRound buttonReject ml-1" style="width: 34px; height:34px;" @click="removeUserButtonClick(member)">
                                        <b-icon-door-open/>
                                    </b-button>
                                </div>  
                            </b-col>
                        </b-row>
                        
                                                                                    
                        </b-list-group-item>
                        
                        <b-list-group-item v-if="$store.getters.isGroupAdmin" class="LastListGroupItem">
                            <b-card-text>
                                Um weitere Quizzer einzuladen kopiere diesen Link und schicke ihn an deine Freunde:       
                            </b-card-text>
                            <b-input-group>
                                <b-form-input type="url" readonly :value="inviteLink"></b-form-input>
                                <b-input-group-append>
                                    <b-button class="buttonStandard" v-clipboard:copy="inviteLink"><b-icon-clipboard/></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-list-group-item>                        
                    </b-list-group>

                    <template #footer v-if="!$store.getters.isGroupAdmin">
                        <div class="align-r">
                            <b-button @click="showLeaveGroupModal" class="buttonReject ml-1">Quizgruppe verlassen</b-button>
                        </div>  
                    </template>
                </b-card>
            </b-col>
        </b-row>
        <!-- <b-row>
            <b-col>
                <b-card v-if="dispOtherGroups" class="cardHome">
                    <template #header>
                        <div class="mb-0 cardHeader" style="">
                            <b-row no-gutters>
                                <b-col>Meine weiteren Quizgruppen</b-col>
                                <b-col cols="auto" style="text-align: right;"><b-button class="buttonStandardSolid" @click="showNewGroupModal"><b-icon-plus-circle/></b-button></b-col>
                            </b-row>
                        </div>
                    </template>
                     <div v-for="group in otherGroups" :key=group._id>                
                        <b-button @click="goToGroupClick(group._id)" class="mb-1 buttonStandard buttonFullWidth">{{group.name}}</b-button>
                    </div>
                </b-card>                
            </b-col>
        </b-row>-->
 
        <CreateGroup />


    
        <!-- Modal for leave group -->
        <b-modal ref="leaveGroupModal" hide-footer title="Quizgruppe verlassen">                  
            <div>
                <p>Das ist aber schade ...</p>
                <p>Bist du sicher, dass du die Quizgruppe <b>{{this.$store.getters.groupName}}</b> verlassen möchtest? Über den Einladungslink kannst du jederzeit wiederkommen!</p>

            </div>
            <b-button class="buttonReject" block @click="leaveGroupConfirm">Ja, Quizgruppe <b>{{this.$store.getters.groupName}}</b> verlassen.</b-button>
        </b-modal> 

        
        <!-- Modal for removing user -->
        <b-modal ref="removeUserModal" hide-footer title="Spieler:in rausschmeißen">                  
            <div>
                <p>Ok ...</p>
                <p>Bist du sicher, dass du <b>{{removeUserDisplayName}}</b> aus der Gruppe <b>{{this.$store.getters.groupName}}</b> schmeißen möchtest?</p>

            </div>
            <b-button class="buttonReject" block @click="removeUserConfirm">Ja, <b>{{removeUserDisplayName}}</b> rausschmeißen.</b-button>
        </b-modal> 
        

        

    </b-container>

</template>

<script>
import api from '@/services/api';
import CreateGroup from "@/components/CreateGroup.vue";
import OtherGroups from '@/components/OtherGroups.vue';
import PreviousQuizVue from '../components/QuizCard.vue';

export default {
    name: 'Groups',
    components: { CreateGroup, OtherGroups },
    // directives: { DirectiveName },
    data() {
        let link = 'https://www.der-supertolle-quizabend.de/invitation/' + String(this.$store.getters.currentGroupId);  // to be updated for production
        return {
            inviteLink : link,
            groupAdmins : null,
            groupMembers : null,
            otherGroups : null,
            dispOtherGroups : false,

            removeUser : null,
            
        };
    },

    computed: {   
        removeUserDisplayName : function() {
            return this.removeUser ? this.removeUser.prename + ' ' + this.removeUser.surname : ' ';
        },
    },

    async mounted() {  
        await api.get("myGroups").then(async (res) => {                    
            this.otherGroups = res.data.filter(group => String(group._id) !== String(this.$store.getters.currentGroupId));
            if (this.otherGroups.length > 0){
                // this.dispOtherGroups = true;
                this.dispOtherGroups = false;
            }
        });
        this.updateMemberLists();    
    },

    methods: {
        showLeaveGroupModal() {
            this.$refs['leaveGroupModal'].show();
        },

        goToGroupClick(id){
            api.get('/selectCurrentGroup/' + String(id))
            .then(async (res) => {                
                await this.$store.dispatch("updateCurrentGroupAction");
                this.$router.push('/');
            });            
        },

        async leaveGroupConfirm(){
            let id = this.$store.getters.currentGroupId;
            await api.get('leaveGroup/' + String(id))
            .then(async (res) => {

                await this.$store.dispatch('updateCurrentGroupAction')           
                await this.$store.dispatch('updateMyGroupsAction')                

                this.$router.push('/');
            })
            .catch(error => console.log(error.response)); 
            
        },

        async removeUserConfirm(){
            let memberIds = this.$store.getters.currentGroup.members.map(member => member._id);
            console.log('memberIds', memberIds)
            let i = memberIds.indexOf(this.removeUser._id);
            memberIds.splice(i,1);
            console.log('memberIds', memberIds)

            await api.patch('group/' + String(this.$store.getters.currentGroupId) , {members : memberIds})
            .then(async (res) => {
                console.log(res);                
                await this.$store.dispatch("updateCurrentGroupAction");
                this.$store.dispatch("updateRouterView");
            })
            .catch(error => {
                console.log(error.response)                          
            })
        },

        removeUserButtonClick(user){
            this.removeUser = user;
            this.$refs['removeUserModal'].show();
        },

        showUnAssignAdminIcon(id){
            if (this.$store.getters.isGroupAdmin && this.groupAdmins.length>1 && String(id) !== String(this.$store.getters.user._id)) {
                return true;
            } else {
                return false;
            }
        },

        updateMemberLists(){
            let allGroupMembers = this.$store.getters.currentGroup.members;
            this.groupMembers = allGroupMembers.filter(member => (this.$store.getters.currentGroup.admins.indexOf(member._id) === -1));
            this.groupAdmins = allGroupMembers.filter(member => (this.$store.getters.currentGroup.admins.indexOf(member._id) !== -1));
    
        },

        
        async toggleGroupAdmin(id){            
            await api.get('toggleGroupAdmin/' + String(id))
            .then(async (res) => {      
                console.log(res.data);     
                await this.$store.dispatch('updateCurrentGroupAction');
                this.updateMemberLists();            
            })
            .catch(error => {
                console.log(error.response)               
            })
            
        },

        
    },
};
</script>

<style lang="scss" scoped>

</style>