<template>    
    <div>     
        <div v-if="dispNoQuiz">
            <b-card class="cardHome">
                <template #header>
                    <span class="cardHeader">Nächster Quizabend</span>
                </template>
                <b-card-text>
                    Derzeit ist kein Quizabend in deiner Quizgruppe <b>{{this.$store.getters.groupName}}</b> angesetzt.<br>
                    Du kannst aber schon mal ein paar Fragen erstellen.
                </b-card-text>
                <template #footer>
                    <div class="align-r">
                        <b-button class="buttonStandard  mb-1" @click="postQuestionButtonClick">Zu meinen Fragen</b-button>
                        <b-button v-if="isGroupAdmin" class="buttonStandard ml-1 mb-1" @click="createQuizButtonClick">Neues Quiz ansetzen</b-button>
                    </div>
                </template>
            </b-card>
        </div>

        <div v-if="dispCurrentQuiz">
            <b-card class="cardHome" no-body>
                <template #header>
                    <span class="cardHeader">Nächster Quizabend</span>
                </template>
                <b-list-group flush>
                    <b-list-group-item>
                        <table width=100%>
                            <tr>
                                <td width=50px><b-icon-calendar-date></b-icon-calendar-date></td>
                                <td>{{dateString}}</td>
                            </tr>
                            <tr>
                                <td><b-icon-clock></b-icon-clock></td>
                                <td>{{timeString}}</td>
                            </tr>
                            <tr>
                                <td><b-icon-geo-alt></b-icon-geo-alt></td>
                                <td>{{locationString}}</td>
                            </tr>
                            <tr v-if="dispQuestionInfo">
                                <td><b-icon-question-octagon></b-icon-question-octagon></td>
                                <td>{{questionTargetPerPlayerString}}</td>
                            </tr>
                            <tr v-if="dispQuestionProgress">
                                <td><b-icon-question-circle @click="dispRegisteredUsers = !dispRegisteredUsers"></b-icon-question-circle> <b-icon-question-circle-fill @click="dispRegisteredUsers = !dispRegisteredUsers"></b-icon-question-circle-fill></td>
                                <td>
                                    <b-progress id="question-progress" :max="numberOfQuestionsTarget" >
                                        <b-progress-bar class="colorMain" :value="numberQuestions" show-value ></b-progress-bar>
                                    </b-progress>
                                </td>
                            </tr>
                            <tr>
                                <td><b-icon-person-fill @click="dispRegisteredUsers = !dispRegisteredUsers"></b-icon-person-fill> <b-icon-person @click="dispRegisteredUsers = !dispRegisteredUsers"></b-icon-person></td>
                                <td v-if="dispRegistrationProgress">
                                    <b-progress @click="dispRegisteredUsers = !dispRegisteredUsers" :max="numberRegistrationsTarget">
                                        <b-progress-bar class="colorMain" :value="numberRegistrations" show-value ></b-progress-bar>
                                    </b-progress>
                                </td>
                                <td v-else>{{numberRegistrations}} Anmeldung{{registrationsSuffix}}</td>
                            </tr>
                            <tr v-if="dispRegisteredUsers">
                                <td></td>
                                <td>
                                    <b>Am Start:</b>
                                    <div>
                                        <b-row class="ml-1" v-for="registration in registrations" :key="registration._id">                                            
                                            <b-col>
                                                <b-badge>{{numberOfpostedQuestions(registration.user._id)}} </b-badge>
                                                {{registration.user.prename}} {{registration.user.surname}}
                                            </b-col>
                                            
                                        </b-row>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </b-list-group-item>
                    <b-list-group-item v-if="registeredtoCurrentQuiz">
                        {{postedQuestionsString1}}<router-link to="/questions" class="login_link"> {{postedQuestionsString2}}</router-link>{{postedQuestionsString3}}
                    </b-list-group-item>    
                </b-list-group>
                <b-card-body style="padding: 0;"></b-card-body>
                <template #footer>
                    <div class="align-r">
                        <b-button v-if="isGroupAdmin" @click="editQuizButtonClick" class="buttonStandard ml-1 mb-1"><b-icon icon="pencil"/></b-button>
                        <b-button v-if="dispPlayLeadQuiz && isGroupAdmin" class="buttonStandard ml-1 mb-1" @click="leadQuizButtonClick">Leiten</b-button>
                        <b-button v-if="dispPlayLeadQuiz && registeredtoCurrentQuiz" class="buttonConfirm ml-1 mb-1" @click="playQuizButtonClick">Spielen</b-button>
                        <b-button v-if="registeredtoCurrentQuiz" @click="goToMyQuestionsButtonClick" class="ml-1 mb-1 buttonStandard">Fragen einreichen</b-button>
                        <b-button v-if="!registeredtoCurrentQuiz" @click="goToMyQuestionsButtonClick" class="ml-1 mb-1 buttonStandard">Zu meinen Fragen</b-button>
                        <b-button v-if="registeredtoCurrentQuiz" @click="deregisterButtonClick" class="buttonStandard ml-1 mb-1">Abmelden</b-button>
                        <b-button v-else @click="registerButtonClick" class="buttonConfirm ml-1 mb-1">Anmelden</b-button>
                    </div>
                </template>
            </b-card>

            <b-popover target="question-progress" triggers="hover" placement="topright">                
                {{questionProgressPopoverText}}
            </b-popover>
        </div>

        <div v-if="dispGoToActiveQuiz">
            <b-card class="cardHome">
                <template #header>
                    <span class="cardHeader">Laufendes Quiz</span>
                </template>
                <b-card-text>Ein Quiz in deiner Quizgruppe <b>{{this.$store.getters.groupName}}</b> ist gestartet. Nix wie hin!</b-card-text>
                <template #footer>
                    <div class="align-r">
                        <b-button v-if="registeredtoCurrentQuiz" @click="deregisterButtonClick" class="buttonReject ml-1 mb-1">Abmelden</b-button>
                        <b-button v-if="!registeredtoCurrentQuiz" @click="registerButtonClick" class="buttonConfirm ml-1 mb-1">Anmelden</b-button>
                        <b-button v-if="registeredtoCurrentQuiz" class="buttonConfirm ml-1 mb-1" @click="playQuizButtonClick">Quiz spielen</b-button>
                        <b-button v-if="isGroupAdmin" class="buttonStandard ml-1 mb-1" @click="leadQuizButtonClick">Quiz leiten</b-button>
                    </div>
                </template>
            </b-card>
        </div>
        
        <div v-if="dispCreateQuiz">
            <b-card class="cardHome">
                <template #header>
                    <span class="cardHeader">Quiz Ansetzung</span>
                </template>           
                <b-card-text>Hey Quizleiter, <br> nach dem Quiz ist vor dem Quiz. <br> Schon das nächste Quiz geplant?</b-card-text>
                <template #footer>
                    <div class="align-r">
                        <b-button class="buttonStandard" @click="createQuizButtonClick">Nächstes Quiz ansetzen</b-button>
                    </div>
                </template>
            </b-card>
        </div>
        
        
        <b-modal ref="registerModal" hide-footer title="Was ist mit deinen Fragen?">                  
            <div>
                <p>Du hast {{numberOfUnassignedQuestions}} nicht eingereichte Frage(n) in deinem Profil:</p>
                <ol>                    
                    <li v-for="question in myUnassignedQuestions" :key="question._id">
                        {{cutText(question.questionText, 100)}}
                    </li>
                </ol> 
            </div>
            <b-button class="buttonConfirm" block @click="assignMyQuestionsButtonClick">Fragen einreichen</b-button>
            <b-button class="buttonReject" block @click="closeRegisterModal">Fragen nicht einreichen</b-button>             
            <b-button class="buttonStandard" block @click="goToMyQuestionsButtonClick">Zu meinen Fragen</b-button>             
        </b-modal>
        
        <b-modal ref="deRegisterModal" hide-footer title="Quiz Abmeldung">                  
            <div>
                <p>Das ist aber schade ...</p>
                <p v-if="numberOfAssignedQuestions">{{deAssignQuestionsInfoString}}</p>                    
                <p>Bist du sicher?</p>                    
            </div>
            <b-button class="buttonReject" block @click="deRegisterModalButtonClick">Jo, bin leider raus.</b-button>
            <b-button class="buttonConfirm" block @click="closeDeRegisterModal">Ach quatsch, ich bin am Start!</b-button>                             
        </b-modal>      
    </div>
</template>

<script>
import api from '@/services/api'

export default {

    name: 'CurrentQuiz',
    props : ['quiz'],
    // components: { ComponentName },
    // directives: { DirectiveName },
    data() {
        return {            
            dispNoQuiz : false,
            dispCreateQuiz : false,
            dispCurrentQuiz : false,
            dispGoToActiveQuiz : false,

            dispRegistrationProgress : false,
            dispQuestionProgress : false,
            dispRegisteredUsers : false,
            
            registeredtoCurrentQuiz : false,

            questions : null,
            registrations : null,
            myQuestions : null,
        };
    },


    computed : {
       
        isGroupAdmin : function (){
            return (this.$store.getters.isGroupAdmin) ? true : false;
        },

        myUnassignedQuestions : function () {
            if (this.myQuestions){
                return this.myQuestions.filter(q => !q.quiz);
            }
            else {
                return null
            }
        },

        myAssignedQuestions : function () {
            if (this.myQuestions){
                return this.myQuestions.filter(q => q.quiz);
            }
            else {
                return null
            }
        },

        numberOfUnassignedQuestions : function(){
            if (this.myQuestions && this.myUnassignedQuestions.length > 0){
                return this.myUnassignedQuestions.length;
            }
            else {
                return 0;
            }
        },

        numberOfAssignedQuestions : function (){
            if (this.myQuestions && this.myAssignedQuestions.length > 0){
                return this.myAssignedQuestions.length;
            }
            else {
                return 0;
            }
        },        

        dateString: function() {
            let now = new Date();
            let quizDate = new Date(this.quiz.quizDate);         
            let daysLeft =  Math.floor((quizDate - now)/1000/24/3600);            
            const dateOptions = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'};            
            let quizDateString = quizDate.toLocaleDateString('de-DE', dateOptions); 
            return quizDateString; 
        },

        timeString: function() {
            let quizDate = new Date(this.quiz.quizDate);    
            return (String(quizDate.getHours()) + ':' + this.getMyMinutes + ' Uhr')
        },

        locationString: function(){
            if (this.quiz && this.quiz.location){
                return this.quiz.location + ', bei ' + this.quiz.hostUser.prename + ' ' + this.quiz.hostUser.surname
            }
            else {
                return this.quiz.hostUser.prename + ' ' + this.quiz.hostUser.surname;
            }
        },

        dispQuestionInfo : function (){
            return this.numberOfQuestionsTarget ? true : false;
        },        

        getMyMinutes : function (){
            let quizDate = new Date(this.quiz.quizDate);      
            let min = quizDate.getMinutes();
            return min < 10 ? ('0' + String(min)) : String(min);
        },

        postedQuestionsString1: function() {
            if (this.registeredtoCurrentQuiz){
                let postedQuestions = this.myQuestions.filter(question => String(question.quiz) === String(this.quiz._id));
                let n = postedQuestions? postedQuestions.length : 0;
                if (n>1) {
                    return ("Du hast bereits ")
                } else if (n === 1){
                    return ("Du hast bisher ")
                } else {
                    return ("Du hast noch ")
                }
            } else {
                return null
            }
        },

        postedQuestionsString2: function() {
            if (this.registeredtoCurrentQuiz){
                let postedQuestions = this.myQuestions.filter(question => String(question.quiz) === String(this.quiz._id));
                let n = postedQuestions? postedQuestions.length : 0;
                if (n>1) {
                    return (String(n) + " Fragen")
                } else if (n === 1){
                    return ("eine Frage")
                } else {
                    return ("keine Fragen")
                }
            } else {
                return null
            }
        },

        postedQuestionsString3: function() {
            if (this.registeredtoCurrentQuiz){
                return ' eingereicht.'
            } else {
                return null
            }
        },

        numberRegistrations: function() {
            return this.registrations ? this.registrations.length : 0;
        }, 

        numberRegistrationsTarget: function() {
            return this.quiz ? this.quiz.numberOfRegistrationsTarget : 0;
        },
        
        numberQuestions: function() {
            if (this.questions) {
                return this.questions.length;
            } else {
                return 0;
            }
            
        }, 

        numberOfQuestionsTarget: function() {
            return this.quiz.numberOfQuestionsTarget;
        },


        questionTargetPerPlayerString: function() {
            if (this.quiz.numberOfRegistrationsTarget) {
                let n = Math.ceil(this.quiz.numberOfQuestionsTarget/this.quiz.numberOfRegistrationsTarget);
                return "Fragenziel: " + String(this.quiz.numberOfQuestionsTarget) +  " gesamt, " + n + " pro Spieler:in"
            } 
            else{
                return String(this.quiz.numberOfQuestionsTarget) + " Fragen"
            }
        },

        deAssignQuestionsInfoString: function() {
            if (this.myAssignedQuestions)
            {
                if (this.myAssignedQuestions.length > 1){
                    return ('Deine ' + this.myAssignedQuestions.length + ' eingereichten Fragen werden in deinem Profil für spätere Quiz gespeichert.')
                } else {
                    return ('Deine eingereichte Frage wird in deinem Profil für spätere Quiz gespeichert.')
                }
            } else {
                return null;
            }
                
        },

        dispPlayLeadQuiz: function () {
            let now = new Date();
            let quizDate = new Date(this.quiz.quizDate);         
            let minLeft =  Math.floor((quizDate - now)/1000/60); 
            if (this.numberRegistrations > 2 && minLeft <= 120){
                return true;
            } else {
                return false;
            }
        },

        questionProgressPopoverText : function (){
            if (this.numberQuestions === 0){
                return 'Bisher wurden keine Fragen eingereicht.'
            }
            else {
                return (this.numberQuestions + ' von ' + this.numberOfQuestionsTarget + ' Fragen eingereicht.')
            }
        },

        registrationsSuffix : function(){
            return this.numberRegistrations === 1? '' : 'en';
        }
        
        
    },
   
    methods: {
        playQuizButtonClick() {
            this.$router.push("/PlayQuiz")
        },

        leadQuizButtonClick() {
            this.$router.push("/LeadQuiz")
        },
        
        createQuizButtonClick() {
            this.$router.push("/EditQuiz/new")
        },

        postQuestionButtonClick() {
            this.$router.push("/questions")
        },

        numberOfpostedQuestions(userId){
            let userQuestions = this.questions.filter(question => String(question.user) === String(userId));
            return userQuestions.length;
        },
        
        async registerButtonClick() {              
            await api.get("registerCurrentQuiz").then(async (res) => {
                    this.registeredtoCurrentQuiz = res.data.registered;
                    await api.get("quizRanking/" + String(this.quiz._id))
                    .then(res => {
                        this.registrations = res.data;
                        this.registrations.sort((a ,b) => {                
                            if ( a.user.prename > b.user.prename ){return +1;}
                            if ( a.user.prename < b.user.prename ){return -1;}
                        });
                    })
                    .catch(error => console.log(error.response));
                    await api.get("question?quiz=" + String(this.quiz._id)).then(res => this.questions = res.data).catch(error => console.log(error.response));
                    await api.get("myOpenQuestions").then(res => this.myQuestions = res.data).catch(error => console.log(error.response));
                })
                .catch(error => console.log(error.response));

            // if user has not assigned questions in his profile, ask to assign open questions
            if (this.myUnassignedQuestions.length) 
            {                
                this.$refs['registerModal'].show();                                
            } 
            
        },

        deregisterButtonClick() {
            this.$refs['deRegisterModal'].show();
        },

        async deRegisterModalButtonClick() {
            this.$refs['deRegisterModal'].hide();
            await api.get("deregisterCurrentQuiz").then(async (res) => {
                this.registeredtoCurrentQuiz = res.data.registered;
                await api.get("quizRanking/" + String(this.quiz._id)).then(res => this.registrations = res.data).catch(error => console.log(error.response));
                await api.get("question?quiz=" + String(this.quiz._id)).then(res => this.questions = res.data).catch(error => console.log(error.response));
                await api.get("myOpenQuestions").then(res => this.myQuestions = res.data).catch(error => console.log(error.response));                
            })
            .catch(error => console.log(error.response));
        },

        async assignMyQuestionsButtonClick(){
            await api.get("activateOpenQuestions").catch(error => console.log(error.response));
            await api.get("question?quiz=" + String(this.quiz._id)).then(res => this.questions = res.data).catch(error => console.log(error.response));
            await api.get("myOpenQuestions").then(res => this.myQuestions = res.data).catch(error => console.log(error.response));
            this.$refs['registerModal'].hide();  
        },

        goToMyQuestionsButtonClick(){
            this.$router.push('/questions')
        },

        closeRegisterModal () {
            this.$refs['registerModal'].hide();  
        },

        closeDeRegisterModal () {
            this.$refs['deRegisterModal'].hide();  
        },

        cutText (text, n) {
            if(text.length > n) {
                return (text.substring(0, n) + ' ...');
            } else {
                return text;
            }
        },

        editQuizButtonClick (){
            this.$router.push('EditQuiz/' + this.quiz._id)
        },
    },

    async mounted() {
                
       if (this.quiz){
            await api.get("currentQuizRegisterState").then(res => this.registeredtoCurrentQuiz = res.data.registered).catch(error => console.log(error.response));

            if (this.quiz.status === 'active' || this.quiz.status === 'evaluation'){
                this.dispGoToActiveQuiz = true;
            }

            await api.get("myOpenQuestions").then(res => this.myQuestions = res.data).catch(error => console.log(error.response));
            await api.get("quizRanking/" + String(this.quiz._id))
            .then(res => {
                this.registrations = res.data;
                this.registrations.sort((a ,b) => {                
                    if ( a.user.prename > b.user.prename ){return +1;}
                    if ( a.user.prename < b.user.prename ){return -1;}
                });
            })
            .catch(error => console.log(error.response));
                
            if (this.quiz.numberOfRegistrationsTarget){
                this.dispRegistrationProgress = true;
            }
            
            await api.get("question?quiz=" + String(this.quiz._id)).then(res => this.questions = res.data).catch(error => console.log(error.response));
            if (this.quiz.numberOfQuestionsTarget){
                this.dispQuestionProgress = true;
            }            
            if (this.quiz.status !== 'active'){
                this.dispCurrentQuiz = true; 
            }
            
        }

        if (!this.quiz){
            this.dispNoQuiz = true;            
        } 
        else if (this.$store.getters.isGroupAdmin) {
            // this.dispCreateQuiz = true;            
        }
      
        await this.$emit("ready");

        
    },
};
</script>

<style lang="scss" scoped>

</style>
