<template>
<div>   
    <div>    
        <b-card class="cardHome" >
        <template #header>
            <span class="cardHeader">Ewige Tabelle</span>
        </template>
        
        <!-- Header Row -->
        <b-row class="mb-1"> 
            <b-col cols = 7>
            </b-col>            
            <b-col cols = 1 class="text-right ml-3">
                
            </b-col>
            <b-col cols = 2 class="text-right">
                <b></b>
            </b-col>

        </b-row>

        <!-- Rable Rows -->
        <div>
            <b-row class="mb-1" v-for="user in tableData" :key="user.name">
            <b-col align-self="center"  cols="8">
                <b-avatar size="30px" class="avatarAdminSmall mr-1">
                    {{user.initials}}
                </b-avatar>
                {{user.name}}
            </b-col>   
            <b-col align-self="center" cols = 2 class="text-center pl-0">
                {{user.numQuizzesPlayed}} 
            </b-col>  
            <b-col align-self="center" cols = 2 class="text-center p-0">
                <b>{{user.pointsTotal}}</b>
            </b-col>          
                     
            
            
        </b-row>  
        </div>
        
    </b-card>
    </div>

</div>
    
</template>

<script>
export default {
    name: 'EternalTable',
    props : ['stats'],

    data() {
        return {
            tableData : [],
            showTable : false,
        };
    },

    mounted() {      
        
        for (let i = 0; i < this.stats.length; i++){
            this.tableData[i] = {
                name : this.stats[i].name,
                initials : this.stats[i].user.prename.charAt(0) + this.stats[i].user.surname.charAt(0),
                numQuizzesPlayed : this.stats[i].numQuizzesPlayed,
                pointsTotal : this.stats[i].pointsTotal,
            }
        }

        this.tableData.sort((a,b) => {            
           if (a.pointsTotal - b.pointsTotal > 0) {
               return -1;
           }
           if (b.pointsTotal - a.pointsTotal > 0) {
               return 1;
           }
        
           return 0;
        }); 

        this.tableData = this.tableData.filter(data => (data.pointsTotal) > 0)



    },     


    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>