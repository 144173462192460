<template>
    <div>
        <div v-if="showGroupInvitation">
            <b-card class="cardHome">
                <template #header>
                    <span class="cardHeader">Neue Quizgruppen-Einladung! </span>
                </template>
                <b-card-text>
                    Du wurdest zur Quizgruppe <b>{{groupInvitation.name}}</b> eingeladen.
                </b-card-text>
                <template #footer>
                    <div class="align-r">
                        <b-button class="buttonConfirm" @click="joinGroupButtonClick(groupInvitation._id)">Jetzt beitreten</b-button>
                    </div>
                </template>                               
            </b-card>
        </div>
        <div v-if="showSelectGroup">
            <b-card class="cardHome">
                <template #header>
                    <span class="cardHeader">Gruppenauswahl</span>
                </template>
                <b-card-text>
                    Wähle eine deiner Quizgruppen:
                    <div v-for="group in groups" :key="group._id">
                        <b-button @click="selectGroupButtonClick(group._id)" class="buttonStandard buttonFullWidth mb-1">{{group.name}}</b-button>
                    </div>
                </b-card-text>
            </b-card>
        </div>     
    </div>
</template>

<script>
import api from '@/services/api'

export default {
    name: 'MyGroups',
    components: {  },
  
    data() {
        return {
            groups : null,
            groupInvitation : null,            
            showGroupInvitation: false ,
            showSelectGroup: false ,
            showCreateGroup: false ,
        };
    }, 

        methods: {
        adminString(group){ 
            console.log(group.admins)
            let adminString = '';
            let n = group.admins.length;            
            for (let i=0; i < n; i++){
                adminString += group.admins[i].prename;
                if (i<(n-2)){
                    adminString += ', ';
                } else if (i<(n-1)) {
                    adminString += ' und ';
                }                
            }
            if (n>1){
                    adminString += ' haben';
                } else {
                    adminString += ' hat';
                }
            return adminString;
        },

        async selectGroupButtonClick(id){
            let apiString = "selectCurrentGroup/" + String(id);                                
            await api.get(apiString)
            .then(async () => {                
                await this.$store.dispatch("updateCurrentGroupAction");                
                await this.$store.dispatch('updateMyGroupsAction'); 
                await this.$store.dispatch('updateCurrentQuiz'); 
                console.log(this.$store.getters.groupName);
                await this.$store.dispatch("updateRouterView");
                })          
            .catch(error => console.log(error.response))
        },

        async joinGroupButtonClick(id){
            let apiString = "joinGroup/" + String(id);                               
            await api.get(apiString)
            .then(async () => {                
                await this.$store.dispatch("updateCurrentGroupAction");                
                await this.$store.dispatch('updateMyGroupsAction'); 
                await this.$store.dispatch('updateCurrentQuiz'); 
                console.log(this.$store.getters.groupName);
                if (id === this.$store.getters.inviteGroupId){
                    this.$store.dispatch("updateGroupInvitation",null)
                }
                this.$store.dispatch("updateRouterView");
                })          
            .catch(error => console.log(error.response))
        },

        async declineGroupButtonClick(id){
            // to be implemented
            // alternative: join via link (to be investigated ...)
        },

        createGroupButtonClick(){
            this.$router.push('/createGroup')
        }
        
    },

    async mounted() {
        await api.get("myGroups").then(res => this.groups = res.data);        
        
        if (this.$store.getters.isInvited) {
            await api.get('group/' + this.$store.getters.inviteGroupId)
            .then(res => {
                this.groupInvitation = res.data;                
            })
            .catch(error => console.log(error.response))
        }

        await api.get("myGroups").then(res => this.groups = res.data);  
        
        if (!this.$store.getters.groupName){            
            if (this.groups.length > 1){
                this.showSelectGroup = true;
            }
            else if (this.groups.length === 1) {
                await this.selectGroupButtonClick(this.groups[0]._id);            
            } 
        }
        
        if (this.$store.getters.isInvited){
            // Check if user already joined invited group
            if (this.groups.length === 0){
                this.showGroupInvitation = true;
            } else {
                const groupIds = this.groups.map(group => group._id);
                if (groupIds.length === 0 || groupIds.indexOf(this.groupInvitation._id) === -1) {
                    this.showGroupInvitation = true;
                }
            }
        }

        await this.$emit("ready");
        
    },
};
</script>

<style lang="scss" scoped>

</style>