<template>
    <b-card class="cardHome" v-if="displayCard">
        <template #header>
            <span class="cardHeaderStats">{{header}}</span>
        </template>
        <div>
            <b-row class="mb-1" v-for="stat in displayData" :key="stat._id" @click="showAll=!showAll">
                <b-col align-self="center"  cols="9">
                    <b-avatar size="32px" class="avatarAdminSmall mr-1">
                        {{stat.initials}}
                    </b-avatar>
                <!-- </b-col>
                <b-col align-self="center" cols="6"> -->
                    {{stat.name}}
                </b-col>                        
                <b-col align-self="center">
                    {{stat.value.toFixed(toFixed)}} {{unit}} 
                </b-col>
            </b-row>  
        </div>
        
        <b-row>
            <b-col cols="5">
            </b-col>
            <b-col>
                <b-icon v-if="!showAll" @click="showAll=!showAll" icon="chevron-down"/>
                <!-- <b-icon v-if="showAll" @click="showAll=!showAll" icon="chevron-up"/> -->
            </b-col>
        </b-row>
        
    </b-card>
</template>

<script>
export default {

    name: 'StatsCard',
    components : {},
    
    props:['header', 'stats', 'unit', 'toFixed'],

    computed : {
        displayData : function (){
            if (this.showAll){
                return this.stats;
            }
            else {
                let data = [];                
                data[0] = this.stats[0];
                let i = 1;
                while (i < this.stats.length && this.stats[i].value === this.stats[i-1].value){
                    data[i] = this.stats[i];
                    i++;
                }
                return data;
            }
        },

        displayCard : function(){
            if (!!this.displayData[0]){ return true}
        }
    },

    data() {
        return {
            showAll : false,
        };
    },

}
</script>

<style>

</style>