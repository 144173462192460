
// STATES
const state = {
  inviteGroupId: null  
};

// GETTERS
const getters = {
  isInvited: (state) => !!state.inviteGroupId,   
  inviteGroupId: (state) => state.inviteGroupId
};

// ACTIONS
const actions = {

  async updateGroupInvitation({commit}, groupId) {        
    await commit("setInviteGroupId", groupId);
  },
}

// MUTATIONS
const mutations = {

  setInviteGroupId(state, inviteGroupId) {
    state.inviteGroupId = inviteGroupId;
  },

};


// EXPORT
export default {
  state,
  getters,
  actions,
  mutations,
};
