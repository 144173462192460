<template>
    <b-card class="cardHome">
        <template #header>
            <div class="mb-0 cardHeader" style="">
                <b-row no-gutters>
                    <b-col>Weitere Quizgruppen</b-col>
                    <!-- <b-col cols="auto" style="text-align: right;"><b-button class="buttonStandardSolid" @click="showNewGroupModal"><b-icon-plus-circle/></b-button></b-col> -->
                </b-row>
            </div>
        </template>
        <b-card-text>
            Du bist derzeit in deiner Quizgruppe <u><router-link to="/groups" class="login_link">{{this.$store.getters.groupName}}</router-link></u>. Diese Knöppe bringen dich zu deinen weiteren Quizgruppen:
        </b-card-text>
            <div v-for="group in otherGroups" :key="group._id">                
            <b-button @click="goToGroupClick(group._id)" class="mb-1 buttonStandard buttonFullWidth">{{group.name}}</b-button>
        </div>

    </b-card>  
</template>

<script>
import api from '@/services/api';

export default {
    name: 'OtherGroups',
    
    // props:['groups'],

    data() {
        return {
            // otherGroups : null,
        }
    },

    computed : {
        otherGroups(){
            let otherGroups = this.$store.getters.myGroups.filter(group => group._id !== this.$store.getters.currentGroupId)
            return otherGroups.sort((a ,b) => {                
                    if ( a.name > b.name ){return +1;}
                    if ( a.name < b.name ){return -1;}
                });
        } 
    },

    methods: {
        goToGroupClick(id){
            api.get('/selectCurrentGroup/' + String(id))
            .then(async (res) => {                
                await this.$store.dispatch("updateCurrentGroupAction");
                await this.$store.dispatch("updateMyGroupsAction");
                if (this.$route.path !== "/"){
                    this.$router.push('/');
                } else {
                    await this.$store.dispatch("updateRouterView");
                }
            });            
        },

    },

    mounted() {        
        
    },

}
</script>

<style>

</style>