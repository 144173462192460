<template>
    <div>
        <h1>404</h1>
        <h2>Hier gibbet nix zu sehn!</h2>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    
    data() {
        return {
            
        };
    },
    mounted() {
        
    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>