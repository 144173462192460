import api from '@/services/api'

// STATES
const state = {
    quiz: null ,
    isActive: false,
    isNotStarted: false,
};
  
// GETTERS
const getters = {
    quizIsActive: (state) => state.isActive,
    quizIsNotStarted: (state) => state.isNotStarted,
    currentQuiz: (state) => state.quiz
};
  
// ACTIONS
const actions = {
    async updateCurrentQuiz({commit}) {
        await api.get("currentQuiz")
        .then(async (res) => {
            
            let quiz = res.data;

            await commit("setCurrentQuiz", quiz);

            if (quiz && quiz.status === "active"){
                await commit("setQuizIsActive", true);
            } else {
                await commit("setQuizIsActive", false);
            }

            if (quiz && quiz.status === "notStarted"){
                await commit("setQuizIsNotStarted", true);
            } else {
                await commit("setQuizIsNotStarted", false);
            }
        })
        .catch(error => console.log(error.response));
    }
};
  
  // MUTATIONS
const mutations = {
  
    setCurrentQuiz(state, quiz) {
      state.quiz = quiz;
    },

    setQuizIsActive(state, isActive) {
        state.isActive = isActive;
    },

    setQuizIsNotStarted(state, isNotStarted) {
        state.isNotStarted = isNotStarted;
    },
  
};
  
  
  // EXPORT
export default {
    state,
    getters,
    actions,
    mutations,
}
  