<template>
    <!-- <b-navbar toggleable="lg" type="dark" variant="dark" :sticky="isLoggedIn"> -->
    <b-navbar type="dark" variant="dark" :sticky="isLoggedIn">
      <b-navbar-brand  to="/" @click="quizLogoClick">
        <!-- <b-icon icon="chevron-left" class="mr-3"  ></b-icon> -->
        <b-img :src="require('../assets/QLogoFlexColorSmall.png')" style="width:35px;height:35px;" ></b-img>                
      </b-navbar-brand>     

      <!-- Center aligned -->

      <b-navbar-nav class="ml-auto">
        <b-navbar-brand v-if="$store.getters.groupName && this.$route.path !== '/PlayQuiz'" to="/" @click="quizLogoClick" style="font-size:1rem;">{{$store.getters.groupName}}</b-navbar-brand>  
      </b-navbar-nav>

      <!-- Right aligned -->
      <b-navbar-nav class="ml-auto">        
        <b-nav-item-dropdown
          v-if="isLoggedIn"
          toggle-class="text-decoration-none"
          no-caret
          right
      >
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <b-avatar
              variant="light"                
              size="35px"                
            >
            {{$store.getters.userInitials}}
            </b-avatar>
            <span class="sr-only">User</span>
          </template>          
          <b-dropdown-item @click="logout">Ausloggen</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav> 



    <div v-if="false">

      <b-navbar-toggle target="nav-collapse" v-if="isLoggedIn"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav v-if="isLoggedIn">
          <!-- Right aligned navigation bar items -->
          <b-navbar-nav v-if="groupIsSelected" class="ml-auto"> 
            <b-nav-item to="/questions">Meine Fragen</b-nav-item>
            <b-nav-item to="/groups">Meine Quizgruppen</b-nav-item>  
            <b-nav-item to="/quizzes">Unsere Quizabende</b-nav-item>
            <!-- <b-nav-item v-if="$store.getters.groupName" to="/Groups">Quizgruppe "{{$store.getters.groupName}}"</b-nav-item>         -->
          </b-navbar-nav>
          
        
        <b-navbar-nav v-else class="ml-auto">        
        </b-navbar-nav>
        
        <b-navbar-nav>
          <b-nav-item-dropdown
            right
            toggle-class="text-decoration-none"
            no-caret
          >
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-avatar
                variant="light"                
                size="35px"                
              >
              {{$store.getters.userInitials}}
              </b-avatar>
              <span class="sr-only">User</span>
            </template>
            <b-dropdown-item @click="logout">Ausloggen</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
        </div>


</b-navbar>
</template>

<script>
export default {

  name: "NavBar",

  data() {
    return {
      // myGroupsSelection : null,      
      
    }
  },
  computed: {

    isAtHome: function (){
      return this.$route.path === "/" ? true : false;
    },

    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    },

    groupIsSelected : function(){
      if (this.isLoggedIn && this.$store.getters.currentGroup){
        return true;
      } 
      else {
        return false;
      }
    },


    // currentGroupId : function () {
    //   if (this.$store.getters.currentGroup){
    //     return this.$store.getters.currentGroup._id;
    //   }
    //   else {
    //     return undefined;
    //   }
    // },

    myGroupsSelection: function(){
      let myGroupsSelection = null;
      if (this.$store.getters.myGroups){
        for (let i = 0; i < this.$store.getters.myGroups.length; i++){
          myGroupsSelection[i] = {
            value : this.$store.getters.myGroups[i]._id,
            name : this.$store.getters.myGroups[i].name,
          }
        }
      }
      return myGroupsSelection
    }

  },
  
  methods: {
    async logout() {
      await this.$store.dispatch("logoutAction");
      console.log('Current user logged out.');
      this.$router.push("/login");      
    },

    async quizLogoClick(){
      if (this.isAtHome){
        await this.$store.dispatch("updateRouterView");
      }
    },

    async updateGroups(){
      myGroups
    }
  },

  async mounted() {
    this.currentGroupId = this.$store.getters.currentGroupId;
  }
}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
