<template>
<div>
    <b-container v-if="dispCmp" fluid class="containerHome">
        <b-row>
            <b-col>
                <b-card class="cardHome mt-1">
                    <template #header>
                        <div class="mb-0 cardHeader">{{headerString}}</div>
                    </template>
                    <b-card-text>
                        <b-form>
                            <b-form-group>
                                <b-form-datepicker
                                    id="date"
                                    v-model="date" 
                                    locale = 'de'                                       
                                    :start-weekday="1"                    
                                    :hide-header="true"
                                    :min="min"
                                    :state="validationState.date"
                                    class="mb-2"
                                    placeholder="Datum auswählen"
                                ></b-form-datepicker>
                                <b-form-timepicker
                                    v-model="time"
                                    locale="de"
                                    @context="onContext"
                                    placeholder="Zeit auswählen"
                                >
                                </b-form-timepicker>
                            </b-form-group>
                            <b-form-group
                                label="Gastgeber:"
                                label-for="hostUserInput"         
                            >
                                <b-form-select
                                    id="hostUserInput"
                                    v-model="hostUser"
                                    :options="groupMembers"
                                    :state="validationState.hostUser"
                                >
                                </b-form-select>
                            </b-form-group>
                            <b-form-group
                                label="Ort:"
                                label-for="locationInput"
                            >
                                <b-form-input
                                    id="locationInput"
                                    v-model="location"
                                    type="text"
                                    placeholder="Köln / Berlin / London / Online"
                                >
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                label="Joker:"
                                label-for="jokersAllowed"               
                            >
                                <b-form-checkbox-group
                                    id="jokersAllowed"
                                    v-model="jokersAllowed"
                                    :options="jokers"
                                >
                                </b-form-checkbox-group>
                            </b-form-group>
                            <b-form-group
                                label="Erwünschte Teilnehmerzahl:"
                                label-for="numberOfRegistrationsTarget"
                            >
                                <b-form-input
                                    id="numberOfRegistrationsTarget"
                                    type="number"
                                    v-model="numberOfRegistrationsTarget"
                                >
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                label="Erwünschte Fragenanzahl:"
                                label-for="numberOfQuestionsTarget"             
                            >
                                <b-form-input
                                    id="numberOfQuestionsTarget"
                                    type="number"
                                    v-model="numberOfQuestionsTarget"
                                >
                                </b-form-input>
                            </b-form-group> 
                        </b-form>
                    </b-card-text>
                    <template #footer>
                        <div v-if="infoAboutQuizChanges" class="align-l">
                            <p><i>Quizabend verändert? Alles klar! Sobald du speicherst schicken wir allen in der Gruppe eine e-Mail mit den neuen Quizdaten.</i></p>
                        </div>
                        <div v-if="!editQuiz" class="align-l">
                            <p><i>Gut zu wissen: Wir informieren alle in der Gruppe per e-Mail über den neu angesetzten Quizabend.</i></p>
                        </div>
                        <div class="align-r">
                            <b-button v-if="editQuiz" id="delete-button" class="buttonReset" @click="showCancelQuizModal"><b-icon icon="trash" /></b-button>
                            <b-button type="reset" @click="$router.go(-1)" class="buttonReset ml-1">Abbrechen</b-button>
                            <b-button type="submit" @click="confirmQuizButtonClick" class="buttonStandard ml-1">{{confirmButtonText}}</b-button>                            
                        </div>                        
                    </template>
                </b-card>
            </b-col>
        </b-row>

        <!-- Modal for cancel quiz -->
        <b-modal ref="cancelQuizModal" hide-footer title="Quiz absagen">                  
            <div>
                <p>Das ist aber schade ...</p>
                <p>Bist du sicher? </p>

            </div>
            <b-button class="buttonReject" block @click="cancelQuizConfirm">Ja, Quiz absagen.</b-button>
        </b-modal> 

    </b-container>
</div>
</template>

<script>
import api from '@/services/api'

export default {
    name: 'EditQuiz',
    // components: { ComponentName },
    // directives: { DirectiveName },
    data() {
        
        return {
            // Configure mail available
            mailAvailable : true,
            
            // Display vars
            dispCmp : false,

            // Form variables
            group : null,
            groupMembers: null,
            min : null,
            
            jokers : [
                { text: '50/50-Joker', value: 'fiftyFifty' },
                { text: 'Kopier-Joker', value: 'copyAnswer' },
                { text: 'Mehrheits-Joker', value: 'majorityAnswer' },
            ],

            // Form Data
            hostUser : null,
            date : null,
            time : '19:00',
            location : '',
            jokersAllowed : ['fiftyFifty', 'copyAnswer', 'majorityAnswer'],
            numberOfRegistrationsTarget : null,
            numberOfQuestionsTarget : null,
            context : null,

            // Quiz Editing            
            editQuiz : false,
            quiz : null,

            // validation
            validationState : {
                date : null,
                hostUser : null 
            }



        }
    },

    async mounted() {
        // If called with quiz ID, get quiz info from server
        if (this.$route.params.id !== 'new'){            
            this.editQuiz = true;
            await api.get('quiz/' + this.$route.params.id)
            .then((res) => {
                this.quiz = res.data;
                if (this.quiz.status === "notStarted"){
                    this.hostUser = this.quiz.hostUser;
                    this.date = this.quiz.quizDate;                    
                    this.quizDatePrev = new Date(this.quiz.quizDate);                
                    this.time = (String(this.quizDatePrev.getHours()) + ':' + this.quizDatePrev.getMinutes());
                    this.location = this.quiz.location;
                    this.jokersAllowed = this.quiz.jokersAllowed;
                    this.numberOfRegistrationsTarget = this.quiz.numberOfRegistrationsTarget;
                    this.numberOfQuestionsTarget = this.quiz.numberOfQuestionsTarget;     
                }
                else{
                    this.$router.push('/');
                }
                           
            })
            .catch(error => console.log(error.response));            
        }
        
        this.dispCmp = true;

        // Get group info
        this.group = this.$store.getters.currentGroup;        
        this.groupMembers = new Array(this.group.members.length)
        for (let i = 0 ; i < this.group.members.length ; i++)
        {
            this.groupMembers[i] = {
                value : this.group.members[i]._id, 
                text : this.group.members[i].prename + ' ' + this.group.members[i].surname
            }
        }

        // Get quiz info and set earliest date for new quiz        
        await api.get('currentQuiz')
        .then(async (res) => {                        
            let currentQuiz = res.data;
            if (currentQuiz && !this.editQuiz)
            {   
                this.min = new Date(currentQuiz.quizDate)                                        
                this.min.setDate(this.min.getDate() + 1)                    
            } else {
                this.min = new Date();
            }
        })
        
    },

    methods: {

        showCancelQuizModal() {
            this.$refs['cancelQuizModal'].show();
        },

        async cancelQuizConfirm(){
            await api.delete('quiz/' + this.quiz._id)
            .then(async (res) => {
                await this.$store.dispatch('updateCurrentQuiz');
                this.$router.push('/');
            })
            .catch(error => console.log(error.response));
        },

        async confirmQuizButtonClick (){     
            if (!this.validateForm()){
                return
            }
            let quizDate = new Date (this.date);
            quizDate.setHours(this.context.hours, this.context.minutes, 0, 0);
            let body = {
                quizDate : quizDate,
                hostUser : this.hostUser,                
                location : this.location,
                jokersAllowed : this.jokersAllowed,
                numberOfQuestionsTarget : this.numberOfQuestionsTarget,
                numberOfRegistrationsTarget : this.numberOfRegistrationsTarget
            }
            console.log(body)
            if (this.editQuiz){
                await api.patch('quiz/' + this.$route.params.id,body)
                .then(async (res) => {                    
                    this.$router.push('/')
                })
                .catch(error => console.log(error.response));
            }
            else {
                await api.post('quiz',body)
                .then(async (res) => {                    
                    this.$router.push('/')
                })
                .catch(error => console.log(error.response));
            }
                        
        },

        
        onContext(ctx) {
            this.context = ctx
        },

        validateForm(){
            this.validationState.date = this.date? null : false;
            this.validationState.hostUser = this.hostUser? null : false;            

            if (this.validationState.date === false ||
                this.validationState.hostUser === false)              
            {
                return false
            } 
            else {
                return true
            }
        },
        
    },

    computed : {
        headerString : function (){
            if (this.editQuiz){
                return 'Quiz bearbeiten'
            }
            else {
                return 'Neues Quiz erstellen'
            }
        },

        confirmButtonText : function (){
            return this.editQuiz ? 'Speichern' : 'Quiz erstellen';
        },

        infoAboutQuizChanges : function(){
            if (this.quizDatePrev && this.context && this.editQuiz && this.mailAvailable){
                if (String(this.hostUser) !== String(this.quiz.hostUser) || this.date !== this.quiz.quizDate || this.context.hours !== this.quizDatePrev.getHours() || this.context.minutes !== this.quizDatePrev.getMinutes()){
                    return true;
                }
                else {
                    return false;
                }
           }
           else {
               return false;
           }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>