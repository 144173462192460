<template>
    <div>
        <div>
            <b-card class="cardHome" no-body>
                <template #header>
                    <span v-if="previousQuiz" class="cardHeader">Letzter Quizabend</span>
                    <span v-else class="cardHeader">{{dateString}}</span>
                </template>
                <b-list-group flush>
                    <b-list-group-item>
                        <table width=100%>
                            <tr v-if="previousQuiz">
                                <td width=50px><b-icon-calendar-date></b-icon-calendar-date></td>
                                <td>{{dateString}}</td>
                            </tr>
                            <tr>
                                <td><b-icon-geo-alt></b-icon-geo-alt></td>
                                <td>{{locationString}}</td>
                            </tr>
                            <tr>
                                <td><b-icon-person-fill></b-icon-person-fill> <b-icon-person></b-icon-person></td>
                                <td>{{nPlayers}} Spielende</td>
                            </tr>
                            <tr>
                                <td><b-icon-question-circle></b-icon-question-circle> <b-icon-question-circle-fill></b-icon-question-circle-fill></td>
                                <td>{{quiz.totalNumberOfQuestions}} Fragen</td>
                            </tr>
                            
                        </table>
                    </b-list-group-item> 
                    <b-list-group-item>
                        <table width=100%>
    
                            <tr>
                                <td v-if="!!winnerString(1)" width=50px><b-img :src="require('@/assets/medals/icons8-1st-place-medal-48.png')" style="width:30px;height:30px;" ></b-img></td>
                                <td>{{winnerString(1)}}</td>
                            </tr>  
                            <tr>
                                <td v-if="!!winnerString(2)"><b-img :src="require('@/assets/medals/icons8-2nd-place-medal-48.png')" style="width:30px;height:30px;" ></b-img></td>
                                <td>{{winnerString(2)}}</td>
                            </tr>  
                            <tr>
                                <td v-if="!!winnerString(3)"><b-img :src="require('@/assets/medals/icons8-3rd-place-medal-48.png')" style="width:30px;height:30px;" ></b-img></td>
                                <td>{{winnerString(3)}}</td>
                            </tr>  
    
                        </table>
                    </b-list-group-item> 
                </b-list-group>

                <b-card-body style="padding: 0;"></b-card-body>
                <template #footer>
                    <div class="align-r">
                        <b-button class="buttonStandard ml-1 mb-1   " @click="goToQuizButtonClick">Zum Quiz</b-button>                        
                        <b-button v-if="!!previousQuiz  " class="buttonStandard ml-1 mb-1" @click="goToQuizzesButtonClick">Zu unseren Quizabenden</b-button>      
                    </div>
                </template>
            </b-card>

        </div>
    </div>
  
</template>

<script>
import api from '@/services/api'

export default {

    name: 'QuizCard',
    props : ['quiz','previousQuiz'],
    // components: { ComponentName },
    // directives: { DirectiveName },
    data() {
        return {
            ranking : null,            
        };
    },


    computed : {
        dateString: function() {
            let now = new Date();
            let quizDate = new Date(this.quiz.quizDate);         
            let daysLeft =  Math.floor((quizDate - now)/1000/24/3600);            
            const dateOptions = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'};            
            let quizDateString = quizDate.toLocaleDateString('de-DE', dateOptions); 
            return quizDateString; 
        },

        timeString: function() {
            let quizDate = new Date(this.quiz.quizDate);    
            return (String(quizDate.getHours()) + ':' + this.getMyMinutes + ' Uhr')
        },

        getMyMinutes : function (){
            let quizDate = new Date(this.quiz.quizDate);      
            let min = quizDate.getMinutes();
            return min < 10 ? ('0' + String(min)) : String(min);
        },        

        locationString: function(){
            if (this.quiz && this.quiz.location){
                return this.quiz.location + ', bei ' + this.quiz.hostUser.prename + ' ' + this.quiz.hostUser.surname
            }
            else {
                return this.quiz.hostUser.prename + ' ' + this.quiz.hostUser.surname;
            }
        },

        nPlayers : function(){
            if (this.ranking){
                return this.ranking.filter(e => !!e.registered).length;
            } else {
                return '';
            }
        }

      
        
    },

    methods: {
        goToQuizButtonClick(){
            this.$router.push('/Quiz/' + this.quiz._id)
        },

        goToQuizzesButtonClick(){
            this.$router.push('/Quizzes/')
        },

        winnerString(pos){
            if (this.ranking){
                let winners = this.ranking.filter(element => element.position === pos);                
                if (winners.length > 0){
                    let winnerString = winners[0].user.prename + ' ' + winners[0].user.surname;
                    for (let i = 1; i < winners.length; i++){
                        winnerString = winnerString + ', ' +  winners[i].user.prename + ' ' + winners[i].user.surname;
                    }
                    return winnerString;
                }
                else {
                    return ''
                }
            }
            else {
                return ''
            }
        }
    },

    async mounted(){
        await api.get("quizRanking/" + String(this.quiz._id))
        .then(res => {
            this.ranking = res.data;
        })
        .catch(error => console.log(error.response));

        await this.$emit("ready");
    }
}

</script>

<style>

</style>