<template>
  <b-container>
      <b-row>
          <b-col v-if="questionIsMultipleChoice">
              <div>
                <b-row cols="1" cols-md="2">
                    <!-- Answer A -->
                    <b-col class="answerCol"> 
                        <b-button v-if="dispAnswerCorrectA" disabled class="buttonQuizQuestions buttonAnswerCorrect">
                            <div class="answerLetterPlayCorrect">A</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[0]}}</div>
                        </b-button>
                        <b-button v-else-if="dispAnswerIncorrectA" disabled class="buttonQuizQuestions buttonAnswerWrong">
                            <div class="answerLetterPlayWrong">A</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[0]}}</div>
                        </b-button>
                        <b-button v-else disabled class="buttonQuizQuestions">
                            <div class="answerLetterPlay">A</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[0]}}</div>
                        </b-button>
                    </b-col>
                    <!-- Answer B -->
                    <b-col class="answerCol"> 
                        <b-button v-if="dispAnswerCorrectB" disabled class="buttonQuizQuestions buttonAnswerCorrect">
                            <div class="answerLetterPlayCorrect">B</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[1]}}</div>
                        </b-button>
                        <b-button v-else-if="dispAnswerIncorrectB" disabled class="buttonQuizQuestions buttonAnswerWrong">
                            <div class="answerLetterPlayWrong">B</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[1]}}</div>
                        </b-button>
                        <b-button v-else disabled class="buttonQuizQuestions">
                            <div class="answerLetterPlay">B</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[1]}}</div>
                        </b-button>
                    </b-col>
                    <!-- Answer C -->
                    <b-col class="answerCol"> 
                        <b-button v-if="dispAnswerCorrectC" disabled class="buttonQuizQuestions buttonAnswerCorrect">
                            <div class="answerLetterPlayCorrect">C</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[2]}}</div>
                        </b-button>
                        <b-button v-else-if="dispAnswerIncorrectC" disabled class="buttonQuizQuestions buttonAnswerWrong">
                            <div class="answerLetterPlayWrong">C</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[2]}}</div>
                        </b-button>
                        <b-button v-else disabled class="buttonQuizQuestions">
                            <div class="answerLetterPlay">C</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[2]}}</div>
                        </b-button>
                    </b-col>
                    <!-- Answer D -->
                    <b-col class="answerCol"> 
                        <b-button v-if="dispAnswerCorrectD" disabled class="buttonQuizQuestions buttonAnswerCorrect">
                            <div class="answerLetterPlayCorrect">D</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[3]}}</div>
                        </b-button>
                        <b-button v-else-if="dispAnswerIncorrectD" disabled class="buttonQuizQuestions buttonAnswerWrong">
                            <div class="answerLetterPlayWrong">D</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[3]}}</div>
                        </b-button>
                        <b-button v-else disabled class="buttonQuizQuestions">
                            <div class="answerLetterPlay">D</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[3]}}</div>
                        </b-button>
                    </b-col>
                    
                </b-row>
            </div>
          </b-col>

          <b-col v-if="questionIsEstimation">
              <div>
                <b-row cols="1" cols-md="2" v-if="dispEstimationMyAnswer">                                        
                    <b-col class="answerCol"> 
                        <b-button disabled class="buttonQuizQuestions">
                            <div class="answerLetterPlay"><b-icon stacked icon="question"></b-icon></div>
                            <div class="answerTextPlay" style="text-align: center;">{{question.answer.answerValue}}</div>
                        </b-button>                        
                    </b-col>
                    <b-col class="answerCol"> 
                        <b-button disabled class="buttonQuizQuestions buttonAnswerCorrect">
                            <div class="answerLetterPlayCorrect"><b-icon stacked icon="check"></b-icon></div>
                            <div class="answerTextPlay" style="text-align: center;">{{question.estimationCorrectAnswer}}</div>
                        </b-button>
                    </b-col>
                </b-row>
                <b-row v-else cols="1" cols-md="1">                                       

                    <b-col class="answerCol"> 
                        <b-button disabled class="buttonQuizQuestions buttonAnswerCorrect">
                            <div class="answerLetterPlayCorrect"><b-icon stacked icon="check"></b-icon></div>
                            <div class="answerTextPlay" style="text-align: center;">{{question.estimationCorrectAnswer}}</div>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
          </b-col>

          <!-- <b-col v-if="!questionIsAnswered">
              <b-row>
                  <b-col>
                      <p><i>Frage nicht gespielt</i></p>
                  </b-col>
              </b-row>
          </b-col> -->
      </b-row>
  </b-container>
</template>

<script>
export default {
    name: 'QuestionResult',
    props : ['question'],
      data() {
        return {
            dispAnswerCorrectA : false,
            dispAnswerCorrectB : false,
            dispAnswerCorrectC : false,
            dispAnswerCorrectD : false,
            dispAnswerIncorrectA : false,
            dispAnswerIncorrectB : false,
            dispAnswerIncorrectC : false,
            dispAnswerIncorrectD : false,   

            multipleChoieAnswerArray : [],

            dispResult : false,
        };
    },

     computed : {
        questionIsMultipleChoice : function (){
            return (this.question && this.question.questionType === 'multipleChoice') ? true : false;
        },

        questionIsEstimation : function(){
            return (this.question && this.question.questionType === 'estimation') ? true : false;
        }, 

        questionIsAnswered : function(){
            return (!!this.question.answer);
        },

        dispEstimationMyAnswer : function(){
            return (this.questionIsEstimation && this.questionIsAnswered && !this.question.answer.isCreator) ? true : false;
        }
     },

    mounted() {

        if (this.questionIsMultipleChoice){

            
            let displayOrder = this.question.multipleChoiceAnswerDisplayOrder;
            for (let i=0 ; i < displayOrder.length; i++){
                this.multipleChoieAnswerArray[ displayOrder[i] ] = this.question.multipleChoiceAnswers[i];
            }
            

            let answerA = this.question.multipleChoiceAnswerDisplayOrder.indexOf(0)+1;
            let answerB = this.question.multipleChoiceAnswerDisplayOrder.indexOf(1)+1;
            let answerC = this.question.multipleChoiceAnswerDisplayOrder.indexOf(2)+1;
            let answerD = this.question.multipleChoiceAnswerDisplayOrder.indexOf(3)+1;
            this.dispAnswerCorrectA = answerA === this.question.multipleChoiceCorrectAnswer ? true : false;
            this.dispAnswerCorrectB = answerB === this.question.multipleChoiceCorrectAnswer ? true : false;
            this.dispAnswerCorrectC = answerC === this.question.multipleChoiceCorrectAnswer ? true : false;
            this.dispAnswerCorrectD = answerD === this.question.multipleChoiceCorrectAnswer ? true : false;
        }
        if (this.question.answer && this.questionIsAnswered && !this.question.answer.isCreator){
            let postedAnswerValueDisp = this.question.multipleChoiceAnswerDisplayOrder[this.question.answer.answerValue-1];
            this.dispAnswerIncorrectA = postedAnswerValueDisp === 0 ? true : false;
            this.dispAnswerIncorrectB = postedAnswerValueDisp === 1 ? true : false;
            this.dispAnswerIncorrectC = postedAnswerValueDisp === 2 ? true : false;
            this.dispAnswerIncorrectD = postedAnswerValueDisp === 3 ? true : false;
        }

        this.dispResult = true;


        
    },




}
</script>

<style>

</style>