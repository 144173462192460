<template>
  <div id="app">
    <NavBar v-if="showNavBar" :key = "navBarKey"/>
    <router-view :key = "componentKey"/>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  components: {
    NavBar,
  },

  computed : {
    componentKey() {
      return this.$store.getters.routerView;
    },
    navBarKey() {
      return this.$store.getters.navBarView;
    },
    showNavBar() {
      if (this.$route.path !== '/LeadQuiz'){
        return true;
      }
      else {
        return false;
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
