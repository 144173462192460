<template>
    <div>
        <b-card class="cardHome">
            <template #header>
                <span class="cardHeader">Passwort zurücksetzen </span>
            </template>
 
            <b-card-text>                
                <div class="input-group mb-2">                    
                    <div class="input-group-append">
                        <span class="input-group-text"><i class="fas fa-key"></i></span>
                    </div>
                    <input type="email" name="email" class="form-control " v-model="email" placeholder="e-Mail" v-on:keyup.enter="resetPasswordButtonClick">
                </div>
            </b-card-text>

            <b-card-text>                
                <p>{{password}}</p>
            </b-card-text>

            <template #footer>
                <div class="align-r">
                    <b-button class="buttonStandard ml-1" @click="resetPasswordButtonClick">Passwort zurücksetzen</b-button>
                </div>
            </template>                               
        </b-card>
    </div>
</template>

<script>
import api from '@/services/api';

export default {
    name: 'ResetPassword',

     data() {
        return {
            email : null,
            password : null,
        };
    },

    computed : {
             
    },

    async mounted() {
    },

    methods: {
        async resetPasswordButtonClick(){
            if (!this.email){
                this.password = 'Enter e-Mail'
                return
            }
            
            const body = {email : this.email};

            await api.post("resetpassword", body)
            .then(res => {
                console.log(res);
                this.password = res.data.password;
            })
            .catch(error => {
                console.log(error.response)
                this.password = error.response.data;                
            })
        },
    },

}
</script>

<style>

</style>