<template>
    <div class="container h-100" style="margin-top: 40px">
		<div class="d-flex justify-content-center h-100">
			<div class="user_card">
            <!-- <div class="user_card user_card_register"> -->
				<div class="d-flex justify-content-center">
					<div class="brand_logo_container">
						<img src="../assets/QLogo.png" class="brand_logo" alt="Logo">                        
					</div>
				</div>

				<div class="d-flex justify-content-center form_container">
					<form>
						<div class="input-group mb-2">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<b-form-input type="email" name="email" class="form-control input_user" v-model="form.email" placeholder="E-Mail Adresse" v-on:keyup.enter="signUpButtonClick" :state="validationState.email"></b-form-input>
						</div>
						<div class="input-group mb-2">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<b-form-input type="text" name="prename" class="form-control input_user" v-model="form.prename" placeholder="Vorname" v-on:keyup.enter="signUpButtonClick" :state="validationState.prename"></b-form-input>
						</div>
						<div class="input-group mb-2">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<b-form-input type="text" name="" class="form-control input_user" v-model="form.surname" placeholder="Nachname" v-on:keyup.enter="signUpButtonClick" :state="validationState.surname"></b-form-input>
						</div>
						<div class="input-group mb-2">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-key"></i></span>
							</div>
							<b-form-input type="password" name="password" class="form-control input_pass" v-model="form.password" placeholder="Passwort" v-on:keyup.enter="signUpButtonClick" :state="validationState.password"></b-form-input>
						</div>
						<div class="input-group mb-2">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-key"></i></span>
							</div>
							<b-form-input type="password" name="repeatPassword" class="form-control input_pass" v-model="form.repeatPassword" placeholder="Passwort wiederholen" v-on:keyup.enter="signUpButtonClick" :state="validationState.repeatPassword"></b-form-input>
						</div>
                        <p>{{validationErrorText}}</p>
					    <div class="d-flex justify-content-center mt-3 login_container">
				 	        <button type="button" name="button" class="btn login_btn" @click="signUpButtonClick">Registrieren</button>
				        </div>                        
					</form>
				</div>
			</div>
		</div>
	</div>     
</template>

<script>
import openapi from '@/services/openapi'

export default {
    name: 'Signup',
    // components: { ComponentName },
    // directives: { DirectiveName },
    data() {
        return {
            form: {
                email: '',
                prename: '',
                surname: '',
                password: ''  ,       
                repeatPassword: ''  
            },

            validationState : {
                email : null,
                password : null,
                prename : null,
                surname : null,
                repeatPassword: null
            },

            validationErrorText : '',
        }
    },
    computed: {

    },
    mounted() {
        
    },
    methods: {
        validateForm(){
            this.validationState.email = this.form.email ? null : false;
            this.validationState.password = this.form.password ? null : false;
            this.validationState.prename = this.form.prename ? null : false;
            this.validationState.surname = this.form.surname ? null : false;
            this.validationState.repeatPassword = (this.form.repeatPassword !== '' && this.form.repeatPassword === this.form.password) ? null : false;

            if (this.validationState.email === false ||
                this.validationState.password === false ||
                this.validationState.prename === false ||
                this.validationState.surname === false ||
                this.validationState.repeatPassword === false){
                    return false;
                }
                else {
                    return true;
                }
        },

        async signUpButtonClick() {
            this.validationErrorText = '';
            let validationCheck = this.validateForm();
            if (validationCheck){
                let credentials = {
                    email: this.form.email,
                    password: this.form.password,
                    prename: this.form.prename,
                    surname: this.form.surname
                }
                await openapi.post("signup",credentials)
                .then(async res => {
                    let credentials = {
                        email: this.form.email,
                        password: this.form.password,
                        stayLoggedIn: false,
                    }
                    await this.$store.dispatch("loginAction", credentials);
                    this.$router.push("/");                    
                })
                .catch(error => {
                    console.log(error.response)
                    let validationError = error.response.data.errors;
                    this.form.password = '';
                    this.form.repeatPassword = '';

                    if (validationError.email){
                        this.validationErrorText = 'Diese E-Mail ist bereits registriert.';
                        this.validationState.email = false;
                        this.validationState.password = null;
                        this.validationState.repeatPassword = null;
                    }
                })
            }
            

            

        }
    },
};
</script>

<style lang="scss" scoped>

</style>