<template>
    <div>
        <div v-if="showQuestions">
            <div v-for="quiz in quizzes" :key="quiz._id">    
                <QuizQuestionsCmp 
                    :quiz="quiz"
                    :showMyQuestionsOnly="showMyQuestionsOnly"
                />
            </div>
        </div>
        
    </div>
</template>

<script>
import api from '@/services/api'
import QuizQuestionsCmp from '@/components/questions/QuizQuestionsCmp.vue';


export default {
    name: 'PlayedQuestions',
    components: { QuizQuestionsCmp },
    

    data() {
        return {
            quizzes : null,
            showQuestions : false,
            showMyQuestionsOnly : true,

        };
    },



    methods: {
               
    },

    async mounted() {
        // Get all quizzes in group
        await api.get('quizzes').then(res => this.quizzes = res.data); 
        this.quizzes = this.quizzes.filter(quiz => quiz.status === 'over');

        // Sort by Date
        this.quizzes.sort((a ,b) => {                
                if ( a.quizDate > b.quizDate ){return -1;}
                if ( a.quizDate < b.quizDate ){return +1;}
            });

        // Get all quizzes in group with questions
        for (let i=0; i<this.quizzes.length; i++){
            await api.get('quizWithQuestions/' + String(this.quizzes[i]._id)).then(res => this.quizzes[i] = res.data); 
        }
        this.showQuestions = true;

    },

    
};
</script>

<style lang="scss" scoped>

</style>