import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/";

import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import ForgotPw from "../views/ForgotPw.vue";
import Questions from "../views/Questions.vue";
import Quizzes from "../views/Quizzes.vue";
import LeadQuiz from "../views/LeadQuiz.vue";
import PlayQuiz from "../views/PlayQuiz.vue";
import EditQuiz from "../views/EditQuiz.vue";
import Groups from "../views/Groups.vue";
import Quiz from "../views/Quiz.vue";
import NotFound from "../views/NotFound.vue";
import UpdateProfile from "../views/UpdateProfile.vue";



import GroupInvitation from "../components/invitation/GroupInvitation.vue";
import openapi from '@/services/openapi'

Vue.use(VueRouter);

// Setting up routes
const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { requiresAuth: true },
    },
    {
        path: "/invitation/:id",
        name: "Invitation",
        component: GroupInvitation,        
    },
    {
        path: "/login",
        name: "Login",
        component: Login,        
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
    },
    {
        path: "/forgotpw",
        name: "ForgotPw",
        component: ForgotPw,        
    },
    {
        path: "/questions",
        name: "Questions",
        component: Questions,
        meta: { 
            requiresAuth: true,
            requiresGroup: true,
         },
    },
    {
        path: "/quiz/:id",
        name: "Quiz",
        component: Quiz,
        meta: { requiresAuth: true },
    },
    {
        path: "/quizzes/",
        name: "Quizzes",
        component: Quizzes,
        meta: { 
            requiresAuth: true,
            requiresGroup: true,
         },
    },
    {
        path: "/updateprofile",
        name: "UpdateProfile",
        component: UpdateProfile,
        meta: { 
            requiresAuth: true,
            requiresGroup: true,
        },
    },
    {
        path: "/groups",
        name: "Groups",
        component: Groups,
        meta: { 
            requiresAuth: true,
            requiresGroup: true,
        },
    },
    {
        path: "/editquiz/:id",
        name: "EditQuiz",
        component: EditQuiz,
        meta: { 
            requiresAuth: true,
            requiresGroup: true,
        },
    },
    {
        path: "/playQuiz",
        name: "PlayQuiz",
        component: PlayQuiz,
        meta: { 
            requiresAuth: true,
            requiresGroup: true,
        },
    },
    {
        path: "/leadQuiz",
        name: "LeadQuiz",
        component: LeadQuiz,
        meta: { 
            requiresAuth: true,
            requiresGroup: true,
        },
    },
    {
        path: '*',
        name: NotFound,
        component: NotFound,
    }
];

// Create router instance
const router = new VueRouter({
    mode: "history",
    base: process.env.URL,
    routes,
});

// Handle authorized users
router.beforeEach((to, from, next) => {    
    document.title = 'Der Supertolle Quizabend';

    // Check if page requires authentification or selected group 
    if ((to.matched.some((record) => record.meta.requiresAuth) || to.matched.some((record) => record.meta.requiresGroup)))
    {
        // if page requires group
        // if (to.matched.some((record) => record.meta.requiresGroup)){
        //     if (!store.getters.currentGroupId){         
        //         next("/");                
        //     }            
        // }
        
        // if page requires auth
        if (to.matched.some((record) => record.meta.requiresAuth)){
            // If user is in store
            if (store.getters.isAuthenticated) {    
                next();            
            }
            // If user is not in store, check cookie
            else {
                // console.log("Check if user is logged in.");
                openapi.get("login")
                .then(async (res) => {                    
                    if (res.data) {   
                        // console.log(res.data)                     
                        let user = {
                            _id : res.data._id,
                            email: res.data.email,
                            prename: res.data.prename,
                            surname: res.data.surname,
                        };
                        await store.dispatch("saveLoginInfo", user);                          
                        // await store.dispatch('updateCurrentGroupAction');                  
                        next();
                    }
                    else {
                        next("/login");
                    }
                })
                .catch((error) => { 
                    console.log(error.response)                 
                    next("/login");
                })       
            }
        }
        
    }
    // if page does not require auth
    else {
        next();
    }
     
});

// Export router
export default router;
