import { render, staticRenderFns } from "./EditQuiz.vue?vue&type=template&id=19ee7eef&scoped=true"
import script from "./EditQuiz.vue?vue&type=script&lang=js"
export * from "./EditQuiz.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ee7eef",
  null
  
)

export default component.exports