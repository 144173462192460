<template>
    <b-container>
        <b-row>
            <b-col>
                <div class="mt-5 mb-5 ml-2 mr-2 cardHeader" style="font-size:1.8rem; text-align: left;">
                    <b>Meine Fragen</b>
                </div>
            </b-col>
                <b-col cols="auto" class="mt-5 mb-5 ml-2 mr-2" style="text-align: right;"><b-button class="buttonStandardSolid" @click="showNewQuestionModal"><b-icon-plus-circle></b-icon-plus-circle></b-button></b-col>
            </b-row>
        <b-row>
            <b-col>
            <b-card no-body>
                <b-tabs card fill v-model="tabIndex">

                    <b-tab :title=titleOpen  :key="1">
                        <b-row cols="1" cols-md="2" cols-xl="3">
                            <b-col v-for="question in unassignedQuestions" :key="question._id">            
                                <QuestionCard v-on:update="updateView" :question="question" :quiz="currentQuiz" :registered="userIsRegistered" />
                            </b-col>
                        </b-row>
                    </b-tab>
                    

                    <b-tab v-if="dispCurrentQuiz" :key="2" :title=titleAssigned >
                                <b-row class="text-left">
                                    <b-col>
                                        <b>Nächster Quizabend</b>
                                    </b-col>
                                </b-row>
                    
                                <b-row cols="1" fluid="md" cols-md="3" class="mb-4">                                      
                                    <b-col class="text-left" >                            
                                        <b-icon-calendar-date></b-icon-calendar-date> {{dateString}} 
                                    </b-col>                        
                                    <b-col class="text-left" >
                                        <b-icon-clock></b-icon-clock> {{timeString}}                             
                                    </b-col>
                                    <b-col class="text-left" >
                                        <b-icon-geo-alt></b-icon-geo-alt> {{locationString}}
                                    </b-col>                        
                                </b-row>

                                <b-row cols="1" cols-md="2" cols-xl="3">
                                    <b-col v-for="question in assignedQuestions" :key="question._id">
                                        <QuestionCard v-on:update="updateView" v-bind:question="question" :quiz="currentQuiz" :registered="userIsRegistered" />
                                    </b-col>
                                </b-row>


                            
                    </b-tab>
                    
                    
                    <b-tab title="Gespielt"  :key="3">
                        <PlayedQuestions />
                    </b-tab>          
                </b-tabs>
            </b-card>
            </b-col>
            
        </b-row>


        <!-- Modal for new question -->
        <b-modal id="add-question-modal" ref="addQuestionModal" title="Neue Frage">
            <b-form>
                <b-form-group>
                    <b-form-select v-model="questionType" :options="questionTypeOptions"></b-form-select>
                </b-form-group>
                <b-form-group label="Frage" label-for="questionTextInput">
                    <b-form-textarea 
                        id="questionTextInput"
                        v-model="questionText"
                        placeholder=""
                        size="sm"
                        rows="2"
                        max-rows="6"
                        :state="validationState.questionText"
                        required
                    >
                    </b-form-textarea>                
                </b-form-group>
                <div v-if="questionTypeIsMultipleChoice">
                    <b-form-group label="Richtige Antwort" label-for="multipleChoiceAnswer0">
                        <b-form-textarea
                            id="multipleChoiceAnswer0"
                            v-model="multipleChoiceAnswers[0]"
                            placeholder=""
                            :state="validationState.multipleChoiceAnswer0"
                            size="sm"
                            rows="2"
                            max-rows="6"
                            class="mb-1"
                            required
                        >
                        </b-form-textarea>
                    </b-form-group>
                    <b-form-group label="Falsche Antworten">
                        <b-form-textarea
                            id="multipleChoiceAnswer1"
                            v-model="multipleChoiceAnswers[1]"
                            placeholder=""
                            :state="validationState.multipleChoiceAnswer1"
                            size="sm"
                            rows="2"
                            max-rows="6"
                            class="mb-1"
                            required
                        >
                        </b-form-textarea>
                        <b-form-textarea
                            id="multipleChoiceAnswer2"
                            v-model="multipleChoiceAnswers[2]"
                            placeholder=""
                            :state="validationState.multipleChoiceAnswer2"
                            size="sm"
                            rows="2"
                            max-rows="6"
                            class="mb-1"
                            required
                        >
                        </b-form-textarea>
                        <b-form-textarea
                            id="multipleChoiceAnswer3"
                            v-model="multipleChoiceAnswers[3]"
                            placeholder=""
                            :state="validationState.multipleChoiceAnswer3"
                            size="sm"
                            rows="2"
                            max-rows="6"
                            required
                        >
                        </b-form-textarea>
                    </b-form-group>
                </div>
                <div v-else>
                    <b-form-group label="Richtige Antwort" label-for="estimationAnswer">
                        <b-form-input
                            id="estimationAnswer"
                            type="number"
                            v-model="estimationCorrectAnswer"
                            :state="validationState.estimationCorrectAnswer"
                            required
                        >
                        </b-form-input>
                    </b-form-group>
                </div>
                <div>
                    <b-form-group 
                        label="Hintergrund-Infos" 
                        label-for="infoText"
                        description="Für andere nicht sichtbar: Hintergrund-Infos werden nur dir während des Quiz auf deinem Gerät angezeigt angezeigt."
                    >                        
                        <b-form-textarea
                            id="infoText"
                            v-model="infoText"                                                        
                            size="sm"
                            rows="2"
                            max-rows="6"
                            class="mb-1"
                            required
                        >                        
                        </b-form-textarea>
                        
                    </b-form-group>                    
                </div>
                <b-form-checkbox v-if="dispAssignToQuizOption" v-model="assignToCurrentQuiz">Frage direkt für nächstes Quiz einreichen</b-form-checkbox>
            </b-form>
            <template #modal-footer>
                <div class="align-r">
                    <b-button @click="resetQuestionForm" class="buttonReset" type="reset">Reset</b-button>
                    <b-button @click="closeModal" class="buttonReject ml-1">Abbrechen</b-button>                
                    <b-button v-if="assignToCurrentQuiz" @click="postQuestionButtonClick" class="buttonConfirm ml-1" type="submit">Frage einreichen</b-button>
                    <b-button v-else @click="postQuestionButtonClick" class="buttonConfirm ml-1" type="submit">Frage speichern</b-button>
                </div>               
            </template>          
        </b-modal>
    </b-container>
</template>

<script>
import api from '@/services/api'
import QuestionCard from '@/components/questions/QuestionCard.vue';
import PlayedQuestions from '@/components/questions/PlayedQuestions.vue';

export default {    
    name: 'Questions',
    components: { QuestionCard , PlayedQuestions},
    // directives: { DirectiveName },

    data() {
        return {
            openQuestions : null,
            currentQuiz : null,
            registered : false,
            dispTabTitle : false,
            tabIndex : 0,


            // Modal Input            
            questionType: 'multipleChoice',
            questionTypeOptions: [
                { value: 'multipleChoice', text: 'Multiple Choice Frage' },
                { value: 'estimation', text: 'Schätzfrage' }
            ],
            questionText : '',
            estimationCorrectAnswer : null,
            multipleChoiceCorrectAnswer : 1,
            multipleChoiceAnswers : ['', '', '', ''],
            infoText : null,
            assignToCurrentQuiz: null,

            // ValidationStates
            validationState : {
                questionText : null,
                multipleChoiceAnswer0 : null,
                multipleChoiceAnswer1 : null,
                multipleChoiceAnswer2 : null,
                multipleChoiceAnswer3 : null,
                estimationCorrectAnswer : null,
            }
        };
    },

    computed: {
        titleAssigned : function (){
            if (!this.dispTabTitle){
                return 'Eingereicht'
            }
            if (this.assignedQuestions !== null){
                return "Eingereicht (" + this.assignedQuestions.length + ")"
            }
            else {
                return 'Eingereicht (0)'
            }
        } ,

        titleOpen : function (){
            if (!this.dispTabTitle){
                return 'Offen'
            }
            if (this.unassignedQuestions !== null){
                return "Offen (" + this.unassignedQuestions.length + ")"
            }
            else {
                return 'Offen (0)'
            }
        } ,

        quizIsNotStarted : function () {
            if (this.currentQuiz && this.currentQuiz.status === 'notStarted'){
                return true
            } else {
                return false
            }
        },

        dispCurrentQuiz : function () {
            return !!this.currentQuiz ? true : false;
        },

        questionTypeIsMultipleChoice : function() {            
                return this.questionType === 'multipleChoice' ? true : false;            
        },

        assignedQuestions : function() {
            if (this.currentQuiz && this.openQuestions && this.openQuestions.find(q => String(q.quiz) === String(this.currentQuiz._id))){
                return this.openQuestions.filter(q => String(q.quiz) === String(this.currentQuiz._id))
            }
            else {
                return null;
            }
  
        },
        unassignedQuestions : function() {      
            if (this.openQuestions && this.openQuestions.find(q => !q.quiz)){      
                return this.openQuestions.filter(q => !q.quiz)
            } else {
                return null
            }
        },

        dispAssignedQuestionsHeader : function(){
            return true;
            // return (!!this.currentQuiz && !!this.assignedQuestions)          
        },

        dispUnassignedQuestionsHeader : function(){
            return true;
            // return !!this.currentQuiz && !!this.unassignedQuestions;
        },           
        
        dispAssignToQuizOption : function(){
            if (this.quizIsNotStarted && this.registered.registered)
            {
                return true
            } else {
                return false
            }
        },

        userIsRegistered : function (){
            return (this.registered && this.registered.registered === true) ? true : false;
        },

        hostUser : function (){
            if (this.currentQuiz && this.currentQuiz.hostUser){
                return this.currentQuiz.hostUser;
            }
            else {
                return null;
            }
        },

        dateString: function() {            
            let quizDate = new Date(this.currentQuiz.quizDate);                               
            const dateOptions = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'};            
            let quizDateString = quizDate.toLocaleDateString('de-DE', dateOptions); 
            return quizDateString;
        },

        timeString: function() {
            let quizDate = new Date(this.currentQuiz.quizDate);    
            return (String(quizDate.getHours()) + ':' + this.getMyMinutes + ' Uhr')
        },

        locationString: function(){
            if (this.currentQuiz && this.currentQuiz.location){
                return this.currentQuiz.location + ', bei ' + this.hostUser.prename + ' ' + this.hostUser.surname
            }
            else {
                return this.hostUser.prename + ' ' + this.hostUser.surname;
            }
        },

        getMyMinutes : function (){
            let quizDate = new Date(this.currentQuiz.quizDate);      
            let min = quizDate.getMinutes();
            return min < 10 ? ('0' + String(min)) : String(min);
        },

        showOpenQuestionsHeader : function (){
            return (this.openQuestions && this.openQuestions.length > 0)? true : false;
        }
    },

    props : [],

    methods: {
        async updateView() {
            await api.get('myOpenQuestions').then(res => this.openQuestions = res.data);   
        },

        evaluateForm() {
            this.validationState.questionText = this.questionText.length>0 ? null : false;

            if (this.questionType === 'multipleChoice'){
                this.validationState.multipleChoiceAnswer0 = this.multipleChoiceAnswers[0].length>0 ? null : false;
                this.validationState.multipleChoiceAnswer1 = this.multipleChoiceAnswers[1].length>0 ? null : false;
                this.validationState.multipleChoiceAnswer2 = this.multipleChoiceAnswers[2].length>0 ? null : false;
                this.validationState.multipleChoiceAnswer3 = this.multipleChoiceAnswers[3].length>0 ? null : false;
                this.validationState.estimationCorrectAnswer = null;
            } else {                
                this.validationState.multipleChoiceAnswer0 = null;
                this.validationState.multipleChoiceAnswer1 = null;
                this.validationState.multipleChoiceAnswer2 = null;
                this.validationState.multipleChoiceAnswer3 = null;
                this.validationState.estimationCorrectAnswer = this.estimationCorrectAnswer ? null : false;
            }

            if (this.validationState.questionText === false ||
                this.validationState.multipleChoiceAnswer0 === false ||
                this.validationState.multipleChoiceAnswer1 === false ||
                this.validationState.multipleChoiceAnswer2 === false ||
                this.validationState.multipleChoiceAnswer3 === false ||
                this.validationState.estimationCorrectAnswer === false)
            {
                return false
            } 
            else {
                return true
            }
        },

        async postQuestionButtonClick(){
            if (!this.evaluateForm()) {
                return;
            }
            let body = {               
                    questionType : this.questionType,
                    questionText : this.questionText,                    
                    assignToCurrentQuiz : this.assignToCurrentQuiz,
                    infoText : this.infoText,
            };
            if (this.questionType === 'multipleChoice'){                
                body.multipleChoiceAnswers = this. multipleChoiceAnswers;
                body.multipleChoiceCorrectAnswer = this.multipleChoiceCorrectAnswer;  
            }
            else {
                body.estimationCorrectAnswer = this.estimationCorrectAnswer;
            }
            
            await api.post("question", body)
            .then(async (res) => {
                console.log(res)
                this.$refs['addQuestionModal'].hide();
                await this.updateView();    
                if (this.assignToCurrentQuiz){
                    this.tabIndex = 1
                }
                else {
                    this.tabIndex = 0
                }            
            })
            .catch(error => {
                console.log(error.response)
                this.error = error.response.data.errors;                
            })

        },

        closeModal () {
            this.$refs['addQuestionModal'].hide();
        },

        showNewQuestionModal() {            
            this.resetQuestionForm();
            this.$refs['addQuestionModal'].show();
        },

        resetQuestionForm(){            
            this.questionText = '';
            this.multipleChoiceAnswers = ['', '', '', ''];            
            this.estimationCorrectAnswer = null;          
            this.infoText = null;  
            this.assignToCurrentQuiz =this.registered.registered ? true : false;            
            this.validationState = {
                questionText : null,
                multipleChoiceAnswer0 : null,
                multipleChoiceAnswer1 : null,
                multipleChoiceAnswer2 : null,
                multipleChoiceAnswer3 : null,
                estimationCorrectAnswer : null,
            }
        },
    },
   

    async mounted() {

        await api.get('currentQuiz').then(res => this.currentQuiz = res.data);          
        await api.get('myOpenQuestions').then(res => this.openQuestions = res.data);   
        if (this.currentQuiz){
            this.tabIndex = 1
        }
        await api.get('currentQuizRegisterState').then(res => this.registered = res.data); 
        
        
        this.dispTabTitle = true;

        if (this.registered.registered === true){
            this.assignToCurrentQuiz = true;
        } else {
            this.assignToCurrentQuiz = false;
        }

        
            
    },
};
</script>

<style lang="scss" scoped>

</style>