<template>
    <div>
        <!-- <b-card class="cardHome" v-bind:class="{ submittedQuestion: isAssigned }" no-body> -->
        <b-card class="cardHome" no-body>
            <template #header>
                <span class="cardHeaderQuestion">{{question.questionText}}</span>
            </template>
            <b-list-group flush>
                <b-list-group-item v-if="isMultipleChoice">
                    <b>Richtige Antwort:</b>
                    <ul>
                        <li>{{question.multipleChoiceAnswers[0]}}</li>
                    </ul>
                </b-list-group-item>
                <b-list-group-item v-else>
                    <b>Richtige Antwort:</b><br>{{question.estimationCorrectAnswer}}
                </b-list-group-item>
                <b-list-group-item v-if="isMultipleChoice">
                    <b>Falsche Antworten:</b> 
                    <ul>
                        <li>{{question.multipleChoiceAnswers[1]}}</li>
                        <li>{{question.multipleChoiceAnswers[2]}}</li>
                        <li>{{question.multipleChoiceAnswers[3]}}</li>
                    </ul>
                </b-list-group-item>
                <b-list-group-item v-if="infoTextAvailable">
                    <b>Hintergrund-Infos:</b> 
                    <ul>
                        <li>{{question.infoText}}</li>                        
                    </ul>
                </b-list-group-item>
                <b-list-group-item class="LastListGroupItem align-r">
                    <b-button v-if="dispEditButton" @click="showModal" class="buttonStandard mr-1"><b-icon icon="pencil"/></b-button>
                    <b-button v-if="dispUnassignButton" @click="unassignQuestion" class="buttonReject">Von Quiz zurückziehen</b-button>
                    <b-button v-if="dispAssignButton" @click="assignQuestion" class="buttonConfirm">Zu Quiz einreichen</b-button>
                </b-list-group-item>
            </b-list-group>
        </b-card>

        <!-- Modal to update question -->
        <b-modal id="update-question-modal" ref="updateQuestionModal" title="Frage bearbeiten">
            <b-form>
                <b-form-group label="Frage" label-for="questionTextInput">
                    <b-form-textarea 
                        id="questionTextInput"
                        v-model="modalData.questionText"
                        :state="validationState.questionText"
                        size="sm"
                        rows="2"
                        max-rows="6"
                        required
                    >
                    </b-form-textarea>                
                </b-form-group>
                <div v-if="isMultipleChoice">
                    <b-form-group label="Richtige Antwort" label-for="correctAnswer">
                        <b-form-textarea
                            id="correctAnswer"
                            v-model="modalData.multipleChoiceAnswer0"
                            :state="validationState.multipleChoiceAnswer0"
                            size="sm"
                            rows="2"
                            max-rows="6"
                            class="mb-1"
                            required
                        >
                        </b-form-textarea>
                    </b-form-group>
                    <b-form-group label="Falsche Antworten">
                        <b-form-textarea
                            id="falseAnswer1"
                            v-model="modalData.multipleChoiceAnswer1"                            
                            :state="validationState.multipleChoiceAnswer1"
                            size="sm"
                            rows="2"
                            max-rows="6"
                            class="mb-1"
                            required
                        >
                        </b-form-textarea>
                        <b-form-textarea
                            id="falseAnswer2"
                            v-model="modalData.multipleChoiceAnswer2"
                            :state="validationState.multipleChoiceAnswer2"
                            size="sm"
                            rows="2"
                            max-rows="6"
                            class="mb-1"
                            required
                        >
                        </b-form-textarea>
                        <b-form-textarea
                            id="falseAnswer3"
                            v-model="modalData.multipleChoiceAnswer3"
                            :state="validationState.multipleChoiceAnswer3"
                            size="sm"
                            rows="2"
                            max-rows="6"
                            required
                        >
                        </b-form-textarea>
                    </b-form-group>
                </div>
                <div v-else>
                    <b-form-group label="Richtige Antwort" label-for="estimationAnswer">
                        <b-form-input
                            id="estimationAnswer"
                            type="number"
                            v-model="modalData.estimationCorrectAnswer"
                            :state="validationState.estimationCorrectAnswer"
                            required
                        >
                        </b-form-input>
                    </b-form-group>
                </div>
                <div>
                    <b-form-group 
                        label="Hintergrund-Infos" 
                        label-for="infoText"
                        description="Für andere nicht sichtbar: Hintergrund-Infos werden nur dir während des Quiz auf deinem Gerät angezeigt angezeigt."
                    >                        
                        <b-form-textarea
                            id="infoText"
                            v-model="modalData.infoText"                                                        
                            size="sm"
                            rows="2"
                            max-rows="6"
                            class="mb-1"
                            required
                        >                        
                        </b-form-textarea>                        
                    </b-form-group>
                </div>
            </b-form>
            <template #modal-footer>
                <div class="align-r">
                    <b-button id="delete-button" class="buttonReset" @click="showDeletePopover = !showDeletePopover"><b-icon icon="trash" /></b-button>
                    <b-button @click="closeModal" class="buttonReject ml-1">Abbrechen</b-button>
                    <b-button type="submit" class="buttonConfirm ml-1" @click="updateQuestionButtonClick">OK</b-button>
                </div>               
            </template>          
        </b-modal>

        <!-- Popup to confirm deletion of question -->
        <b-popover :show.sync="showDeletePopover" target="delete-button" variant="danger" triggers="focus" placement="top">
            <template #title>
                <p>Sicher?</p>                            
                <b-button variant="danger" @click="deleteQuestionButtonClick">Ja, Frage löschen</b-button>
            </template>
        </b-popover>
    </div>
</template>

<script>
import api from '@/services/api'

export default {
    name: 'questionCard',
    // components: { ComponentName },
    // directives: { DirectiveName },
    props:['question', 'quiz', 'registered'],

    data() {
        return {
            showDeletePopover : false,
            // borderVariant : "primary",

            //ModalData
            modalData : {
                questionText : this.question.questionText,                
                estimationCorrectAnswer : this.question.estimationCorrectAnswer,
                multipleChoiceAnswer0 : this.question.multipleChoiceAnswers[0],
                multipleChoiceAnswer1 : this.question.multipleChoiceAnswers[1],
                multipleChoiceAnswer2 : this.question.multipleChoiceAnswers[2],
                multipleChoiceAnswer3 : this.question.multipleChoiceAnswers[3],
                infoText : this.question.infoText,
            },

            // ValidationStates
            validationState : {
                questionText : null,
                multipleChoiceAnswer0 : null,
                multipleChoiceAnswer1 : null,
                multipleChoiceAnswer2 : null,
                multipleChoiceAnswer3 : null,
                estimationCorrectAnswer : null,
            }
        }
    },


    methods: {        

       
        async unassignQuestion(){
            await api.get('deactivateQuestion/' + String(this.question._id))
                .then(this.$emit("update"))            
                .catch(error => console.log(error.response))
        },

        async assignQuestion(){
            await api.get('activateQuestion/' + String(this.question._id))
                .then(this.$emit("update"))
                .catch(error => console.log(error.response))
        },

        async deleteQuestionButtonClick(){          
            await api.delete('question/' + String(this.question._id))
                .then(this.$emit("update"))
                .catch(error => console.log(error.response))
        },

        closeModal(){
            this.$refs["updateQuestionModal"].hide()
        },

        showModal(){
            this.$refs["updateQuestionModal"].show()
        },

        async updateQuestionButtonClick(){
            let valid = this.evaluateForm()
            if (valid) {
                let body = {
                    questionText : this.modalData.questionText,                
                }
                if (this.question.questionType === 'multipleChoice'){                
                    body.multipleChoiceAnswers = [
                        this.modalData.multipleChoiceAnswer0,
                        this.modalData.multipleChoiceAnswer1,
                        this.modalData.multipleChoiceAnswer2,
                        this.modalData.multipleChoiceAnswer3                        
                    ];      
                    body.multipleChoiceCorrectAnswer = this.modalData.multipleChoiceCorrectAnswer;                    
                }
                else {
                    body.estimationCorrectAnswer = this.modalData.estimationCorrectAnswer;
                }
                body.infoText = this.modalData.infoText;

                await api.put('question/' + String(this.question._id),body)
                .then(async (res) => {
                    this.closeModal()
                    await this.$emit("update")
                })
                .catch(error => console.log(error.response))
            }
    },

        evaluateForm() {
            this.validationState.questionText = this.modalData.questionText.length>0 ? null : false;

            if (this.question.questionType === 'multipleChoice'){
                this.validationState.multipleChoiceAnswer0 = this.modalData.multipleChoiceAnswer0.length>0 ? null : false;
                this.validationState.multipleChoiceAnswer1 = this.modalData.multipleChoiceAnswer1.length>0 ? null : false;
                this.validationState.multipleChoiceAnswer2 = this.modalData.multipleChoiceAnswer2.length>0 ? null : false;
                this.validationState.multipleChoiceAnswer3 = this.modalData.multipleChoiceAnswer3.length>0 ? null : false;
                this.validationState.estimationCorrectAnswer = null;
            } else {                
                this.validationState.multipleChoiceAnswer0 = null;
                this.validationState.multipleChoiceAnswer1 = null;
                this.validationState.multipleChoiceAnswer2 = null;
                this.validationState.multipleChoiceAnswer3 = null;
                this.validationState.estimationCorrectAnswer = this.modalData.estimationCorrectAnswer ? null : false;
            }

            if (this.validationState.questionText === false ||
                this.validationState.multipleChoiceAnswer0 === false ||
                this.validationState.multipleChoiceAnswer1 === false ||
                this.validationState.multipleChoiceAnswer2 === false ||
                this.validationState.multipleChoiceAnswer3 === false ||
                this.validationState.estimationCorrectAnswer === false)
            {
                return false
            } 
            else {
                return true
            }
        },
       
    },

    computed : {
        isAssigned : function(){
            return (this.quiz && this.question.quiz) ? true : false;
        },
        
        isMultipleChoice : function(){
            return (this.question && this.question.questionType==="multipleChoice")? true : false;
        },

        isPlayed : function(){
            return (this.question.status && this.question.status === 'evaluated')? true : false;
        },

        quizIsNotStarted : function (){
            return (!this.quiz || this.quiz.status === 'notStarted') ? true : false;
        },

        dispEditButton : function (){
            return (this.quizIsNotStarted || !this.isAssigned) ? true : false;
        },

        dispUnassignButton : function(){
            return (this.quizIsNotStarted && this.isAssigned)? true : false;
        },

        dispAssignButton : function(){
            return (this.quizIsNotStarted && !this.isAssigned && this.registered)? true : false;            
        },

        infoTextAvailable : function (){
            return (!!this.question.infoText) ? true : false;
        },

    },

    
    mounted() {
        
    },
    
};
</script>

<style lang="scss" scoped>

</style>