<template>
    <div>  
        <div> 
            <b-card class="cardHome">
                <template #header>
                    <div v-if="firstGroup" class="mb-0 cardHeader" style="">
                        Herzlich Willkommen!
                    </div>
                    <div v-else class="mb-0 cardHeader" style="">
                        Lust auf noch mehr Quizspaß?
                    </div>
                </template>                
                <b-card-text v-if="firstGroup">
                    Sieht aus, als wärst du neu hier ...<br><br>
                                        
                    Lass dich von einem deiner Freunde zu einer bereits bestehenden Quizgrzuppe einladen oder erstelle jetzt deine eigene Quizgruppe, um weitere Quizzer einzuladen.
                </b-card-text>
                <b-card-text v-else>
                    Dann gründe jetzt eine neue Quizgruppe und lade weitere Quizer:innen ein!
                </b-card-text>
                <template #footer>
                    <div class="align-r">
                        <b-button @click="showNewGroupModal" class="buttonStandard">Neue Quizgruppe gründen</b-button>
                    </div>
                </template>
                
            </b-card>
        </div>
    
        <!-- Modal for new group -->
        <div>
            <b-modal id="add-group-modal" ref="addGroupModal" title="Neue Gruppe erstellen">
                <b-form-input v-model="name" :state="validationState.name" type="text" placeholder="Gruppenname" required></b-form-input>
                <template #modal-footer>
                    <b-button type="reset" @click="closeNewGroupModal" class="buttonReject">Abbrechen</b-button>
                    <b-button type="submit" @click="createGroupButtonClick" class="mr-1 buttonConfirm">Erstellen</b-button>
                </template>
            </b-modal>
        </div>
       
    </div>

</template>

<script>
import api from '@/services/api';

export default {
    name: 'CreateGroup',
    props:['firstGroup'],

    data () {
        return {
            name : '',

            // validation
            validationState : {
                name : null,
            }
        }

    },

    methods : {
        showNewGroupModal() {
            this.$refs['addGroupModal'].show();
        },

        closeNewGroupModal () {
            this.$refs['addGroupModal'].hide();
        },

        async createGroupButtonClick (){     
            if (!this.validateForm()){
                return
            }
            
            let body = {
                name : this.name,                
            }
            
            await api.post('group',body)
            .then(async (res) => {
                console.log(res)
                this.closeNewGroupModal();
                await this.$store.dispatch("updateCurrentGroupAction");
                await this.$store.dispatch("updateMyGroupsAction");
                await this.$store.dispatch("updateRouterView");                
            })
            .catch(error => {
                console.log(error.response)
                this.error = error.response.data.errors;                
            })
        },

        validateForm(){
            this.validationState.name = this.name? null : false;            

            if (this.validationState.name === false)                
            {
                return false
            } 
            else {
                return true
            }
        },
    }
}
</script>

<style>

</style>