<template>
<b-container style="padding-top: 10px;">
    <div v-if="dispQuestion || dispWelcomeScreen || dispSpinner">
        <!-- Progress bar -->
        <b-row>
            <b-col cols="auto">
                <b-avatar v-if="dispSpinner || dispQuestion && !breakActive" size="35px" class="avatarAdmin">
                    {{question.user.prename.charAt(0) + question.user.surname.charAt(0)}}
                </b-avatar>
                
                <!-- <b-avatar v-else size="35px" :src="require('../assets/QLogo.png')"></b-avatar> -->
            </b-col>
            <b-col>
                <b-progress :max="quiz.totalNumberOfQuestions" class="quizProgress">
                    <b-progress-bar :value="quiz.currentQuestionNumber" class="colorMain">
                        <span>{{ quiz.currentQuestionNumber }} / {{ quiz.totalNumberOfQuestions }}</span>
                    </b-progress-bar>
                </b-progress>
            </b-col>
        </b-row>
        <!-- Lobby/Welcome Screen -->
        <b-row v-if="dispWelcomeScreen">
            <b-col cols="12" class="questionText">Willkommen zum Supertollen Quizabend!</b-col>
            <b-col cols="12">
                Die Spannung steigt, die Lobby füllt sich langsam ...<br>
                Wer wird wohl heute den goldenen Pokal gewinnen? <br><br>
                Hol dir am besten schnell noch ein Bier und dann geht es gleich auch schon los.
            </b-col>
            <!-- <b-col>
                <b-list-group>
                    <b-list-group-item v-for="result in ranking" :key="result._id" class="d-flex align-items-center">
                        <b-avatar class="avatarAdmin mr-3">
                            {{result.user.prename.charAt(0) + result.user.surname.charAt(0)}}
                        </b-avatar>
                        <span class="mr-auto">{{result.user.prename}} {{result.user.surname}}</span>
                        <b-badge>{{numberOfpostedQuestions(result.user._id)}}</b-badge>
                    </b-list-group-item>
                </b-list-group>
            </b-col> -->
        </b-row>

        <div v-if="dispSpinner">
            <br><br>
            <b-spinner style="width: 4rem; height: 4rem;" label="Large Spinner"></b-spinner>
        </div>

        <div v-if="breakActive">
            <b-row>
                <b-col class="questionText"><br><br> Pinkelpause <b-icon icon="emoji-heart-eyes"></b-icon> </b-col>
            </b-row>
        </div>

        <div v-else-if="dispQuestion">
            

            <!-- Joker -->
            <b-row v-if="dispQuestion && questionIsMultipleChoice">
                <b-col cols="12" class="text-right">
                    <!-- Majority Joker -->
                    <svg v-if="jokerMajorityAnswerAllowed" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="2rem" viewBox="0 0 80 50" style="enable-background:new 0 0 80 50;" xml:space="preserve" @click="jokerMajorityAnswerButtonClick" v-bind:class="{jokerSymbol: (jokerMajorityAnswerAvailable && answersEnabled && !dispFiftyFifty), jokerSymbolInactive: (!jokerMajorityAnswerAvailable || !answersEnabled || dispFiftyFifty)}">
                        <path d="M40.4,18.4c1.3,0,2.5,0.2,3.6,0.6c0.1-1.5,0.7-2.8,1.7-3.9c-0.7-0.1-1.4-0.2-2.2-0.2h-7.1
                            c-0.7,0-1.5,0.1-2.2,0.2c1.1,1.2,1.7,2.7,1.7,4.2C37.4,18.7,38.9,18.4,40.4,18.4z"/>
                        <ellipse cx="40.1" cy="7.7" rx="8.4" ry="6.7"/>
                        <path d="M53.2,25.9c4.3,0,7.7-2.8,7.7-6.2c0-3.4-3.4-6.2-7.7-6.2c-4.2,0-7.6,2.7-7.7,6.1
                            c2.4,1.2,4.1,3.3,4.4,5.7C50.9,25.7,52,25.9,53.2,25.9z"/>
                        <path d="M26.9,13.5c-4.3,0-7.7,2.8-7.7,6.2s3.4,6.2,7.7,6.2c1.5,0,2.9-0.4,4.1-1c0.4-2,1.7-3.7,3.6-4.9
                            c0-0.1,0-0.2,0-0.3C34.6,16.3,31.2,13.5,26.9,13.5z"/>
                        <path d="M58.2,13.4c2.4,1.2,4.1,3.3,4.4,5.7c1,0.4,2.1,0.6,3.3,0.6c4.3,0,7.7-2.8,7.7-6.2
                            s-3.4-6.2-7.7-6.2C61.7,7.3,58.2,10,58.2,13.4z"/>
                        <ellipse cx="40.4" cy="26.1" rx="7.7" ry="6.2"/>
                        <path d="M56.4,26.3H50c-0.1,2.1-1.2,4-2.9,5.3c4.8,1.2,8.4,4.8,8.4,9v2c6.4-0.2,10.1-1.6,10.3-1.7
                            l0.5-0.2h0.1v-6.4C66.3,29.9,61.9,26.3,56.4,26.3z"/>
                        <path d="M69.1,20.1h-6.5c-0.1,2.1-1.2,4-2.9,5.3c4.8,1.2,8.4,4.8,8.4,9v2c6.4-0.2,10.1-1.6,10.3-1.7
                            l0.5-0.2H79V28C79,23.6,74.6,20.1,69.1,20.1z"/>
                        <path d="M33.8,31.6c-1.7-1.3-2.8-3.2-2.9-5.3c-0.2,0-0.5,0-0.7,0h-6.5c-5.4,0-9.9,3.6-9.9,7.9v6.4l0,0.1
                            l0.6,0.1c4.2,1,7.9,1.5,11.1,1.7v-1.9C25.5,36.4,29,32.8,33.8,31.6z"/>
                        <path d="M43.7,32.7h-6.5c-5.4,0-9.9,3.6-9.9,7.9V47l0,0.1l0.5,0.1c5.2,1.3,9.7,1.7,13.4,1.7
                            c7.2,0,11.4-1.7,11.7-1.8l0.5-0.2h0.1v-6.4C53.6,36.2,49.1,32.7,43.7,32.7z"/>
                        <path d="M14.1,19.9c1.2,0,2.3-0.2,3.3-0.6c0.3-2.4,2-4.5,4.4-5.7c-0.1-3.4-3.5-6.1-7.7-6.1
                            c-4.3,0-7.7,2.8-7.7,6.2C6.4,17.1,9.9,19.9,14.1,19.9z"/>
                        <path d="M20.3,25.6c-1.8-1.3-2.9-3.2-2.9-5.3h-6.5c-5.4,0-9.9,3.6-9.9,7.9v6.4h0.1l0.5,0.2
                            c0.2,0.1,3.9,1.6,10.3,1.7v-2C11.9,30.3,15.4,26.7,20.3,25.6z"/>
                        <path d="M23.1,12.9c0.9-0.3,2.3-0.7,3.7-0.7c1.6,0,3.1,0.3,4.5,0.9c0.3-0.2,0.6-0.5,0.8-0.8
                            c-1.3-1.4-2-3-2-4.8c0-1.2,0.3-2.3,0.9-3.4c-1.4-1-3.2-1.6-5.1-1.6c-3.5,0-6.5,1.9-7.4,4.5C21.2,8.4,22.9,10.5,23.1,12.9z"/>
                        <path d="M48.1,12c0.3,0.3,0.6,0.7,0.9,1c1.3-0.5,2.7-0.8,4.1-0.8c1.4,0,2.7,0.3,3.6,0.5
                            c0.3-2.6,2.2-4.8,5.1-6c-1.1-2.4-3.9-4.1-7.2-4.1c-2.1,0-4.1,0.7-5.5,1.9c0.5,1,0.8,2,0.8,3C49.8,9.2,49.2,10.7,48.1,12z"/>
                        <!-- Joker already used -->
                        <g id="Strikethrough" v-if="!jokerMajorityAnswerAvailable" v-bind:class="{jokerStrikethrough : (answersEnabled && !dispFiftyFifty)}">
                            <path d="M0,50c0,0,1.1-1,3.1-2.6c1-0.8,2.2-1.8,3.6-3c0.7-0.6,1.5-1.2,2.3-1.8
                                c0.8-0.6,1.8-1.1,2.7-1.7c7.5-4.7,17.5-10.9,27.5-17.2s20-12.5,27.5-17.2c0.5-0.3,0.9-0.6,1.4-0.9c0.4-0.3,0.9-0.6,1.3-0.8
                                C70.3,4.4,71.2,4,72,3.6c1.6-0.8,3.1-1.4,4.2-2C78.6,0.6,80,0,80,0s-1.1,1-3.1,2.6c-1,0.8-2.2,1.8-3.6,3c-0.7,0.6-1.5,1.2-2.3,1.8
                                c-0.4,0.3-0.8,0.6-1.3,0.9c-0.5,0.3-0.9,0.6-1.4,0.8C60.8,13.8,50.8,20,40.8,26.3s-20,12.5-27.5,17.2c-1,0.5-1.8,1.2-2.7,1.7
                                C9.7,45.6,8.8,46,8,46.4c-1.6,0.8-3.1,1.4-4.3,2C1.4,49.4,0,50,0,50z"/>
                            <path d="M0,0c0,0,1.4,0.6,3.7,1.7c1.2,0.5,2.6,1.2,4.3,2C8.8,4,9.7,4.4,10.6,4.9
                                c0.9,0.4,1.7,1.1,2.7,1.7c7.5,4.7,17.5,10.9,27.5,17.2s20,12.5,27.5,17.2c0.5,0.3,0.9,0.6,1.4,0.8c0.5,0.3,0.9,0.5,1.3,0.9
                                c0.8,0.6,1.5,1.2,2.3,1.8c1.4,1.1,2.6,2.1,3.6,3C78.9,49,80,50,80,50s-1.4-0.6-3.7-1.7c-1.2-0.5-2.6-1.2-4.2-2
                                c-0.8-0.4-1.7-0.8-2.6-1.2c-0.5-0.2-0.9-0.5-1.3-0.8c-0.4-0.3-0.9-0.6-1.4-0.9c-7.5-4.7-17.5-10.9-27.5-17.2s-20-12.5-27.5-17.2
                                C10.8,8.5,9.8,8,9,7.4C8.2,6.8,7.5,6.2,6.8,5.6c-1.4-1.1-2.6-2.1-3.6-3C1.1,1,0,0,0,0z"/>
                        </g>
                    </svg>
                    <!-- 50:50 Joker -->
                    <svg  v-if="jokerFiftyFiftyAllowed" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="2rem" viewBox="0 0 80 50" style="enable-background:new 0 0 80 50;" xml:space="preserve" @click="jokerFiftyFiftyButtonClick" v-bind:class="{jokerSymbol: (jokerFiftyFiftyAvailable && answersEnabled && !dispFiftyFifty), jokerSymbolInactive: (!jokerFiftyFiftyAvailable || !answersEnabled || dispFiftyFifty)}">
                        <path d="M1,30.4l4.3-0.4c0.1,1,0.5,1.8,1.1,2.3c0.6,0.6,1.3,0.9,2.1,0.9c0.9,0,1.7-0.4,2.3-1.1s0.9-1.8,0.9-3.3
                            c0-1.4-0.3-2.4-0.9-3.1c-0.6-0.7-1.4-1-2.4-1c-1.2,0-2.4,0.6-3.4,1.7l-3.5-0.5l2.2-11.8h11.5v4.1H7l-0.7,3.8c1-0.5,2-0.7,3-0.7
                            c1.9,0,3.6,0.7,4.9,2.1c1.3,1.4,2,3.2,2,5.5c0,1.9-0.5,3.5-1.6,5c-1.5,2-3.5,3-6.1,3c-2.1,0-3.8-0.6-5.1-1.7
                            C2,33.8,1.3,32.3,1,30.4z"/>
                        <path  d="M25.9,13.4c2.2,0,3.9,0.8,5.1,2.3c1.5,1.9,2.2,4.9,2.2,9.2c0,4.3-0.7,7.4-2.2,9.2c-1.2,1.5-2.9,2.3-5.1,2.3
                            c-2.2,0-4-0.8-5.3-2.5c-1.4-1.7-2-4.7-2-9.1c0-4.3,0.7-7.3,2.2-9.2C22,14.2,23.7,13.4,25.9,13.4z M25.9,17c-0.5,0-1,0.2-1.4,0.5
                            s-0.7,0.9-1,1.8c-0.3,1.1-0.4,3-0.4,5.7c0,2.7,0.1,4.5,0.4,5.5c0.3,1,0.6,1.7,1,2c0.4,0.3,0.9,0.5,1.4,0.5c0.5,0,1-0.2,1.4-0.5
                            c0.4-0.3,0.7-0.9,1-1.8c0.3-1.1,0.4-3,0.4-5.7c0-2.7-0.1-4.5-0.4-5.5c-0.3-1-0.6-1.7-1-2C26.9,17.2,26.4,17,25.9,17z"/>
                        <path d="M37.9,24.1v-4.3h4.3v4.3H37.9z M37.9,36.2v-4.3h4.3v4.3H37.9z"/>
                        <path d="M46.8,30.4l4.3-0.4c0.1,1,0.5,1.8,1.1,2.3c0.6,0.6,1.3,0.9,2.1,0.9c0.9,0,1.7-0.4,2.3-1.1
                            c0.6-0.7,0.9-1.8,0.9-3.3c0-1.4-0.3-2.4-0.9-3.1c-0.6-0.7-1.4-1-2.4-1c-1.2,0-2.4,0.6-3.4,1.7l-3.5-0.5l2.2-11.8H61v4.1h-8.2
                            l-0.7,3.8c1-0.5,2-0.7,3-0.7c1.9,0,3.6,0.7,4.9,2.1c1.3,1.4,2,3.2,2,5.5c0,1.9-0.5,3.5-1.6,5c-1.5,2-3.5,3-6.1,3
                            c-2.1,0-3.8-0.6-5.1-1.7C47.8,33.8,47,32.3,46.8,30.4z"/>
                        <path d="M71.6,13.4c2.2,0,3.9,0.8,5.1,2.3c1.5,1.9,2.2,4.9,2.2,9.2c0,4.3-0.7,7.4-2.2,9.2
                            c-1.2,1.5-2.9,2.3-5.1,2.3c-2.2,0-4-0.8-5.3-2.5c-1.4-1.7-2-4.7-2-9.1c0-4.3,0.7-7.3,2.2-9.2C67.7,14.2,69.4,13.4,71.6,13.4z
                            M71.6,17c-0.5,0-1,0.2-1.4,0.5c-0.4,0.3-0.7,0.9-1,1.8c-0.3,1.1-0.4,3-0.4,5.7c0,2.7,0.1,4.5,0.4,5.5c0.3,1,0.6,1.7,1,2
                            c0.4,0.3,0.9,0.5,1.4,0.5c0.5,0,1-0.2,1.4-0.5c0.4-0.3,0.7-0.9,1-1.8c0.3-1.1,0.4-3,0.4-5.7c0-2.7-0.1-4.5-0.4-5.5
                            c-0.3-1-0.6-1.7-1-2C72.6,17.2,72.2,17,71.6,17z"/>
                        <!-- Joker already used -->
                        <g id="Strikethrough" v-if="!jokerFiftyFiftyAvailable" v-bind:class="{jokerStrikethrough : (answersEnabled && !dispFiftyFifty)}">
                            <path d="M0,50c0,0,1.1-1,3.1-2.6c1-0.8,2.2-1.8,3.6-3c0.7-0.6,1.5-1.2,2.3-1.8
                                c0.8-0.6,1.8-1.1,2.7-1.7c7.5-4.7,17.5-10.9,27.5-17.2s20-12.5,27.5-17.2c0.5-0.3,0.9-0.6,1.4-0.9c0.4-0.3,0.9-0.6,1.3-0.8
                                C70.3,4.4,71.2,4,72,3.6c1.6-0.8,3.1-1.4,4.2-2C78.6,0.6,80,0,80,0s-1.1,1-3.1,2.6c-1,0.8-2.2,1.8-3.6,3c-0.7,0.6-1.5,1.2-2.3,1.8
                                c-0.4,0.3-0.8,0.6-1.3,0.9c-0.5,0.3-0.9,0.6-1.4,0.8C60.8,13.8,50.8,20,40.8,26.3s-20,12.5-27.5,17.2c-1,0.5-1.8,1.2-2.7,1.7
                                C9.7,45.6,8.8,46,8,46.4c-1.6,0.8-3.1,1.4-4.3,2C1.4,49.4,0,50,0,50z"/>
                            <path d="M0,0c0,0,1.4,0.6,3.7,1.7c1.2,0.5,2.6,1.2,4.3,2C8.8,4,9.7,4.4,10.6,4.9
                                c0.9,0.4,1.7,1.1,2.7,1.7c7.5,4.7,17.5,10.9,27.5,17.2s20,12.5,27.5,17.2c0.5,0.3,0.9,0.6,1.4,0.8c0.5,0.3,0.9,0.5,1.3,0.9
                                c0.8,0.6,1.5,1.2,2.3,1.8c1.4,1.1,2.6,2.1,3.6,3C78.9,49,80,50,80,50s-1.4-0.6-3.7-1.7c-1.2-0.5-2.6-1.2-4.2-2
                                c-0.8-0.4-1.7-0.8-2.6-1.2c-0.5-0.2-0.9-0.5-1.3-0.8c-0.4-0.3-0.9-0.6-1.4-0.9c-7.5-4.7-17.5-10.9-27.5-17.2s-20-12.5-27.5-17.2
                                C10.8,8.5,9.8,8,9,7.4C8.2,6.8,7.5,6.2,6.8,5.6c-1.4-1.1-2.6-2.1-3.6-3C1.1,1,0,0,0,0z"/>
                        </g>
                    </svg>
                    <!-- Copy Joker -->
                    <svg v-if="jokerCopyAnswerAllowed" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="2rem" viewBox="0 0 80 50" style="enable-background:new 0 0 80 50;" xml:space="preserve" @click="jokerCopyAnswerButtonClick" v-bind:class="{jokerSymbol: (jokerCopyAnswerAvailable && answersEnabled && !dispFiftyFifty), jokerSymbolInactive: (!jokerCopyAnswerAvailable || !answersEnabled || dispFiftyFifty)}">
                        <circle cx="43.8" cy="17.2" r="2.5"/>
                        <circle cx="39.7" cy="34.2" r="2.5"/>
                        <path d="M74.5,30.2c0.7-0.5,1.5-0.7,2.4-0.6c-0.5-9.3-7.9-12.1-15.8-8.5C59.9,9.7,48,4.5,43.9,13.8
                            c1.1,0,2.1,0.4,2.9,1.7c2.9-1.4,4.5,0.8,4.5,7.2c-4.7-3.1-15.2-4.1-16.6,4.4c-0.3,1.6,0.6,3.3,2.3,5.2c0.4-0.7,1.1-1.2,1.9-1.3
                            c-0.3-4,4.3-4.9,6.2,0c-0.4,2.2,0,4.9,0.2,6.1c0.4,2.3,1.3,4.5,2.5,6.7c1.9,3.3,4.3,5.1,7.2,5.1c5.5,0.1,8.8-5.7,10-10.4
                            c0.5-1.9,1.1-5.3,0.4-7.8C67.8,27,71.3,26.8,74.5,30.2z M48.1,35.9c-0.5-0.2-0.8-0.4-0.9-0.6c-0.3-0.8,3-2.5,5.1-0.6
                            C55.4,37.6,49.2,36.4,48.1,35.9z M58.3,43.5c-1.6,1.9-3.7,1.8-5.5,0.3c-0.8-0.7-1.2-1.5-1.4-2.5c2.7,1.6,5.4,1.7,8-0.1
                            C59.1,42.1,58.8,42.9,58.3,43.5z M63.3,35.3c-0.1,0.2-0.3,0.4-0.8,0.6c-1.1,0.5-7.2,1.9-4.3-1C60.2,32.9,63.6,34.5,63.3,35.3z"/>
                        <path d="M76.5,30.3c-1.4,0-2.5,0.3-2.5,1.7c0,1.4,1.1,3.3,2.5,3.3c1.4,0,2.5-1.1,2.5-2.5
                            C79,31.4,77.9,30.3,76.5,30.3z"/>
                        <circle cx="15.5" cy="17.5" r="8.5"/>
                        <path d="M19.1,26.6h-7.2C5.9,26.6,1,31.5,1,37.5v8.8l0,0.1l0.6,0.2C7.4,48.4,12.3,49,16.4,49
                            c8,0,12.6-2.3,12.9-2.4l0.6-0.3H30v-8.8C30,31.5,25.1,26.6,19.1,26.6z"/>
                        <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
                            <path  d="M523.5,12737.1c-0.4,0.3-1.9,1.5-3.3,2.6c-3.6,2.9-7,5.5-11.5,8.9c-41.1,30.3-81.6,44-120.5,41
                                c-20.3-1.6-41.1-8.3-59.4-19.3c-10.2-6.1-18.8-12.7-27.9-21.3c-4.9-4.6-8.1-7.9-11.8-12.2c-1.4-1.6-2.6-3-2.6-3
                                c-0.1-0.1-3.8,3.7-14.2,14.7l-14.1,14.9l-1.1,0.4c-2.4,0.8-4.6,0.6-6.7-0.8c-1.2-0.8-2.3-2.1-2.9-3.6c-0.3-0.8-0.7-10.9-1.8-46.6
                                c-0.8-25.1-1.3-46.2-1.2-47c0.3-2.7,2.1-5,4.5-5.9c0.8-0.3,9-1.4,26.2-3.5c13.8-1.7,36.1-4.3,49.6-5.9c13.5-1.6,25-2.9,25.6-2.9
                                c2,0.1,4.1,1.3,5.2,2.9c1.9,2.6,2,5.9,0.3,8.5c-0.4,0.6-5.7,6.4-14.8,16l-14.2,15l0.3,0.5c0.2,0.2,1,1.4,1.9,2.5
                                c8.5,10.9,19.8,21,31.8,28.6c30.9,19.5,68.6,25,111,16.2c13.6-2.8,27-6.9,43.8-13.5c2.9-1.1,4.4-1.3,6.1-0.8
                                c5.1,1.5,7,7.8,3.5,11.9C524.7,12735.9,523.9,12736.7,523.5,12737.1z"/>
                        </g>
                        <!-- Joker already used -->
                        <g id="Strikethrough" v-if="!jokerCopyAnswerAvailable" v-bind:class="{jokerStrikethrough : (answersEnabled && !dispFiftyFifty)}">
                            <path d="M0,50c0,0,1.1-1,3.1-2.6c1-0.8,2.2-1.8,3.6-3c0.7-0.6,1.5-1.2,2.3-1.8
                                c0.8-0.6,1.8-1.1,2.7-1.7c7.5-4.7,17.5-10.9,27.5-17.2s20-12.5,27.5-17.2c0.5-0.3,0.9-0.6,1.4-0.9c0.4-0.3,0.9-0.6,1.3-0.8
                                C70.3,4.4,71.2,4,72,3.6c1.6-0.8,3.1-1.4,4.2-2C78.6,0.6,80,0,80,0s-1.1,1-3.1,2.6c-1,0.8-2.2,1.8-3.6,3c-0.7,0.6-1.5,1.2-2.3,1.8
                                c-0.4,0.3-0.8,0.6-1.3,0.9c-0.5,0.3-0.9,0.6-1.4,0.8C60.8,13.8,50.8,20,40.8,26.3s-20,12.5-27.5,17.2c-1,0.5-1.8,1.2-2.7,1.7
                                C9.7,45.6,8.8,46,8,46.4c-1.6,0.8-3.1,1.4-4.3,2C1.4,49.4,0,50,0,50z"/>
                            <path d="M0,0c0,0,1.4,0.6,3.7,1.7c1.2,0.5,2.6,1.2,4.3,2C8.8,4,9.7,4.4,10.6,4.9
                                c0.9,0.4,1.7,1.1,2.7,1.7c7.5,4.7,17.5,10.9,27.5,17.2s20,12.5,27.5,17.2c0.5,0.3,0.9,0.6,1.4,0.8c0.5,0.3,0.9,0.5,1.3,0.9
                                c0.8,0.6,1.5,1.2,2.3,1.8c1.4,1.1,2.6,2.1,3.6,3C78.9,49,80,50,80,50s-1.4-0.6-3.7-1.7c-1.2-0.5-2.6-1.2-4.2-2
                                c-0.8-0.4-1.7-0.8-2.6-1.2c-0.5-0.2-0.9-0.5-1.3-0.8c-0.4-0.3-0.9-0.6-1.4-0.9c-7.5-4.7-17.5-10.9-27.5-17.2s-20-12.5-27.5-17.2
                                C10.8,8.5,9.8,8,9,7.4C8.2,6.8,7.5,6.2,6.8,5.6c-1.4-1.1-2.6-2.1-3.6-3C1.1,1,0,0,0,0z"/>
                        </g>
                    </svg>


                    <!-- <div><img :src="require('../assets/jocker/majority.svg')" height="35px" style="color: green !important;"></div> -->
                    <!-- <b-dropdown-item v-if="jokerFiftyFiftyAvailable" @click="jokerFiftyFiftyButtonClick">50 / 50 Joker</b-dropdown-item>
                    <b-dropdown-item v-if="jokerMajorityAnswerAvailable" @click="jokerMajorityAnswerButtonClick">Mehrheits-Joker</b-dropdown-item>
                    <b-dropdown-item v-if="jokerCopyAnswerAvailable" @click="jokerCopyAnswerButtonClick">Antwortkopier-Joker</b-dropdown-item> -->

                </b-col>
            </b-row>

            
            <!-- Question Text -->
            <b-row v-if="dispQuestion">
                <b-col class="questionText">{{question.questionText}}</b-col>
            </b-row>
            <!-- Multiple Choice Answers -->
            <div v-if="dispQuestion && questionIsMultipleChoice  && !breakActive">
                <b-row cols="1" cols-md="2">
                    <!-- Answer A -->
                    <b-col class="answerCol">
                        <b-button v-if="answersEnabled && dispFiftyFifty_answerA" disabled class="buttonAnswerDisabled">
                            <div class="answerLetterPlayDisabled">A</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[0]}}</div>
                        </b-button>
                        <b-button v-else-if="answersEnabled && !dispFiftyFifty_answerA" class="buttonAnswer" @click="answerClick(0)">
                            <div class="answerLetterPlay">A</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[0]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispAnswerCorrectA" disabled class="buttonAnswerCorrect">
                            <div class="answerLetterPlayCorrect">A</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[0]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispAnswerIncorrectA" disabled class="buttonAnswerWrong">
                            <div class="answerLetterPlayWrong">A</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[0]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispFiftyFifty_answerA" disabled class="buttonAnswerDisabled">
                            <div class="answerLetterPlayDisabled">A</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[0]}}</div>
                        </b-button>
                        <b-button v-else disabled class="buttonAnswerNoHover">
                            <div class="answerLetterPlay">A</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[0]}}</div>
                        </b-button>
                    </b-col>
                    <!-- Answer B -->
                    <b-col class="answerCol">
                        <b-button v-if="answersEnabled && dispFiftyFifty_answerB" disabled class="buttonAnswerDisabled">
                            <div class="answerLetterPlayDisabled">B</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[1]}}</div>
                        </b-button>
                        <b-button v-else-if="answersEnabled && !dispFiftyFifty_answerB" class="buttonAnswer" @click="answerClick(1)">
                            <div class="answerLetterPlay">B</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[1]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispAnswerCorrectB" disabled class="buttonAnswerCorrect">
                            <div class="answerLetterPlayCorrect">B</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[1]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispAnswerIncorrectB" disabled class="buttonAnswerWrong">
                            <div class="answerLetterPlayWrong">B</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[1]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispFiftyFifty_answerB" disabled class="buttonAnswerDisabled">
                            <div class="answerLetterPlayDisabled">B</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[1]}}</div>
                        </b-button>
                        <b-button v-else disabled class="buttonAnswerNoHover">
                            <div class="answerLetterPlay">B</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[1]}}</div>
                        </b-button>
                    </b-col>
                    <!-- Answer C -->
                    <b-col class="answerCol">
                        <b-button v-if="answersEnabled && dispFiftyFifty_answerC" disabled class="buttonAnswerDisabled">
                            <div class="answerLetterPlayDisabled">C</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[2]}}</div>
                        </b-button>
                        <b-button v-else-if="answersEnabled && !dispFiftyFifty_answerC" class="buttonAnswer" @click="answerClick(2)">
                            <div class="answerLetterPlay">C</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[2]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispAnswerCorrectC" disabled class="buttonAnswerCorrect">
                            <div class="answerLetterPlayCorrect">C</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[2]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispAnswerIncorrectC" disabled class="buttonAnswerWrong">
                            <div class="answerLetterPlayWrong">C</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[2]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispFiftyFifty_answerC" disabled class="buttonAnswerDisabled">
                            <div class="answerLetterPlayDisabled">C</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[2]}}</div>
                        </b-button>
                        <b-button v-else disabled class="buttonAnswerNoHover">
                            <div class="answerLetterPlay">C</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[2]}}</div>
                        </b-button>
                    </b-col>
                    <!-- Answer D -->
                    <b-col class="answerCol">
                        <b-button v-if="answersEnabled && dispFiftyFifty_answerD" disabled class="buttonAnswerDisabled">
                            <div class="answerLetterPlayDisabled">D</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[3]}}</div>
                        </b-button>
                        <b-button v-else-if="answersEnabled && !dispFiftyFifty_answerD" class="buttonAnswer" @click="answerClick(3)">
                            <div class="answerLetterPlay">D</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[3]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispAnswerCorrectD" disabled class="buttonAnswerCorrect">
                            <div class="answerLetterPlayCorrect">D</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[3]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispAnswerIncorrectD" disabled class="buttonAnswerWrong">
                            <div class="answerLetterPlayWrong">D</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[3]}}</div>
                        </b-button>
                        <b-button v-else-if="!answersEnabled && dispFiftyFifty_answerD" disabled class="buttonAnswerDisabled">
                            <div class="answerLetterPlayDisabled">D</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[3]}}</div>
                        </b-button>
                        <b-button v-else disabled class="buttonAnswerNoHover">
                            <div class="answerLetterPlay">D</div>
                            <div class="answerTextPlay">{{multipleChoieAnswerArray[3]}}</div>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <!-- Estimation Input -->
            <div v-if="dispQuestion && !questionIsMultipleChoice && !isMyQuestion">
                <b-row>
                    <b-col>
                        <b-input-group>
                            <b-form-input type="number" :disabled="!estimationInputEnabled" :state="estimationFrozen" v-model="estimationValue" vv-on:keyup.enter="estimationAnswerButtonClick"></b-form-input>
                            <b-input-group-append>
                                <b-button v-if="showEnabledEstimationButton" class="buttonStandardSolid" @click="estimationAnswerButtonClick">OK</b-button>
                                <b-button v-if="showDisabledEstimationButton" variant="secondary" disabled>OK</b-button>
                                <b-button v-if="showEditEstimationButton" class="buttonStandardSolid" @click="recallAnswerButtonClick"><b-icon-pencil/></b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
            </div>
            <div v-if="isMyQuestion && questionIsEstimation">
                <!-- Correct Estimation Question Answers -->
                <b-container>
                    <b-row>
                        <b-col class="answerCol">
                            <b-row class="answerBox" v-bind:class="{ answerBoxCorrect: questionIsEvaluated }">
                                <b-col cols="auto" class="answerLetter" v-bind:class="{ answerLetterCorrect: questionIsEvaluated }"></b-col>
                                <b-col v-if="questionIsEvaluated || showMyQuestionCorrectAnswer" class="answerText" style="text-align: center;">{{question.estimationCorrectAnswer}}</b-col>
                                <b-col v-else class="answerText" style="text-align: center;">???</b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
            <!-- Answer Status -->
            <div v-if="answerPosted">
                <!-- <p>Antwort eingeloggt.</p> -->
                <b-button @click="recallAnswerButtonClick" class="buttonFullWidth">Antwort zurückziehen</b-button>
            </div>
            <div v-if="questionIsEvaluated">
                <p>+{{answer.points}} Punkte</p>
            </div>        
            <div>
                <p></p>
                <b-button v-if="dispShowCorrectAnswerButton" class="buttonFullWidth buttonStandardSolid"  @click="dispShowCorrectAnswerButtonClick">{{this.showMyQuestionCorrectAnswer ? 'Weg damit!' : 'Zeige korrekte Antwort' }}</b-button>
            </div>
            <div>
                <div style="text-align:left" v-if="showInfoText">            
                    <p> </p>
                    <p><b>Hintergrund-Infos:</b><br>{{question.infoText}}</p>                
                </div>
                <p style="text-align:left" v-if="dispWaitForPlayers">Warte auf {{numberMissingAnswers}} Spielende ...</p>
                <p style="text-align:left" v-if="questionIsClosed">Alle Antworten eingegangen ...</p>            
            </div>        
            


            <div v-if="questionIsMultipleChoice">  
            <!-- <div v-if="answersEnabled">                        
                
                <b-button v-if="dispFiftyFifty_answerA" disabled>Antwort A <br> {{multipleChoieAnswerArray[0]}}</b-button>
                <b-button v-else class="buttonStandard" @click="answerClick(0)">Antwort A <br> {{multipleChoieAnswerArray[0]}}</b-button> <br>

                <b-button v-if="dispFiftyFifty_answerB" disabled>Antwort B <br> {{multipleChoieAnswerArray[1]}}</b-button>
                <b-button v-else class="buttonStandard" @click="answerClick(1)">Antwort B <br> {{multipleChoieAnswerArray[1]}}</b-button> <br>

                <b-button v-if="dispFiftyFifty_answerC" disabled>Antwort C <br> {{multipleChoieAnswerArray[2]}}</b-button>
                <b-button v-else class="buttonStandard" @click="answerClick(2)">Antwort C <br> {{multipleChoieAnswerArray[2]}}</b-button> <br>

                <b-button v-if="dispFiftyFifty_answerD" disabled>Antwort D <br> {{multipleChoieAnswerArray[3]}}</b-button>
                <b-button v-else class="buttonStandard" @click="answerClick(3)">Antwort D <br> {{multipleChoieAnswerArray[3]}}</b-button> <br>     
            </div>              
            <div v-else>                        
                <b-button v-if="dispAnswerCorrectA" variant="success" disabled>Antwort A <br> {{multipleChoieAnswerArray[0]}}</b-button>
                <b-button v-else-if="dispAnswerIncorrectA" variant="danger" disabled>Antwort A <br> {{multipleChoieAnswerArray[0]}}</b-button>
                <b-button v-else-if="dispFiftyFifty_answerA" disabled>Antwort A <br> {{multipleChoieAnswerArray[0]}}</b-button>
                <b-button v-else class="buttonStandard" disabled>Antwort A <br> {{multipleChoieAnswerArray[0]}}</b-button> <br>
                
                <b-button v-if="dispAnswerCorrectB" variant="success" disabled>Antwort B <br> {{multipleChoieAnswerArray[1]}}</b-button>
                <b-button v-else-if="dispAnswerIncorrectB" variant="danger" disabled>Antwort B <br> {{multipleChoieAnswerArray[1]}}</b-button>
                <b-button v-else-if="dispFiftyFifty_answerB" disabled>Antwort B <br> {{multipleChoieAnswerArray[1]}}</b-button>
                <b-button v-else class="buttonStandard" disabled>Antwort B <br> {{multipleChoieAnswerArray[1]}}</b-button> <br>

                <b-button v-if="dispAnswerCorrectC" variant="success" disabled>Antwort C <br> {{multipleChoieAnswerArray[2]}}</b-button>
                <b-button v-else-if="dispAnswerIncorrectC" variant="danger" disabled>Antwort C <br> {{multipleChoieAnswerArray[2]}}</b-button>
                <b-button v-else-if="dispFiftyFifty_answerC" disabled>Antwort C <br> {{multipleChoieAnswerArray[2]}}</b-button>
                <b-button v-else class="buttonStandard" disabled>Antwort C <br> {{multipleChoieAnswerArray[2]}}</b-button> <br>

                <b-button v-if="dispAnswerCorrectD" variant="success" disabled>Antwort D <br> {{multipleChoieAnswerArray[3]}}</b-button>
                <b-button v-else-if="dispAnswerIncorrectD" variant="danger" disabled>Antwort D <br> {{multipleChoieAnswerArray[3]}}</b-button>
                <b-button v-else-if="dispFiftyFifty_answerD" disabled>Antwort D <br> {{multipleChoieAnswerArray[3]}}</b-button>
                <b-button v-else class="buttonStandard" disabled>Antwort D <br> {{multipleChoieAnswerArray[3]}}</b-button> <br>            
            </div> -->
            <!-- <div v-if="dispQuestion && dispJokers">
                <div><img :src="require('../assets/jocker/majority.svg')" height="35px"></div> 
                <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        &#127167;<span class="sr-only">Search</span>
                    </template>
                    <b-dropdown-item v-if="jokerFiftyFiftyAvailable" @click="jokerFiftyFiftyButtonClick">50 / 50 Joker</b-dropdown-item>
                    <b-dropdown-item v-if="jokerMajorityAnswerAvailable" @click="jokerMajorityAnswerButtonClick">Mehrheits-Joker</b-dropdown-item>
                    <b-dropdown-item v-if="jokerCopyAnswerAvailable" @click="jokerCopyAnswerButtonClick">Antwortkopier-Joker</b-dropdown-item>
                </b-dropdown>
            </div> -->
        </div>
        

        <div v-if="questionIsEvaluated">
                <b-modal ref="questionEvaluationModal" hide-footer :title="questionEvaluationHeader" :header-bg-variant="questionEvaluationHeaderVariant" >
                    <div v-if="isMyQuestion">
                        <div v-if="questionIsMultipleChoice">
                            <p>{{numberOfCorrectAnswersString}}</p>
                            <p>+{{answer.points}} Punkte</p>
                        </div>
                        <div v-if="questionIsEstimation">                        
                            <p>+{{answer.points}} Punkte für deine Frage!</p>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="multipleChoiceAnswerIsCorrect">
                            <p>Richtige Antwort!</p>
                            <p><b-icon icon="emoji-sunglasses"/></p>
                            <p>{{answer.points}} Punkte für Dich!</p>
                        </div>
                        <div v-if="multipleChoiceAnswerIsFalse">
                            <p> Die Antwort ist leider nicht korrekt.</p>
                            <p><b-icon icon="emoji-dizzy"/></p>
                            <p>{{answer.points}} Punkte ...</p>
                        </div>
                        <div v-if="questionIsEstimation">
                            <p>Korrekt: {{this.question.estimationCorrectAnswer}}</p>
                            <p>Deine Schätzung: {{this.answer.answerValue}} </p>
                            <p>{{estimationDiffText}} daneben / {{this.answer.estimationRank}}. Platz</p>
                            <p>{{answer.points}} Punkte für Dich!</p>
                        </div>
                    </div>
                    <p></p>
                </b-modal>
            </div>
        </div>

        
    </div>




    

    <!-- Quiz Evaluation -->
    <div v-if="dispEvaluationScreen">
        <h2>Augen an die Tafel!</h2>
        <p>Siegerehrung im Gange.</p>         
    </div>


        <!-- Modals -->
        <b-modal ref="postAnswerModal" hide-footer title="Antwort einloggen">
            <div v-if="questionIsMultipleChoice">
                <h4>Antwort {{selectedAnswerLetter}}</h4>
                <p>{{multipleChoieAnswerArray[selectedAnswerDispArray]}}</p>
            </div>
            <b-button class="buttonConfirm" block @click="postAnswerButtonClick">Antwort einloggen</b-button>              
        </b-modal>

        <b-modal ref="postMajorityAnswerJokerModal" hide-footer title="Mehrheitsjoker">
            <h4>Die Weisheit der Vielen ...</h4>
            <p>Die am häufigsten ausgewählte Antwort wird auch die Deine sein.</p>
            <b-button class="buttonConfirm" block @click="postAnswerButtonClick">Mehrheitsjoker einloggen</b-button>              
        </b-modal>

        <b-modal ref="postCopyAnswerJokerModal" hide-footer title="Antwortkopierer">            
            <h4>Wer es wohl wissen könnte ...</h4>
            <p>Wessen Antwort möchtest du kopieren?</p>
            <b-form-select v-model="jokerCopyAnswerFrom" :options="copyAnswerArray"></b-form-select>
            <b-button v-if="jokerCopyAnswerButtonActive" class="buttonConfirm" block @click="postAnswerButtonClick">Antwortkopierer einloggen</b-button>              
            <b-button v-else class="buttonConfirm" block disabled>Antwortkopierer einloggen</b-button>              
        </b-modal>

        <b-modal ref="postFiftyFiftyJokerModal" hide-footer title="50/50 Joker">                        
            <p>Sicher?</p>                        
            <b-button class="buttonConfirm" block @click="confirmFiftyFiftyJokerButtonClick">50/50 Joker nutzen</b-button>              
        </b-modal>
</b-container>
</template>

<script>
import api from '@/services/api'
export default {
    name: 'PlayQuiz',
    // components: { ComponentName },
    // directives: { DirectiveName },
    data() {
        return {
            cfgShowConfirmationPopUp : false,

            dispSpinner : false,

            selectedAnswerLetter : null,
            selectedAnswerText : null,
            selectedAnswerDispArray : null,
            selectedAnswer : "",
            selectedJoker : "",            
            jokerCopyAnswerFrom : null,
            estimationValue : null,
            
            questionStatus : null,
            question: null,
            answer : null,
            result : null,
            ranking : null,
            quiz : null,

            dispQuestion : false,    
            dispWelcomeScreen : false,        
            dispEvaluationScreen : false,

            interval : null,

            showMyQuestionCorrectAnswer : false,

            dispAnswerCorrectA : false,
            dispAnswerCorrectB : false,
            dispAnswerCorrectC : false,
            dispAnswerCorrectD : false,
            dispAnswerIncorrectA : false,
            dispAnswerIncorrectB : false,
            dispAnswerIncorrectC : false,
            dispAnswerIncorrectD : false,            

            copyAnswerArray : null,
        };
    },

    computed :{


        questionIsMultipleChoice : function() {
            if (this.question && this.question.questionType === "multipleChoice") {
                return true;
            } else {
                return false;
            }
        },

        questionIsEstimation : function(){
            return (this.question && this.question.questionType === "estimation") ? true : false;
        },

        answerPosted : function () {
            if (this.questionIsMultipleChoice && this.answer && this.answer._id && !this.answer.isCreator && this.question && this.question.status === "open") {
                return true;
            } else {
                return false;
            }
        },

        estimationAnswerPosted : function () {
            if (this.questionIsEstimation && this.answer && this.answer._id && !this.answer.isCreator && this.question) {
                return true;
            } else {
                return false;
            }
        },

        estimationInputEnabled : function (){
            if (!this.estimationAnswerPosted && this.questionIsOpen){
                return true;
            }
            else {
                return false;
            }
        },
        
        showEditEstimationButton : function (){
            if (this.estimationAnswerPosted && this.questionIsOpen){
                return true;
            }
            else {
                return false;
            }
        },

        estimationFrozen : function(){
            // return (this.estimationAnswerPosted) ? true : null;
            return null
        },  

        answersEnabled : function () {
            if (this.question && this.question.status === 'open'){
                if (this.answer && this.answer.user || this.isMyQuestion){
                    return false;
                } 
                else {
                    return true
                }
            }
            else {
                return false;
            }
           
        },
        
        isMyQuestion : function() {
            if (this.answer && this.answer.isCreator){
                return true;
            } else {
                return false;
            }
        },

        dispJokers : function(){
            if (this.answersEnabled && this.result && this.result.jokersAvailable.length > 0 && !this.dispFiftyFifty){
                return true;
            } else {
                return false;
            }
        },

        jokerMajorityAnswerAllowed : function(){            
            return (this.quiz && this.quiz.jokersAllowed.indexOf('majorityAnswer') > -1) ? true : false;
        },

        jokerCopyAnswerAllowed : function(){            
            return (this.quiz && this.quiz.jokersAllowed.indexOf('copyAnswer') > -1) ? true : false;
        },

        jokerFiftyFiftyAllowed : function(){            
            return (this.quiz && this.quiz.jokersAllowed.indexOf('fiftyFifty') > -1) ? true : false;
        },

        jokerMajorityAnswerAvailable : function(){            
            return (this.result && this.result.jokersAvailable.indexOf('majorityAnswer') > -1) ? true : false;
        },

        jokerCopyAnswerAvailable : function(){            
            return (this.result && this.result.jokersAvailable.indexOf('copyAnswer') > -1) ? true : false;
        },

        jokerCopyAnswerButtonActive : function(){
            return (this.jokerCopyAnswerFrom) ? true : false;
        },

        jokerFiftyFiftyAvailable : function(){            
            return (this.result && this.result.jokersAvailable.indexOf('fiftyFifty') > -1) ? true : false;
        },

        dispWaitForPlayers : function (){
            return this.questionStatus && this.questionStatus.status === "open" && (this.isMyQuestion || this.answerPosted) ? true : false;
        },

        questionIsOpen : function (){
            return (this.questionStatus && this.questionStatus.status === "open") ? true : false;
        },

        questionIsClosed : function (){
            return (this.questionStatus && this.questionStatus.status === "closed") ? true : false;
        },

        questionIsEvaluated : function (){
            return (this.questionStatus && this.questionStatus.status === "evaluated") ? true : false;
        },

        numberMissingAnswers : function (){
            if (this.questionStatus && this.questionStatus.status === "open"){
                return this.questionStatus.missingAnswers.length;
            } else {
                return 0;
            }
        }, 

        dispShowCorrectAnswerButton : function(){
            return (this.isMyQuestion && (this.questionIsOpen || this.questionIsClosed)) ? true : false;
        },

        showEstimationInput : function(){
            return (this.question && this.question.questionType === 'estimation' && this.question.status === 'open' && !this.answerPosted && !this.isMyQuestion) ? true : false;
        },        

        showEnabledEstimationButton : function (){
            return (this.showEstimationInput && this.estimationValue && !this.estimationAnswerPosted)? true : false;
        },

        showDisabledEstimationButton : function (){
            return (this.showEstimationInput && !this.estimationValue && !this.estimationAnswerPosted)? true : false;
        },

        showInfoText : function(){
            return (this.isMyQuestion && !!this.question.infoText);
        },

        multipleChoieAnswerArray : function(){            
            if (this.question && this.question.questionType === "multipleChoice") {                             
                let answerArray = [];
                let displayOrder = this.question.multipleChoiceAnswerDisplayOrder;
                for (let i=0 ; i < displayOrder.length; i++){
                    answerArray[ displayOrder[i] ] = this.question.multipleChoiceAnswers[i];
                }
                return answerArray;
            }
            else {
                return null;
            }
        },

        dispFiftyFifty : function (){
            if (this.result && this.question && this.result.fiftyFiftyJokerUsed === this.question._id && 
                    (this.answersEnabled || (this.question && this.question.status === "evaluated")))
            {
                return true;
            } 
            else 
            {
                return false;
            }
        },

        dispFiftyFifty_answerA : function (){
            let answer = this.question.multipleChoiceAnswerDisplayOrder.indexOf(0)+1;
            return (this.dispFiftyFifty && this.question.jokerFiftyFiftyFalseAnswers.indexOf(answer) > -1) ? true : false;     
        },

        dispFiftyFifty_answerB : function (){
            let answer = this.question.multipleChoiceAnswerDisplayOrder.indexOf(1)+1;
            return (this.dispFiftyFifty && this.question.jokerFiftyFiftyFalseAnswers.indexOf(answer) > -1) ? true : false;
        },

        dispFiftyFifty_answerC : function (){
            let answer = this.question.multipleChoiceAnswerDisplayOrder.indexOf(2)+1;
            return (this.dispFiftyFifty && this.question.jokerFiftyFiftyFalseAnswers.indexOf(answer) > -1) ? true : false;
        },

        dispFiftyFifty_answerD : function (){
            let answer = this.question.multipleChoiceAnswerDisplayOrder.indexOf(3)+1;
            return (this.dispFiftyFifty && this.question.jokerFiftyFiftyFalseAnswers.indexOf(answer) > -1) ? true : false;
        },

        questionEvaluationHeader : function(){            
            if (this.answer && this.questionIsEvaluated){
                if (this.answer.isCreator){
                    return 'Frage ausgewertet';
                }
                else if (this.questionIsMultipleChoice){
                    if (this.answer.multipleChoiceCorrect){
                        return 'Glückwunsch!'
                    } else {
                        return 'Leider nein ...'
                    }
                } else {                    
                    return 'Tada ...'
                }
            }
            else {
                return null
            }
        },

        questionEvaluationHeaderVariant : function(){
            const pointsMax = 10;
            if (this.answer && this.questionIsEvaluated){
                if (this.answer.isCreator){
                    return null;                    
                }
                else if (this.questionIsMultipleChoice){
                    if (this.answer.multipleChoiceCorrect){
                        return 'success'
                    } else {
                        return 'danger'
                    }
                } else {            
                    if (this.answer.points / pointsMax >= 0.7){
                        return 'success'
                    } 
                    else if (this.answer.points / pointsMax <= 0.3) {
                        return 'danger'
                    } 
                    else {
                        return 'warning'
                    }
                    
                }
            }
            else {
                return null
            }
        },

        multipleChoiceAnswerIsCorrect : function (){
            if (this.answer && this.questionIsEvaluated && this.questionIsMultipleChoice && this.answer.multipleChoiceCorrect){ 
                return true;
            }
            else {
                return false;
            }
        },

        multipleChoiceAnswerIsFalse : function (){
            if (this.answer && this.questionIsEvaluated && this.questionIsMultipleChoice && !this.answer.multipleChoiceCorrect){ 
                return true;
            }
            else {
                return false;
            }
        },

        numberOfCorrectAnswersString : function(){
            if (this.answer && this.questionIsEvaluated && this.questionIsMultipleChoice){
                if (this.question.questionResult.correctAnswers > 1){
                    return this.question.questionResult.correctAnswers + ' richtige Antworten (' + Math.round(this.question.questionResult.correctAnswersPcnt) + '%)';
                }
                else if (this.question.questionResult.correctAnswers === 1) {
                    return 'Eine richtige Antwort (' + this.question.questionResult.correctAnswersPcnt + '%)';
                } 
                else {
                    return 'Keine richtige Antwort.';
                }
            }
        },

        estimationDiffText : function (){
            if (this.answer && this.questionIsEstimation && this.questionIsEvaluated){                
                let diff = this.answer.answerValue - this.question.estimationCorrectAnswer;
                if (diff === 0){
                    return '+/- 0'
                }
                else if (diff > 0) {
                    return '+ ' + diff.toString();
                } 
                else {
                    return '- ' + Math.abs(diff).toString();
                }
            } else {
                return null
            }
        },

        breakActive : function(){
            return ((this.questionStatus && this.questionStatus.breakActive) || (this.quiz && this.quiz.breakActive)) ? true : false;
        }

    },
        

 
    methods: {
        answerClick(answerIn) {
            this.selectedJoker = '';
            this.selectedAnswerDispArray = answerIn;
            if (this.questionIsMultipleChoice){                
                this.selectedAnswerLetter = this.answerLetter(answerIn+1);
                this.selectedAnswer = this.question.multipleChoiceAnswerDisplayOrder.indexOf(this.selectedAnswerDispArray)+1;
            }
            if (this.cfgShowConfirmationPopUp){
                this.$refs['postAnswerModal'].show();
            }
            else {
                this.postAnswerButtonClick();
            }

            
        },

        async estimationAnswerButtonClick(){
            let answer = {
                answerValue: this.estimationValue,
            };

            await api.post('quizNight/currentAnswer', answer).then(async (res) => {
                this.answer = res.data;        
                this.estimationValue = null;    
            })
        },
        
        jokerMajorityAnswerButtonClick(){
            if (this.jokerMajorityAnswerAvailable && this.answersEnabled && !this.dispFiftyFifty){
                this.selectedAnswer = ""; this.selectedAnswerLetter = null; this.selectedAnswerText = null; this.selectedAnswerDispArray = null;
                this.selectedJoker = 'majorityAnswer';
                this.$refs['postMajorityAnswerJokerModal'].show();
            }
        },

        jokerCopyAnswerButtonClick(){
            if (this.jokerCopyAnswerAvailable && this.answersEnabled && !this.dispFiftyFifty){
                this.selectedAnswer = ""; this.selectedAnswerLetter = null; this.selectedAnswerText = null; this.selectedAnswerDispArray = null;
                this.selectedJoker = 'copyAnswer';
                this.$refs['postCopyAnswerJokerModal'].show();
            }
        },

        jokerFiftyFiftyButtonClick(){
            if (this.jokerFiftyFiftyAvailable && this.answersEnabled && !this.dispFiftyFifty) {
                this.selectedAnswer = ""; this.selectedAnswerLetter = null; this.selectedAnswerText = null; this.selectedAnswerDispArray = null; 
                this.$refs['postFiftyFiftyJokerModal'].show();
            }
        },

        async confirmFiftyFiftyJokerButtonClick(){
            await api.get('quizNight/useFiftyFiftyJoker/' + this.quiz._id).then(async (res) => {
                this.result = res.data; 
                this.$refs['postFiftyFiftyJokerModal'].hide();               
            })
            .catch(error => console.log(error.response));                                 
        },

        dispShowCorrectAnswerButtonClick(){            
            this.showMyQuestionCorrectAnswer = !this.showMyQuestionCorrectAnswer;
            this.updateDispCorrectAnswer();
        },

        answerLetter(answerIn) {
            let a = ["", "A", "B", "C", "D"];
            return a[answerIn];
        },

        closePostAnswerModal(){
            this.$refs['postAnswerModal'].hide();
        },

        async postAnswerButtonClick(){            
            let answer = {
                answerValue: this.selectedAnswer,
                jokerUsed : this.selectedJoker,
            };

            if (this.question._id === this.result.fiftyFiftyJokerUsed){
                answer.jokerUsed = 'fiftyFifty';
            }

            if (answer.jokerUsed === "copyAnswer"){
                answer.jokerCopyAnswerFrom = this.jokerCopyAnswerFrom;
            }

            await api.post('quizNight/currentAnswer', answer).then(async (res) => {
                this.answer = res.data;            
            })
            .catch(error => console.log(error.response));    

            // this.updateDispCorrectAnswer();
            this.$refs['postAnswerModal'].hide();
            this.$refs['postMajorityAnswerJokerModal'].hide();
            this.$refs['postCopyAnswerJokerModal'].hide();
            this.waitingForOtherPlayersInterval = setInterval(this.checkQuestionStatus,1000);            
        },

        async recallAnswerButtonClick(){                        
            await api.get('quizNight/recallCurrentAnswer/' + this.answer._id)            
            .then(async (res) => {
                if (this.questionIsEstimation){
                    this.estimationValue = this.answer.answerValue;
                }
                this.answer = null;                
            })
            .catch(error => console.log(error.response));
        },


        updateDispCorrectAnswer() {           
            if (!this.questionStatus || (this.questionStatus.status !== 'evaluated' && !this.showMyQuestionCorrectAnswer) || !this.questionIsMultipleChoice){
                this.dispAnswerCorrectA = false;
                this.dispAnswerCorrectB = false;
                this.dispAnswerCorrectC = false;
                this.dispAnswerCorrectD = false;  
                this.dispAnswerIncorrectA = false;
                this.dispAnswerIncorrectB = false;
                this.dispAnswerIncorrectC = false;
                this.dispAnswerIncorrectD = false; 
            }
            else {                
                let answerA = this.question.multipleChoiceAnswerDisplayOrder.indexOf(0)+1;
                let answerB = this.question.multipleChoiceAnswerDisplayOrder.indexOf(1)+1;
                let answerC = this.question.multipleChoiceAnswerDisplayOrder.indexOf(2)+1;
                let answerD = this.question.multipleChoiceAnswerDisplayOrder.indexOf(3)+1;
                this.dispAnswerCorrectA = answerA === this.question.multipleChoiceCorrectAnswer ? true : false;
                this.dispAnswerCorrectB = answerB === this.question.multipleChoiceCorrectAnswer ? true : false;
                this.dispAnswerCorrectC = answerC === this.question.multipleChoiceCorrectAnswer ? true : false;
                this.dispAnswerCorrectD = answerD === this.question.multipleChoiceCorrectAnswer ? true : false;

                if (this.answer && !this.answer.isCreator) {
                    let postedAnswerValueDisp = this.question.multipleChoiceAnswerDisplayOrder[this.answer.answerValue-1];
                    this.dispAnswerIncorrectA = postedAnswerValueDisp === 0 ? true : false;
                    this.dispAnswerIncorrectB = postedAnswerValueDisp === 1 ? true : false;
                    this.dispAnswerIncorrectC = postedAnswerValueDisp === 2 ? true : false;
                    this.dispAnswerIncorrectD = postedAnswerValueDisp === 3 ? true : false;
                }
                else {
                    this.dispAnswerIncorrectA = false;
                    this.dispAnswerIncorrectB = false;
                    this.dispAnswerIncorrectC = false;
                    this.dispAnswerIncorrectD = false;
                }
            }

            
        },

        async updateResult(){
            await api.get('result').then(async (res) => {
                this.result = res.data;
            })
            .catch(error => console.log(error.response));
        },

        async updateRanking(){
            await api.get('quizRanking/' + this.quiz._id).then(async (res) => {
                this.ranking = res.data;
            })
            .catch(error => console.log(error.response));
        },
        
        async updateQuestion(){
            await api.get('quizNight/currentQuestion/' + this.quiz._id).then(async (res) => {
                this.question = res.data;
            })
            .catch(error => console.log(error.response));
        },

        async updateAnswer(){
            await api.get('quizNight/currentAnswer/' + this.question._id).then(async (res) => {
                this.answer = res.data;                
            })
            .catch(error => console.log(error.response));
        },

        async updateQuiz(){
            await api.get('/currentQuiz').then(async (res) => {
                this.quiz = res.data;            
            })
            .catch(error => console.log(error.response));
        },

        async updateCurrentQuestionStatus(){
            await api.get('quizNight/currentQuestionStatus/' + this.quiz._id).then(async (res) => {         
                let _questionStatus = {
                    questionId : undefined,
                    status : undefined,
                };
                if (this.questionStatus){
                    _questionStatus = this.questionStatus;
                }
                this.questionStatus = res.data;                                
                if (!_questionStatus || _questionStatus.status !== this.questionStatus.status || String(_questionStatus.questionId) !== String(this.questionStatus.questionId)){ 
                    if (String(_questionStatus.questionId) !== String(this.questionStatus.questionId)) {
                        this.dispQuestion = false;                    
                        this.dispSpinner = true;
                    }
                    
                    await this.updateQuestion();
                    await this.updateAnswer();  
                    this.showMyQuestionCorrectAnswer = false;                  
                    this.updateDispCorrectAnswer();                    
                    this.dispSpinner = false;
                    this.dispQuestion = true;
                    
                    await this.updateQuiz();
                    await this.updateResult();
                    await this.updateRanking();
                    this.updateCopyAnswerArray();
                    if (this.questionStatus.status === 'evaluated' || this.questionStatus.status === 'open'){
                        this.estimationValue = null;                        
                    }
                    if (this.questionStatus.status === 'evaluated' && _questionStatus.status !== 'evaluated' && this.questionStatus.questionId === _questionStatus.questionId){
                        this.$refs['questionEvaluationModal'].show(); 
                    }
                }
                if (_questionStatus.quizStatus !== this.questionStatus.quizStatus && this.questionStatus.quizStatus === 'evaluation') {
                    this.dispQuestion = false;
                    this.dispEvaluationScreen = true;                        
                }           
            })
            .catch(error => {                
                console.log(error.response);
                // this.$router.push('/quiz/' + this.quiz._id);
                // clearInterval(this.interval);
            }
            )

        },

        async checkIfQuizIsStarted(){
            await this.updateQuiz();
            if (this.quiz && this.quiz.status === "active"){
                clearInterval(this.interval2);
                // Get question status            
                await this.updateQuestion();
                await this.updateAnswer();   
                await this.updateCurrentQuestionStatus();                     
                await this.updateResult();
                await this.updateRanking();

                this.updateDispCorrectAnswer();
                this.dispWelcomeScreen = false;
                this.dispQuestion = true;
                this.updateCopyAnswerArray();
                this.interval = setInterval(this.updateCurrentQuestionStatus,1000);
            }
        },

        updateCopyAnswerArray (){            
            let otherPlayers = [];
            otherPlayers[0] = {
                value : null,
                text : ''
            };
            let j = 1;            
            for (let i = 0 ; i < this.ranking.length ; i++)
            {
                if (this.ranking[i].registered && this.ranking[i].user._id !== this.question.user._id && this.ranking[i].user._id !== this.result.user){
                    otherPlayers[j] = {
                        value : this.ranking[i].user._id, 
                        text : this.ranking[i].user.prename + ' ' + this.ranking[i].user.surname
                    }
                    j++;
                }                
            }
            this.copyAnswerArray = otherPlayers;
        },

    },

       async mounted() {
        await this.updateQuiz();
        if (this.quiz && this.quiz.status === "active"){
            // Get question status            
            await this.updateQuestion();
            await this.updateAnswer();   
            await this.updateCurrentQuestionStatus();                     
            await this.updateResult();
            await this.updateRanking();

            if (!this.result.registered){
                this.$router.push('/')
            }

            this.updateDispCorrectAnswer();
            this.dispQuestion = true;
            this.updateCopyAnswerArray();
            this.interval = setInterval(this.updateCurrentQuestionStatus,1000);

            if (this.questionIsEstimation && this.estimationAnswerPosted && !this.questionIsOpen){
                this.estimationValue = this.answer.answerValue;
            }
    
        } 
        else if (this.quiz && this.quiz.status === 'over'){
            this.$router.push('/quizzes/' + this.quiz._id)
        }        
        else if (this.quiz && this.quiz.status === "notStarted"){
            await this.updateResult();
            if (!this.result.registered){
                this.$router.push('/')
            }
            let now = new Date();
            let quizDate = new Date(this.quiz.quizDate);         
            let minLeft = (quizDate - now)/60/1000;
            console.log(minLeft)                
            if (minLeft < 120){
                this.dispWelcomeScreen = true;
                this.interval2 = setInterval(this.checkIfQuizIsStarted,1000);

                await api.get('quizNight/playerEntersLobby/').then(async (res) => {
                    console.log('player entered Lobby')
                    this.quiz = res.data;            
                })
                .catch(error => console.log(error.response));
            }  
        }
        else if (this.quiz && this.quiz.status === 'evaluation' ){
            this.dispEvaluationScreen = true;
        }
        else {
            this.$router.push('/')
        }
    },

    beforeDestroy(){        
        clearInterval(this.interval);
        clearInterval(this.interval2);
    }
};
</script>

<style lang="scss" scoped>

</style>