<template>
    <div>
        
    </div>
</template>

<script>
export default {
    name: 'UpdateProfile',
    // components: { ComponentName },
    // directives: { DirectiveName },
    data() {
        return {
            
        };
    },
    mounted() {        
        
    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>