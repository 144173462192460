<template>
    <div>
        <b-row class="mb-3">
            <b-col>
                <b>{{text}}</b>
            </b-col>
        </b-row>

        <b-row class="mb-1" v-for="stat in displayData" :key="stat._id" @click="showAll=!showAll">
            <b-col align-self="center"  cols="9">
                <b-avatar size="32px" class="avatarAdminSmall mr-1">
                    {{stat.initials}}
                </b-avatar>
                {{stat.name}}
            </b-col>                        
            <b-col align-self="center" class="text-right">
                <i>{{stat.value.toFixed(toFixed)}} {{unit}} </i>
            </b-col>
        </b-row>    
    
        <b-row v-if="textSub">
            <b-col class="text-right">
                <i>{{textSub}}</i>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'StatsUnit',

    props:['text', 'stats', 'unit', 'toFixed', 'textSub', 'hideZeroValues'],

    computed : {
        displayData : function (){
            let stats = this.stats;
            if (!!this.hideZeroValues){
                stats = stats.filter(stat => stat.value !== 0);
            }

            if (this.showAll){                
                return stats;
            }
            else {
                let data = [];                
                data[0] = stats[0];
                let i = 1;
                while (i < stats.length && stats[i].value === stats[i-1].value){
                    data[i] = stats[i];
                    i++;
                }
                return data;
            }
        }
    },

    data() {
        return {            
            showAll : false,
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>