<template>
    <div>
        <b-card class="cardHome" no-body>
            <template #header>
                <span class="cardHeader">Und sonst so?</span>
            </template>

            
            <b-list-group flush>
                <b-list-group-item >
                    <StatsUnit
                        :stats="dataTable('multipleChoiceCorrectRate','des')"
                        :text="'Multiple-Choice Star'"                        
                        :textSub = "'richtige Multiple Choice Fragen'"
                        :unit="'%'"
                        :toFixed="0"                
                    />
                </b-list-group-item>

                <b-list-group-item >
                    <StatsUnit
                            :stats="dataTable('estimationPointsMean','des')"
                            :text="'Schätz Champion'"
                            :textSub = "'Punkte pro Schätzfrage'"
                            :unit="''"
                            :toFixed="1"                
                        />
                </b-list-group-item>

                <b-list-group-item >
                    <StatsUnit
                            :stats="dataTable('numQuestionsCreatedPerQuiz','des')"
                            :text="'Kreativer Kopf'"
                            :textSub = "'eingereichte Fragen pro Quiz'"
                            :unit="''"
                            :toFixed="1"                
                        />
                </b-list-group-item>

                <b-list-group-item >
                    <StatsUnit
                            :stats="dataTable('answersWithJokerCorrectRate','asc')"
                            :text="'Jokerflöte'"
                            :textSub = "'korrekte Antwort mit Joker'"
                            :unit="'%'"
                            :toFixed="0"                
                        />
                </b-list-group-item>

                <b-list-group-item >
                    <StatsUnit
                            :stats="dataTable('answersCopiedSumPerQuiz','des')"
                            :text="'Helfer:in in der Not'"
                            :textSub = "'kopierte Antworten pro Quiz'"
                            :unit="''"
                            :toFixed="1"  
                            :hideZeroValues="true"              
                        />
                </b-list-group-item>
                
                <b-list-group-item >
                    <StatsUnit
                            :stats="dataTable('correctAnswersToMyQuestionsMean','asc')"
                            :text="'Harter Brocken'"
                            :textSub = "'korrekte Antworten auf gestellte Fragen'"
                            :unit="'%'"
                            :toFixed="0"                
                        />
                </b-list-group-item>

                <b-list-group-item >
                    <StatsUnit
                            :stats="dataTable('meanAnswerTime','asc')"
                            :text="'Speedy Gonzales'"
                            :textSub = "'durchschnittliche Antwortzeit'"
                            :unit="'s'"
                            :toFixed="0"                
                        />
                </b-list-group-item>

                <b-list-group-item >
                    <StatsUnit
                            :stats="dataTable('fastestCorrectAnswerTime','asc')"
                            :text="'Lucky Luke'"
                            :textSub = "'schnellste korrekte Antwort'"
                            :unit="'s'"
                            :toFixed="1"                
                        />
                </b-list-group-item>

            </b-list-group>

            <b-card-body style="padding: 0;"></b-card-body>
            <b-card-body></b-card-body>
            

            
         
            
        </b-card>
    </div>
</template>

<script>
import StatsUnit from '@/components/home/stats/StatsUnit.vue'

export default {
    name: 'MiscStats',
    props : ['stats'],
    components : {StatsUnit},

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        dataTable (fieldname, order) {
            let data = [];
            let i=0; 
            let j=0;
            while (i < this.stats.length){
                // if (!!this.stats[i][fieldname])
                if (this.stats[i].hasOwnProperty(fieldname) && this.stats[i][fieldname] !== null)
                {
                    data[j] = {
                        name : this.stats[i].name,
                        initials : this.stats[i].user.prename.charAt(0) + this.stats[i].user.surname.charAt(0),
                        numQuizzesPlayed : this.stats[i].numQuizzesPlayed,
                        value : this.stats[i][fieldname]
                    }                    
                    j++;
                }
                i++;
            }
            if (order === 'asc'){
                data.sort((a,b) => {return (a.value-b.value)});
            } else {
                data.sort((a,b) => {return (b.value-a.value)});
            }        
            return data;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>