<template>
  	<div class="container h-100" style="margin-top: 40px">
		<div v-if="showLoginScreen" class="d-flex justify-content-center h-100">
			<div class="user_card">
				<div class="d-flex justify-content-center">
					<div class="brand_logo_container">
						<img src="../assets/QLogo.png" class="brand_logo" alt="Logo">
					</div>
				</div>
				<div class="d-flex justify-content-center form_container">
					<form>
						<div class="input-group mb-3">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							
							<b-form-input type="email" name="email" class="form-control input_user" v-model="email" placeholder="E-Mail" v-on:keyup.enter="loginButtonClick" :state="validationState.email"></b-form-input>
						</div>
						<div class="input-group mb-2">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-key"></i></span>
							</div>
							<b-form-input type="password" name="password" class="form-control input_pass" v-model="password" placeholder="Passwort" v-on:keyup.enter="loginButtonClick" :state="validationState.password"></b-form-input>
						</div>
						<div class="form-group">
							<div class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" id="customControlInline" v-model="stayLoggedIn">
								<label class="custom-control-label" for="customControlInline">Eingeloggt bleiben</label>
							</div>
						</div>
						<div class="d-flex justify-content-center mt-3 login_container">
				 			<button type="button" name="button" class="btn login_btn" @click="loginButtonClick">Login</button>
						</div>
					</form>
				</div>
		
				<div class="mt-4">
					<div class="d-flex justify-content-center links">
						Noch nicht registriert? <router-link to="/signup" class="ml-2 login_link">Registrieren</router-link>
					</div>
					<div class="d-flex justify-content-center links">
						<router-link to="/forgotpw" class="login_link">Passwort vergessen?</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import openapi from '@/services/openapi'

export default {
  data () {
	return {
		showLoginScreen : false,

		email: '',
		password: '',
		stayLoggedIn: true,
		user:{
			email : '',
			password: '',        
		},


	  validationState : {
		  email : null,
		  password : null,
	  }
	  
	}
  },

  computed: {
	loginError: function() {
	  return this.$store.getters.loginError;
	}
  },



  methods: {

	resetValidation(){
		
	},

	async loginButtonClick () {	  
		let credentials = {
			email: this.email,
			password: this.password,
			stayLoggedIn: this.stayLoggedIn,
		}

		await this.$store.dispatch("loginAction", credentials);
		if (this.$store.getters.isAuthenticated){
			this.$router.push("/");
		} else {    
			console.log(this.loginError)
			this.validationState.password = this.loginError.password ? false : null;				
			this.validationState.email = this.loginError.email ? false : null;		
		}
	}
},

async mounted() {
	// check if user is already logged in	

	// check in store
	if 	(this.$store.getters.isAuthenticated){
		this.$router.push('/')
	}

	// check for java web token in browser	
	await openapi.get("login")
	.then(async (res) => {  
		console.log('res.data')                  
		if (res.data) {   		
			let user = {
				_id : res.data._id,
				email: res.data.email,
				prename: res.data.prename,
				surname: res.data.surname,
			};
			await this.$store.dispatch("saveLoginInfo", user);     
			this.$router.push('/');
		}
	})
	.catch(error => console.log('No user logged in.'));   

	this.showLoginScreen = true;

},


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
