<template>
    <div>
        <div>
        <b-container fluid class="containerQuiz">
            <b-row>
                <b-col>
                    <div class="mt-5 mb-5 ml-2 mr-2 cardHeader" style="font-size:1.8rem; text-align: left;">
                        <b>Quizabend</b>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="dispQuizNotFound" style="text-align: left">
                <p>Das Quiz konnte nicht gefunden werden :(<br>
                <router-link to="/quizzes" class="ml-2 login_link">Zu meinen Quizabenden</router-link></p>                
            </b-row>
            <div v-if="dispQuiz">
                <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="4" style="text-align: left" class="mb-3">  
                    <b-col>                            
                        <b-icon-calendar-date></b-icon-calendar-date> {{dateString}} 
                    </b-col>                                            
                    <b-col>
                        <b-icon-geo-alt></b-icon-geo-alt>{{locationString}}
                    </b-col>                                         
                </b-row>                
            </div>       

            <b-row v-if="dispQuiz">
                <b-col>
                    <b-card no-body>
                        <b-tabs fill card content-class="mt-10">
                            <b-tab title="Endstand" >
                                <Ranking 
                                    :quiz="quiz"    
                                    :columns="columns"
                                    :highlightUserId="this.$store.getters.user._id"
                                /> 
                            </b-tab>
                            <b-tab title="Fragen">
                                <QuizQuestions 
                                    :quiz="quiz"    
                                    :filterActv="true"
                                    :myQuestionsOnly="false"
                                    /> 
                                
                            </b-tab>
                            <b-tab title="Stats">
                                <QuizStats
                                    :quiz="quiz"                                     
                                    />
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
    <!-- <div>
        <b-container fluid class="containerQuiz">
            <div v-if="dispQuiz">
                <h2>
                    Endstand
                </h2>
                <p></p>
                <p><Ranking 
                    :quiz="quiz"    
                    :columns="columns"
                    :highlightUserId="this.$store.getters.user._id"
                /> </p>
            </div>
            <div v-if="dispQuiz">
                <h2>
                    Fragen
                </h2>
                <p>
                    <QuizQuestions 
                    :quiz="quiz"    
                    :highlightUserId="this.$store.getters.user._id"
                    /> 
                </p>
            </div>
        </b-container>
    </div> -->
</div>

    
</template>

<script>
import api from '@/services/api';
import Ranking from '@/components/Ranking.vue';
import QuizQuestions from '@/components/quizzes/QuizQuestions.vue';
import QuizStats from '@/components/quizzes/QuizStats.vue';



export default {
    name: 'Quiz',
    components: { Ranking, QuizQuestions, QuizStats },
    // directives: { DirectiveName },
    data() {
        return {
            dispQuiz : false,
            dispQuizNotFound : false,
            quiz : null,
            hostUser : null,

            ranking : null,                        
            columns : ['numberJokersAvailable' ,  'numberPostedQuestionsPlayed','pointsPostedQuestions',   'pointsCorrectMultipleChoiceAnswers', 'pointsGoodEstimations' , 'pointsTotal'],
            
            
        };
    },

    computed : {
          dateString: function() {            
            let quizDate = new Date(this.quiz.quizDate);                               
            const dateOptions = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'};            
            let quizDateString = quizDate.toLocaleDateString('de-DE', dateOptions); 
            return quizDateString;
        },

        timeString: function() {
            let quizDate = new Date(this.quiz.quizDate);    
            return (String(quizDate.getHours()) + ':' + this.getMyMinutes + ' Uhr')
        },

        locationString: function(){
            if (this.quiz && this.quiz.location){
                return this.quiz.location + ', bei ' + this.hostUser.prename + ' ' + this.hostUser.surname
            }
            else {
                return this.hostUser.prename + ' ' + this.hostUser.surname;
            }
        },

        getMyMinutes : function (){
            let quizDate = new Date(this.quiz.quizDate);      
            let min = quizDate.getMinutes();
            return min < 10 ? ('0' + String(min)) : String(min);
        },
    },

    async mounted() {
        // If called with quiz ID, get quiz info from server        
        if (this.$route.params.id){
            await api.get('quizWithQuestions/' + this.$route.params.id)
            .then(res => this.quiz = res.data)
            .catch(error => console.log(error.response));      
            
            if (this.quiz && this.quiz._id){
                await api.get('quizRanking/' + this.quiz._id)
                .then(res => this.ranking = res.data)
                .catch(error => console.log(error.response));   

                await api.get('user/' + this.quiz.hostUser)
                .then(res => this.hostUser = res.data)
                .catch(error => console.log(error.response));   
                

                this.dispQuiz = true;
            }
            else {
                this.dispQuizNotFound = true;
            }
        }
    },



    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>