<template>
    <div>
        <b-card class="cardHome">
            <template #header>
                <span class="cardHeader">Je mehr, desto besser!</span>
            </template>
            
            <b-card-text>
                Versende diesen Link, um weitere Quizer:innen zur Gruppe <b>{{this.$store.getters.groupName}}</b> einzuladen.       
            </b-card-text>
            <b-input-group>
                <b-form-input type="url" readonly :value="inviteLink"></b-form-input>
                <b-input-group-append>
                    <b-button v-if="!linkCopied" class="buttonStandard" v-clipboard:copy="inviteLink" @click="linkCopied=true"><b-icon-clipboard/></b-button>
                    <b-button v-else class="buttonStandard" v-clipboard:copy="inviteLink"><b-icon-check/></b-button>

                </b-input-group-append>
            </b-input-group>
        </b-card>
    </div>
</template>

<script>
export default {
    name: 'InvitePeople',

    data() {
        let link = 'https://www.der-supertolle-quizabend.de/invitation/' + String(this.$store.getters.currentGroupId);  // to be updated for production

        return {
            inviteLink : link,
            linkCopied : false,
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>