<template>
<div>  
    <div v-show="dispSpinner">
        <br><br>
        <b-spinner style="width: 4rem; height: 4rem;" label="Large Spinner"></b-spinner>
    </div>
    <div v-show="!dispSpinner">
      <b-container v-if="user" fluid class="containerHome">
      <b-row>        
        <b-col>
          <div class="mt-5 mb-0 ml-2 mr-2 cardHeader" style="font-size:1.8rem; text-align: left;">
            <b>Hallo {{user.prename}}!</b>
          </div>
          <div class="mt-0 mb-5 ml-2 mr-2 cardHeader" style="font-size:1.2rem; text-align: left;">
            Schön, dass du hier bist <b-icon icon="emoji-smile-upside-down"></b-icon>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="showSetPassword">
        <b-col>
          <SetPassword :user="user" />
        </b-col>
      </b-row>

      <b-row v-if="showGroupsCmp">
        <b-col>   
          <Groups />
        </b-col>
      </b-row>

      <b-row v-if="showCurrentQuizCmp">
        <b-col>     
          <CurrentQuiz v-bind:quiz="quiz" v-on:ready="currentQuizCmpLoading=false"/>
        </b-col>
      </b-row>

      <b-row v-if="showPreviousQuizCmp">
        <b-col>     
          <QuizCard v-bind:quiz="previousQuiz" v-bind:previousQuiz="true" v-on:ready="previousQuizCmpLoading=false"/>
        </b-col>
      </b-row>    

      <b-row v-if="showStats">
        <b-col>     
          <GroupStats 
            v-on:ready="statsCmpLoading=false"/>
        </b-col>
      </b-row> 

      <b-row v-if="showInvitePeople">
        <b-col>     
          <InvitePeople />
        </b-col>
      </b-row> 

      <b-row v-show="showOtherGroups">
        <b-col>     
          <OtherGroups />
        </b-col>
      </b-row>  
      
      <b-row v-show="showCreateGroup">
        <b-col>     
          <CreateGroup v-bind:firstGroup="firstGroup" />
        </b-col>
      </b-row>
      
      <b-row v-show="showResetPassword">
        <b-col>     
          <ResetPassword />
        </b-col>
      </b-row>

    </b-container>
  </div>
  </div>
</template>

<script>
import api from '@/services/api'
import openapi from '@/services/openapi'
import Groups from "@/components/home/Groups.vue";
import CurrentQuiz from "@/components/home/CurrentQuiz.vue";
import QuizCard from "@/components/QuizCard.vue";
import SetPassword from "@/components/home/SetPassword.vue";
import InvitePeople from "@/components/home/InvitePeople.vue";
import CreateGroup from '@/components/CreateGroup.vue';
import OtherGroups from '@/components/OtherGroups.vue';
import ResetPassword from '@/components/home/ResetPassword.vue';
import GroupStats from "@/components/home/GroupStats.vue";  


export default {
  name: 'Home',
  components: {
    Groups,
    CurrentQuiz,
    QuizCard,
    SetPassword,
    CreateGroup,
    OtherGroups,
    ResetPassword,
    GroupStats,
    CreateGroup,
    InvitePeople,
  },

  // Data
  data() {
    return {      
      user : null,  
      quiz : null,    
      previousQuiz : null,      
      showStats : false, 
      showGroupsCmp : false,      
      showCurrentQuizCmp : false, 
      showPreviousQuizCmp : false, 
      showSetPassword: false,  
      showInvitePeople : false,   

      myGroups : null,      
      showCreateGroup : false,
      firstGroup : false,
      
      showOtherGroups : false,

      showSetPasswordWithOtherComponents : true,    
      
      
      currentQuizCmpLoading : true,
      previousQuizCmpLoading : true,
      statsCmpLoading : true,
    };
  },

  computed : {
    
    dispSpinner () {
      // if (this.groupsCmpLoading || this.currentQuizCmpLoading || this.previousQuizCmpLoading || this.statCmpLoading) {
      if (this.$store.getters.currentGroupId && !this.isInvited && (this.currentQuizCmpLoading || this.previousQuizCmpLoading || this.statsCmpLoading)) {
        return true;
      }
      else  {
        return false;
      }
    },

    showResetPassword () {
      return this.user && this.user._id === '5fda0f41afc0074a64159c5a' ? true : false;
    },

    isInvited () {
      if (!this.$store.getters.isInvited){
        return false;
      }
      if (!this.myGroups){
        return false;
      }
      
      // Check if user is already in this group        
      if (!!this.myGroups && this.myGroups.length === 0){
        return true;
      }
      else {
        let groupIds = this.myGroups.map(group => group._id);
        if (groupIds.length === 0 || groupIds.indexOf(this.$store.getters.inviteGroupId) === -1) {
            return true;
        }
      }     
      
    },


  },

  // Life Cycle Hooks
  async mounted() {
    // Get user info and check is password is random and needs to be set
    await openapi.get("login").then(res => this.user = res.data);
    
    if (this.user.isRandomPassword === true){
      this.showSetPassword = true;      
    }

    await api.get("myGroups").then(res => this.myGroups = res.data);  

    if (this.showSetPasswordWithOtherComponents || !this.showSetPassword)
    {
      if (this.isInvited || !this.$store.getters.groupName){      
        this.showGroupsCmp = true;
      }
      
      else {
        api.get('currentQuiz').then(res => {
          this.quiz = res.data;
          this.showCurrentQuizCmp = true;          
        })  

        api.get('previousQuiz').then(res => {
          // console.log('prev quiz', res.data)
          this.previousQuiz = res.data;
          if (!!this.previousQuiz){
            this.showPreviousQuizCmp = true;
            this.showStats = true;
          }
          else {
            this.showPreviousQuizCmp = false;
            this.previousQuizCmpLoading = false;
            this.statsCmpLoading = false;            
          }
        })
        .catch(error => {
            console.log(error.response);
            this.showPreviousQuizCmp = false;
            this.previousQuizCmpLoading = false;
          });;
        
              
        await this.$store.dispatch("updateCurrentQuiz");
        
      }
      

      if (!this.$store.getters.groupName && this.$store.getters.myGroups && this.$store.getters.myGroups.length === 0){
        this.firstGroup = true;        
        // this.showCreateGroup = true;
      }
      this.showCreateGroup = true;

      if (this.$store.getters.isGroupAdmin){
        this.showInvitePeople = true;
      }
      
      if (this.$store.getters.groupName && this.$store.getters.myGroups.length > 1){
        this.showOtherGroups = true;
      }

      
    }
    

    
    
    
    }
}
</script>
