<template>

     <div v-if="this.displayStats">         
     <!-- <div>          -->
               
            <MedalTable
                :stats="stats"
            />

            <EternalTable
                :stats="stats"
            />  

            <MiscStats
                :stats="stats"
            />
                  
    </div>
                
</template>

<script>
import api from '@/services/api'
import MedalTable from '@/components/charts/MedalTable.vue'
import EternalTable from '@/components/charts/EternalTable.vue'
import MiscStats from '@/components/home/stats/MiscStats.vue'


export default {
    name: 'GroupStats',
    components : {MedalTable, EternalTable, MiscStats},
    
    props:[],


    data() {
        return {
            stats : null,
            displayStats : false,

        };
    },

    methods: {

        
    },

    computed : {
    
    },

    async mounted() {      
        
        await api.get('groupStats/')
        .then(res => {
            this.stats = res.data;               
        })
        .catch(error => console.log(error.response));
        this.stats.forEach(stat => stat.name = stat.user.prename + ' ' + stat.user.surname)        

        
        this.displayStats = true;
        await this.$emit("ready");


    },
}
</script>

<style>

</style>