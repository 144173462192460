import api from '@/services/api'

// STATES
const state = {
    groups: null ,    
};
  
// GETTERS
const getters = {
    myGroups: (state) => state.groups,    
    
};
  
// ACTIONS
const actions = {
    async updateMyGroupsAction({commit}) {
        let groups;
        // console.log('updateGroup action called')
        await api.get("myGroups")
        .then(async (res) => {
            groups = res.data;
            // console.log('My groups', groups)
            await commit("setMyGroups", groups);
            // console.log('My Groups committed: ', groups)
        })
        .catch(async (error) => {
            // console.log('User is in no group.')
            await commit("setMyGroups", null);  
          })
    }
};
  
  // MUTATIONS
const mutations = {
  
    setMyGroups(state, groups) {
      state.groups = groups;
    },

  
};
  
  
  // EXPORT
export default {
    state,
    getters,
    actions,
    mutations,
}
  