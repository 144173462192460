<template>

     <div v-if="showTable">

         <b-container fluid v-if="filterActv">
             <b-row class="mb-3">                 
                 <b-col>
                     <b-form-group 
                        label=""
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-input-group size="sm" >
                            <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Suchste wat?"
                            ></b-form-input>

                            <!-- <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''"><b-icon icon="cross"></b-icon></b-button>
                            </b-input-group-append> -->
                        </b-input-group>
                        </b-form-group>
                 </b-col>
             </b-row>
         </b-container>

         

        <b-table 
            :items="tableData" 
            :fields="fields"             
            responsive="sm"             
            :filter="filter"
            >

        <!-- Icon Column -->
        <template #cell(userIcon)="row">
            <div @click="row.toggleDetails">
                <b-avatar class="avatarAdmin">
                    {{row.item.user.prename.charAt(0) + row.item.user.surname.charAt(0)}}
                </b-avatar>
            </div>
        </template>

        <template #cell(questionText)="row">
            <div @click="row.toggleDetails">
                {{row.item.questionText}}
            </div>            
        </template>

        <!-- Points Column -->
        <template #cell(points)="row">
            <div v-if="answerAvailable(row.item)" @click="row.toggleDetails">
                <b-avatar v-if="row.item.user._id === $store.getters.user._id" class="badgeCreatorPoints">
                {{row.item.answer.points}}
            </b-avatar>
            <b-avatar v-else v-bind:class="{'badge-success' : (row.item.answer.points != 0)}">
                {{row.item.answer.points}}
            </b-avatar>
            </div>    
            <!-- Jokers -->
            <div v-if="jokerUsed(row.item)" class="mt-3" @click="row.toggleDetails">
                <!-- Majority Answer Joker -->
                <svg v-if="majorityAnswerJokerUsed(row.item)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="1.5rem" viewBox="0 0 80 50" style="enable-background:new 0 0 80 50;" xml:space="preserve" v-bind:class="{jokerSuccessful: row.item.answer.points != 0}">
                    <path d="M40.4,18.4c1.3,0,2.5,0.2,3.6,0.6c0.1-1.5,0.7-2.8,1.7-3.9c-0.7-0.1-1.4-0.2-2.2-0.2h-7.1
                        c-0.7,0-1.5,0.1-2.2,0.2c1.1,1.2,1.7,2.7,1.7,4.2C37.4,18.7,38.9,18.4,40.4,18.4z"/>
                    <ellipse cx="40.1" cy="7.7" rx="8.4" ry="6.7"/>
                    <path d="M53.2,25.9c4.3,0,7.7-2.8,7.7-6.2c0-3.4-3.4-6.2-7.7-6.2c-4.2,0-7.6,2.7-7.7,6.1
                        c2.4,1.2,4.1,3.3,4.4,5.7C50.9,25.7,52,25.9,53.2,25.9z"/>
                    <path d="M26.9,13.5c-4.3,0-7.7,2.8-7.7,6.2s3.4,6.2,7.7,6.2c1.5,0,2.9-0.4,4.1-1c0.4-2,1.7-3.7,3.6-4.9
                        c0-0.1,0-0.2,0-0.3C34.6,16.3,31.2,13.5,26.9,13.5z"/>
                    <path d="M58.2,13.4c2.4,1.2,4.1,3.3,4.4,5.7c1,0.4,2.1,0.6,3.3,0.6c4.3,0,7.7-2.8,7.7-6.2
                        s-3.4-6.2-7.7-6.2C61.7,7.3,58.2,10,58.2,13.4z"/>
                    <ellipse cx="40.4" cy="26.1" rx="7.7" ry="6.2"/>
                    <path d="M56.4,26.3H50c-0.1,2.1-1.2,4-2.9,5.3c4.8,1.2,8.4,4.8,8.4,9v2c6.4-0.2,10.1-1.6,10.3-1.7
                        l0.5-0.2h0.1v-6.4C66.3,29.9,61.9,26.3,56.4,26.3z"/>
                    <path d="M69.1,20.1h-6.5c-0.1,2.1-1.2,4-2.9,5.3c4.8,1.2,8.4,4.8,8.4,9v2c6.4-0.2,10.1-1.6,10.3-1.7
                        l0.5-0.2H79V28C79,23.6,74.6,20.1,69.1,20.1z"/>
                    <path d="M33.8,31.6c-1.7-1.3-2.8-3.2-2.9-5.3c-0.2,0-0.5,0-0.7,0h-6.5c-5.4,0-9.9,3.6-9.9,7.9v6.4l0,0.1
                        l0.6,0.1c4.2,1,7.9,1.5,11.1,1.7v-1.9C25.5,36.4,29,32.8,33.8,31.6z"/>
                    <path d="M43.7,32.7h-6.5c-5.4,0-9.9,3.6-9.9,7.9V47l0,0.1l0.5,0.1c5.2,1.3,9.7,1.7,13.4,1.7
                        c7.2,0,11.4-1.7,11.7-1.8l0.5-0.2h0.1v-6.4C53.6,36.2,49.1,32.7,43.7,32.7z"/>
                    <path d="M14.1,19.9c1.2,0,2.3-0.2,3.3-0.6c0.3-2.4,2-4.5,4.4-5.7c-0.1-3.4-3.5-6.1-7.7-6.1
                        c-4.3,0-7.7,2.8-7.7,6.2C6.4,17.1,9.9,19.9,14.1,19.9z"/>
                    <path d="M20.3,25.6c-1.8-1.3-2.9-3.2-2.9-5.3h-6.5c-5.4,0-9.9,3.6-9.9,7.9v6.4h0.1l0.5,0.2
                        c0.2,0.1,3.9,1.6,10.3,1.7v-2C11.9,30.3,15.4,26.7,20.3,25.6z"/>
                    <path d="M23.1,12.9c0.9-0.3,2.3-0.7,3.7-0.7c1.6,0,3.1,0.3,4.5,0.9c0.3-0.2,0.6-0.5,0.8-0.8
                        c-1.3-1.4-2-3-2-4.8c0-1.2,0.3-2.3,0.9-3.4c-1.4-1-3.2-1.6-5.1-1.6c-3.5,0-6.5,1.9-7.4,4.5C21.2,8.4,22.9,10.5,23.1,12.9z"/>
                    <path d="M48.1,12c0.3,0.3,0.6,0.7,0.9,1c1.3-0.5,2.7-0.8,4.1-0.8c1.4,0,2.7,0.3,3.6,0.5
                        c0.3-2.6,2.2-4.8,5.1-6c-1.1-2.4-3.9-4.1-7.2-4.1c-2.1,0-4.1,0.7-5.5,1.9c0.5,1,0.8,2,0.8,3C49.8,9.2,49.2,10.7,48.1,12z"/>
                </svg>
                <!-- 50:50 Joker -->
                <svg  v-if="fiftyFiftyJokerUsed(row.item)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="1.5rem" viewBox="0 0 80 50" style="enable-background:new 0 0 80 50;" xml:space="preserve" v-bind:class="{jokerSuccessful: row.item.answer.points != 0}">
                    <path d="M1,30.4l4.3-0.4c0.1,1,0.5,1.8,1.1,2.3c0.6,0.6,1.3,0.9,2.1,0.9c0.9,0,1.7-0.4,2.3-1.1s0.9-1.8,0.9-3.3
                        c0-1.4-0.3-2.4-0.9-3.1c-0.6-0.7-1.4-1-2.4-1c-1.2,0-2.4,0.6-3.4,1.7l-3.5-0.5l2.2-11.8h11.5v4.1H7l-0.7,3.8c1-0.5,2-0.7,3-0.7
                        c1.9,0,3.6,0.7,4.9,2.1c1.3,1.4,2,3.2,2,5.5c0,1.9-0.5,3.5-1.6,5c-1.5,2-3.5,3-6.1,3c-2.1,0-3.8-0.6-5.1-1.7
                        C2,33.8,1.3,32.3,1,30.4z"/>
                    <path  d="M25.9,13.4c2.2,0,3.9,0.8,5.1,2.3c1.5,1.9,2.2,4.9,2.2,9.2c0,4.3-0.7,7.4-2.2,9.2c-1.2,1.5-2.9,2.3-5.1,2.3
                        c-2.2,0-4-0.8-5.3-2.5c-1.4-1.7-2-4.7-2-9.1c0-4.3,0.7-7.3,2.2-9.2C22,14.2,23.7,13.4,25.9,13.4z M25.9,17c-0.5,0-1,0.2-1.4,0.5
                        s-0.7,0.9-1,1.8c-0.3,1.1-0.4,3-0.4,5.7c0,2.7,0.1,4.5,0.4,5.5c0.3,1,0.6,1.7,1,2c0.4,0.3,0.9,0.5,1.4,0.5c0.5,0,1-0.2,1.4-0.5
                        c0.4-0.3,0.7-0.9,1-1.8c0.3-1.1,0.4-3,0.4-5.7c0-2.7-0.1-4.5-0.4-5.5c-0.3-1-0.6-1.7-1-2C26.9,17.2,26.4,17,25.9,17z"/>
                    <path d="M37.9,24.1v-4.3h4.3v4.3H37.9z M37.9,36.2v-4.3h4.3v4.3H37.9z"/>
                    <path d="M46.8,30.4l4.3-0.4c0.1,1,0.5,1.8,1.1,2.3c0.6,0.6,1.3,0.9,2.1,0.9c0.9,0,1.7-0.4,2.3-1.1
                        c0.6-0.7,0.9-1.8,0.9-3.3c0-1.4-0.3-2.4-0.9-3.1c-0.6-0.7-1.4-1-2.4-1c-1.2,0-2.4,0.6-3.4,1.7l-3.5-0.5l2.2-11.8H61v4.1h-8.2
                        l-0.7,3.8c1-0.5,2-0.7,3-0.7c1.9,0,3.6,0.7,4.9,2.1c1.3,1.4,2,3.2,2,5.5c0,1.9-0.5,3.5-1.6,5c-1.5,2-3.5,3-6.1,3
                        c-2.1,0-3.8-0.6-5.1-1.7C47.8,33.8,47,32.3,46.8,30.4z"/>
                    <path d="M71.6,13.4c2.2,0,3.9,0.8,5.1,2.3c1.5,1.9,2.2,4.9,2.2,9.2c0,4.3-0.7,7.4-2.2,9.2
                        c-1.2,1.5-2.9,2.3-5.1,2.3c-2.2,0-4-0.8-5.3-2.5c-1.4-1.7-2-4.7-2-9.1c0-4.3,0.7-7.3,2.2-9.2C67.7,14.2,69.4,13.4,71.6,13.4z
                        M71.6,17c-0.5,0-1,0.2-1.4,0.5c-0.4,0.3-0.7,0.9-1,1.8c-0.3,1.1-0.4,3-0.4,5.7c0,2.7,0.1,4.5,0.4,5.5c0.3,1,0.6,1.7,1,2
                        c0.4,0.3,0.9,0.5,1.4,0.5c0.5,0,1-0.2,1.4-0.5c0.4-0.3,0.7-0.9,1-1.8c0.3-1.1,0.4-3,0.4-5.7c0-2.7-0.1-4.5-0.4-5.5
                        c-0.3-1-0.6-1.7-1-2C72.6,17.2,72.2,17,71.6,17z"/>
                </svg>
                <!-- Copy Joker -->
                <svg v-if="copyAnswerJokerUsed(row.item)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="1.5rem" viewBox="0 0 80 50" style="enable-background:new 0 0 80 50;" xml:space="preserve" v-bind:class="{jokerSuccessful: row.item.answer.points != 0}">
                    <circle cx="43.8" cy="17.2" r="2.5"/>
                    <circle cx="39.7" cy="34.2" r="2.5"/>
                    <path d="M74.5,30.2c0.7-0.5,1.5-0.7,2.4-0.6c-0.5-9.3-7.9-12.1-15.8-8.5C59.9,9.7,48,4.5,43.9,13.8
                        c1.1,0,2.1,0.4,2.9,1.7c2.9-1.4,4.5,0.8,4.5,7.2c-4.7-3.1-15.2-4.1-16.6,4.4c-0.3,1.6,0.6,3.3,2.3,5.2c0.4-0.7,1.1-1.2,1.9-1.3
                        c-0.3-4,4.3-4.9,6.2,0c-0.4,2.2,0,4.9,0.2,6.1c0.4,2.3,1.3,4.5,2.5,6.7c1.9,3.3,4.3,5.1,7.2,5.1c5.5,0.1,8.8-5.7,10-10.4
                        c0.5-1.9,1.1-5.3,0.4-7.8C67.8,27,71.3,26.8,74.5,30.2z M48.1,35.9c-0.5-0.2-0.8-0.4-0.9-0.6c-0.3-0.8,3-2.5,5.1-0.6
                        C55.4,37.6,49.2,36.4,48.1,35.9z M58.3,43.5c-1.6,1.9-3.7,1.8-5.5,0.3c-0.8-0.7-1.2-1.5-1.4-2.5c2.7,1.6,5.4,1.7,8-0.1
                        C59.1,42.1,58.8,42.9,58.3,43.5z M63.3,35.3c-0.1,0.2-0.3,0.4-0.8,0.6c-1.1,0.5-7.2,1.9-4.3-1C60.2,32.9,63.6,34.5,63.3,35.3z"/>
                    <path d="M76.5,30.3c-1.4,0-2.5,0.3-2.5,1.7c0,1.4,1.1,3.3,2.5,3.3c1.4,0,2.5-1.1,2.5-2.5
                        C79,31.4,77.9,30.3,76.5,30.3z"/>
                    <circle cx="15.5" cy="17.5" r="8.5"/>
                    <path d="M19.1,26.6h-7.2C5.9,26.6,1,31.5,1,37.5v8.8l0,0.1l0.6,0.2C7.4,48.4,12.3,49,16.4,49
                        c8,0,12.6-2.3,12.9-2.4l0.6-0.3H30v-8.8C30,31.5,25.1,26.6,19.1,26.6z"/>
                    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
                        <path  d="M523.5,12737.1c-0.4,0.3-1.9,1.5-3.3,2.6c-3.6,2.9-7,5.5-11.5,8.9c-41.1,30.3-81.6,44-120.5,41
                            c-20.3-1.6-41.1-8.3-59.4-19.3c-10.2-6.1-18.8-12.7-27.9-21.3c-4.9-4.6-8.1-7.9-11.8-12.2c-1.4-1.6-2.6-3-2.6-3
                            c-0.1-0.1-3.8,3.7-14.2,14.7l-14.1,14.9l-1.1,0.4c-2.4,0.8-4.6,0.6-6.7-0.8c-1.2-0.8-2.3-2.1-2.9-3.6c-0.3-0.8-0.7-10.9-1.8-46.6
                            c-0.8-25.1-1.3-46.2-1.2-47c0.3-2.7,2.1-5,4.5-5.9c0.8-0.3,9-1.4,26.2-3.5c13.8-1.7,36.1-4.3,49.6-5.9c13.5-1.6,25-2.9,25.6-2.9
                            c2,0.1,4.1,1.3,5.2,2.9c1.9,2.6,2,5.9,0.3,8.5c-0.4,0.6-5.7,6.4-14.8,16l-14.2,15l0.3,0.5c0.2,0.2,1,1.4,1.9,2.5
                            c8.5,10.9,19.8,21,31.8,28.6c30.9,19.5,68.6,25,111,16.2c13.6-2.8,27-6.9,43.8-13.5c2.9-1.1,4.4-1.3,6.1-0.8
                            c5.1,1.5,7,7.8,3.5,11.9C524.7,12735.9,523.9,12736.7,523.5,12737.1z"/>
                    </g>
                </svg>
                
            </div>        
        </template>


        
        <!-- Details -->
        <template #row-details="row">
            
            <QuestionResult 
                :question="row.item"  
                />
            
        </template>
        </b-table>
        
    </div>
                
</template>

<script>
import api from '@/services/api'
import QuestionResult from '@/components/questions/QuestionResult'

export default {
    name: 'QuizQuestions',
    components : {QuestionResult},
    
    props:['quiz', 'filterActv', 'myQuestionsOnly'],


    data() {
        return {
            fields : [
                // {key: 'number', label: ' ' },
                {key: 'userIcon', label: ' ' },
                {key: 'questionText', label: ' ' },
                {key: 'points', label: ' ' },
                // {key: 'show_details', label: ' '}
            ],
            tableData : null,
            showTable : false,
            answers : null,

            currentPage : 1,
            totalRows : null,
            perPage : 5,
            filter : null,


          
        };
    },

    methods: {
        answerAvailable(question) {
            if (question.answer) {
                return true;
            }
            else {
                return false;
            }
        },

        jokerUsed(question){
            if (!!question.answer && !!question.answer.jokerUsed) {
                return true;
            }
            else {
                return false;
            }
        },

        majorityAnswerJokerUsed(question){
            if (question.answer.jokerUsed === 'majorityAnswer') {
                return true;
            }
            else {
                return false;
            }
        },

        fiftyFiftyJokerUsed(question){
            if (question.answer.jokerUsed === 'fiftyFifty') {
                return true;
            }
            else {
                return false;
            }
        },

        copyAnswerJokerUsed(question){
            if (question.answer.jokerUsed === 'copyAnswer') {
                return true;
            }
            else {
                return false;
            }
        },

  
    },

    async mounted() {       
        // set up headings
        this.tableData = this.quiz.questions.filter(question => question.status === "evaluated"); 
        if (this.myQuestionsOnly){
            this.tableData = this.tableData.filter(question => String(question.user._id) === (this.$store.getters.user._id)); 
        }
        for (let i=0; i < this.tableData.length; i++){
            this.tableData[i].number = i+1;                        
        }

        // get answer documents of active user
        await api.get('answer?quiz=' + this.quiz._id + '&user=' + this.$store.getters.user._id)
        .then(res => {
            this.answers = res.data;               
        })
        .catch(error => console.log(error.response));


        this.tableData.forEach(element => {
            element.creatorInitials = element.user.prename.charAt(0) + element.user.surname.charAt(0);            
            element.answer = this.answers.find(a => String(a.question) === String(element._id));            
        });
        // this.tableData[0]._showDetails = true;
        this.totalRows = this.tableData.length;
        this.showTable = true;


        


        


    },
}
</script>

<style>

</style>