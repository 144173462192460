<template>
<div>
    <!-- Final Ranking - Quiz Evaluation -->
    <div v-if="dispQuizEvaluation">
        <b-container>
            <b-row>
                <div class="mt-5 mb-5 ml-2 mr-2 cardHeader" style="font-size:1.8rem; text-align: left;">
                    <b>Siegerehrung</b>
                </div>
            </b-row>
            <b-row>
                <b-col>
                    <Ranking v-on:evolutionComplete="dispEndQuizButton = true" :quiz="quiz" :columns="columns" :evolve="true"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button v-if="dispEndQuizButton" @click="endQuizButtonConfirm" class="buttonStandard" style="height: 35px;">Quiz beenden</b-button>
                </b-col>                
            </b-row>
        </b-container>
        <p></p>
        <h1></h1>
        <p> </p>        
        <div>
                
        </div>
        
    </div>
    
    <!-- Deregister User Modal -->
    <b-modal ref="deregisterUserModal" hide-footer :title=deregisterUserHeaderSring>
        <p>{{deregisterUserText}}</p>
        <b-button variant="danger" block @click="deregisterUserConfirm">Ja, {{userPrenameToBeDeRegistered}} abmelden.</b-button>              
    </b-modal> 


<!-- Main Screen (left) -->
    <div style="width: 70%; float: left;">
        <b-container>
            <div v-if="dispQuestion || dispWelcomeScreen" style="margin-top: 10px;">
                <!-- Progress bar -->
                <b-row>
                    <b-col cols="auto">
                        <b-avatar v-if="dispQuestion && !breakActive" size="35px" class="avatarAdmin">
                            {{quiz.currentQuestion.user.prename.charAt(0) + quiz.currentQuestion.user.surname.charAt(0)}}
                        </b-avatar>                        
                        <b-avatar v-else size="35px" :src="require('../assets/QLogo.png')"></b-avatar>
                    </b-col>
                    <b-col class="quizProgressCol">
                        <b-progress :max="quiz.totalNumberOfQuestions" class="quizProgress">
                            <b-progress-bar :value="quiz.currentQuestionNumber" class="colorMain">
                                <span>{{ quiz.currentQuestionNumber }} / {{ quiz.totalNumberOfQuestions }}</span>
                            </b-progress-bar>
                        </b-progress>
                    </b-col>
                </b-row>
                <!-- Lobby/Welcome Screen -->
                <b-row v-if="dispWelcomeScreen">
                    <b-col cols="12" class="questionText">Willkommen zum Supertollen Quizabend!</b-col>
                    <b-col cols="12">
                        Es haben sich für heute {{ranking.length}} Spielende angemeldet.<br><br>
                        Die Spannung steigt, die Lobby füllt sich langsam ...<br>
                        Wer wird wohl heute den goldenen Pokal gewinnen? {{randomPreName}} scheint äußerst motiviert zu sein.<br><br>
                        Hol dir am besten schnell noch ein Bier und dann geht es gleich auch schon los.
                    </b-col>                    
                </b-row>
                <!-- Question Text -->
                <b-row v-if="dispQuestion  && !breakActive">
                    <b-col class="questionText">{{quiz.currentQuestion.questionText}}</b-col>
                </b-row>
                <!-- Correct Estimation Question Answers -->
                <b-row v-if="dispQuestion && questionIsEstimation && !breakActive">
                    <b-col class="answerCol">
                        <b-row class="answerBox" v-bind:class="{ answerBoxCorrect: questionIsEvaluated }">
                            <b-col cols="auto" class="answerLetter" v-bind:class="{ answerLetterCorrect: questionIsEvaluated }"></b-col>
                            <b-col v-if="questionIsEvaluated" class="answerText" style="text-align: center;">{{question.estimationCorrectAnswer}}</b-col>
                            <b-col v-else class="answerText" style="text-align: center;">???</b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <!-- Multiple Coice Answers -->
                <b-row v-if="dispQuestion && questionIsMultipleChoice  && !breakActive" cols="1" cols-md="2">
                    <b-col class="answerCol">
                        <b-row class="answerBox" v-bind:class="{ answerBoxCorrect: dispAnswerCorrectA }">
                            <b-col cols="auto" class="answerLetter" v-bind:class="{ answerLetterCorrect: dispAnswerCorrectA }">A</b-col>
                            <b-col class="answerText">{{multipleChoieAnswerArray[0]}}</b-col>
                        </b-row>
                    </b-col>
                    <b-col class="answerCol">
                        <b-row class="answerBox" v-bind:class="{ answerBoxCorrect: dispAnswerCorrectB }">
                            <b-col cols="auto" class="answerLetter" v-bind:class="{ answerLetterCorrect: dispAnswerCorrectB }">B</b-col>
                            <b-col class="answerText">{{multipleChoieAnswerArray[1]}}</b-col>
                        </b-row>
                    </b-col>
                    <b-col class="answerCol">
                        <b-row class="answerBox" v-bind:class="{ answerBoxCorrect: dispAnswerCorrectC }">
                            <b-col cols="auto" class="answerLetter" v-bind:class="{ answerLetterCorrect: dispAnswerCorrectC }">C</b-col>
                            <b-col class="answerText">{{multipleChoieAnswerArray[2]}}</b-col>
                        </b-row>
                    </b-col>
                    <b-col class="answerCol">
                        <b-row class="answerBox" v-bind:class="{ answerBoxCorrect: dispAnswerCorrectD }">
                            <b-col cols="auto" class="answerLetter" v-bind:class="{ answerLetterCorrect: dispAnswerCorrectD }">D</b-col>
                            <b-col class="answerText">{{multipleChoieAnswerArray[3]}}</b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <!-- Question Text -->
                <div v-if="dispQuestion && breakActive">
                    <b-row>
                        <b-col class="questionText">
                            <br><br> Pinkelpause <b-icon icon="emoji-heart-eyes"></b-icon> <br><br> 
                        </b-col>                    
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button class="buttonStandard" @click="showRankingButtonClick">Wie steht's?</b-button>                        
                        </b-col>                
                    </b-row>                
                </div>
                

            </div>
        </b-container>
    </div>



    <!-- Support Screen (right) -->
    <div style="width: 30%; float: left; padding:10px; text-align: left;">
        <!-- Content for Lobby/Welcom Screen -->
        <b-container v-if="dispWelcomeScreen">
            <b-row>
                <b-col>
                    <b-button class="buttonConfirm buttonFullWidth" @click="startQuizButtonClick">Quiz starten</b-button>
                </b-col>
            </b-row>
            <b-row style="margin-top: 40px;">
                <b-col>
                    <b-list-group>
                        <b-list-group-item v-for="result in ranking" :key="result._id" class="d-flex align-items-center">
                            <b-avatar v-if="userIsInLobby(result.user._id)" class="avatarAdmin mr-3">
                                {{result.user.prename.charAt(0) + result.user.surname.charAt(0)}}
                            </b-avatar>
                            <b-avatar v-else class="avatarMember mr-3">
                                {{result.user.prename.charAt(0) + result.user.surname.charAt(0)}}
                            </b-avatar>
                            <span class="mr-auto">{{result.user.prename}} {{result.user.surname}}</span>
                            <b-badge>{{numberOfpostedQuestions(result.user._id)}}</b-badge>
                            <b-button class="buttonReject buttonRound ml-3" style="width: 1.5em; height:1.5em;" @click="deregisterUserButtonClick(result.user)">
                                <b-icon-x scale="1.3" />
                            </b-button>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
        </b-container>
        <!-- Content for Questions -->
        <b-container v-if="dispQuestion">
            <!-- Next Action Button -->
            <b-row>
                <b-col>
                    <!-- <div v-if="questionIsOpen">
                        <b-button @click="closeQuestionButtonClick" class="buttonStandard" style="border-top-right-radius: 0; border-bottom-right-radius: 0; height: 35px;">Frage schließen</b-button>
                        <b-dropdown right style="height: 35px;">
                            <b-dropdown-item @click="nextQuestionButtonClick">Frage überspringen</b-dropdown-item>
                            <b-dropdown-item @click="previousQuestionButtonClick">Zur vorherigen Frage</b-dropdown-item>
                            <b-dropdown-item @click="resetQuestionButtonClick">Frage zurücksetzen</b-dropdown-item>                                                        
                        </b-dropdown>
                    </div>
                    <div v-if="questionIsClosed">
                        <b-button @click="evaluateQuestionButtonClick" class="buttonStandard" style="border-top-right-radius: 0; border-bottom-right-radius: 0; height: 35px;">Frage auswerten</b-button>
                        <b-dropdown right style="height: 35px;">
                            <b-dropdown-item @click="resetQuestionButtonClick">Frage zurücksetzen</b-dropdown-item>
                            <b-dropdown-item @click="nextQuestionButtonClick">Frage überspringen</b-dropdown-item>
                            <b-dropdown-item @click="previousQuestionButtonClick">Zur vorherigen Frage</b-dropdown-item>                            
                        </b-dropdown>
                    </div>
                    <div v-if="dispNextQuestionButton">
                        <b-button @click="nextQuestionButtonClick" class="buttonStandard" style="border-top-right-radius: 0; border-bottom-right-radius: 0; height: 35px;">Zur nächsten Frage</b-button>
                        <b-dropdown right style="height: 35px;">
                            <b-dropdown-item @click="previousQuestionButtonClick">Zur vorherigen Frage</b-dropdown-item>
                            <b-dropdown-item @click="resetQuestionButtonClick">Frage zurücksetzen</b-dropdown-item>
                            <b-dropdown-item @click="showRankingButtonClick">Spielstand</b-dropdown-item>                            
                            <b-dropdown-item @click="endQuizButtonClick">Quiz beenden</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div v-if="dispToQuizEvaluationButton">
                        <b-button @click="evaluateQuizButtonConfirm" class="buttonStandard" style="height: 35px;">Zur Auswertung</b-button>                        
                    </div> -->
                    <div v-if="breakActive">
                        <b-button-group class="buttonFullWidth">
                            <b-dropdown right split text="Pinkelpause fertig!" @click="nextQuestionButtonClick" variant="outline-primary" class="buttonFullWidth" style="height: 35px;">                                
                                <b-dropdown-item @click="showRankingButtonClick">Spielstand</b-dropdown-item> 
                                <b-dropdown-item v-if="dispPrevQuestionOption" @click="previousQuestionButtonClick">Zur vorherigen Frage</b-dropdown-item>                                                        
                                <b-dropdown-item @click="endQuizButtonClick">Quiz beenden</b-dropdown-item>
                                <b-dropdown-item @click="goHome">Zur Startseite</b-dropdown-item>
                            </b-dropdown>
                        </b-button-group>
                    </div>
                    <div v-else>
                        <b-button-group v-if="questionIsOpen" class="buttonFullWidth">
                            <b-dropdown right split text="Frage schließen" @click="closeQuestionButtonClick" variant="outline-primary" class="buttonFullWidth" style="height: 35px;">
                                <b-dropdown-item @click="resetQuestionButtonClick">Frage zurücksetzen</b-dropdown-item>
                                <b-dropdown-item v-if="dispNextQuestionOption" @click="nextQuestionButtonClick">Frage überspringen</b-dropdown-item>
                                <b-dropdown-item v-if="dispPrevQuestionOption" @click="previousQuestionButtonClick">Zur vorherigen Frage</b-dropdown-item>                                                        
                                <b-dropdown-item @click="goHome">Zur Startseite</b-dropdown-item>
                            </b-dropdown>
                        </b-button-group>
                        <b-button-group v-if="questionIsClosed" class="buttonFullWidth">
                            <b-dropdown right split text="Frage auswerten" @click="evaluateQuestionButtonClick" variant="outline-primary" class="buttonFullWidth" style="height: 35px;">
                                <b-dropdown-item @click="resetQuestionButtonClick">Frage zurücksetzen</b-dropdown-item>
                                <b-dropdown-item v-if="dispNextQuestionOption" @click="nextQuestionButtonClick">Frage überspringen</b-dropdown-item>
                                <b-dropdown-item v-if="dispPrevQuestionOption" @click="previousQuestionButtonClick">Zur vorherigen Frage</b-dropdown-item>
                                <b-dropdown-item @click="goHome">Zur Startseite</b-dropdown-item>                            
                            </b-dropdown>
                        </b-button-group>
                        <b-button-group v-if="dispNextQuestionButton" class="buttonFullWidth">
                            <b-dropdown right split text="Zur nächsten Frage" @click="nextQuestionButtonClick" variant="outline-primary" class="buttonFullWidth" style="height: 35px;">
                                <b-dropdown-item @click="showRankingButtonClick">Spielstand</b-dropdown-item> 
                                <b-dropdown-item @click="resetQuestionButtonClick">Frage zurücksetzen</b-dropdown-item>                               
                                <b-dropdown-item v-if="dispPrevQuestionOption" @click="previousQuestionButtonClick">Zur vorherigen Frage</b-dropdown-item>
                                <b-dropdown-item @click="endQuizButtonClick">Quiz beenden</b-dropdown-item>
                                <b-dropdown-item @click="goHome">Zur Startseite</b-dropdown-item>
                            </b-dropdown>
                        </b-button-group>
                        <div v-if="dispToQuizEvaluationButton">
                            <b-dropdown right split text="Zur Auswertung" @click="evaluateQuizButtonConfirm" variant="outline-primary" class="buttonFullWidth" style="height: 35px;">
                                <b-dropdown-item @click="resetQuestionButtonClick">Frage zurücksetzen</b-dropdown-item>                               
                                <b-dropdown-item v-if="dispPrevQuestionOption" @click="previousQuestionButtonClick">Zur vorherigen Frage</b-dropdown-item>                                                        
                            </b-dropdown>
                        </div>


                            
                    </div>
                </b-col>
            </b-row>
            <!-- Status Overview -->
            <b-row style="padding-top: 30px;">
                <b-col v-if="!breakActive">
                    <QuestionStatus :question="question" :questionStatus="questionStatus"/>
                </b-col>
            </b-row>
            <!-- ONLY FOR DEVELOPMENT FOR EASIER NAVIGATION
            <b-row style="margin-top: 30px;">
                <b-col>
                    <b-icon icon="house-door" @click="homeButtonClick" class="mr-1"></b-icon>             
                    <b-icon v-if="questionIsEvaluated" icon="list-ol" @click="showRankingButtonClick" class="mr-1"></b-icon>                
                    <b-icon icon="arrow-left-circle" @click="previousQuestionButtonClick" class="mr-1"></b-icon>   
                    <b-icon icon="arrow-right-circle" @click="nextQuestionButtonClick" class="mr-1"></b-icon>   
                    <b-icon v-if="!questionIsEvaluated" icon="bootstrap-reboot" @click="resetQuestionButtonClick" class="mr-1"></b-icon>  
                    <b-icon v-if="questionIsEvaluated" icon="door-open" @click="endQuizButtonClick"></b-icon>
                </b-col>
            </b-row> -->
        </b-container>
    </div>

    <!-- Modals -->
    <b-modal ref="closeQuestionModal" hide-footer title="Frage vorzeitig schließen">
        <p>Nach dem Schließen der Frage können keine weiteren Antworten mehr eingeloggt werden.</p>
        <p>Die Antwort von folgenden Spielern fehlt noch:</p>
        <div v-if="questionStatus">
            <li v-for="player in questionStatus.missingAnswers" :key="player._id">
                {{player.prename}} {{player.surname}}
            </li> 
        </div>
        <p></p>
        <p>Sicher?</p>
        <b-button variant="danger" block @click="closeQuestionConfirm">Ja, Frage schließen.</b-button>              
    </b-modal>

    <b-modal ref="resetQuestionModal" hide-footer title="Frage zurücksetzen">
        <p>Alle bisher eingereichten Antworten werden gelöscht.</p>
        <p>Sicher?</p>
        <b-button variant="danger" block @click="resetQuestionConfirm">Ja, Frage zurücksetzen.</b-button>              
    </b-modal>

    <b-modal ref="invalidJokerModal" hide-footer no-close-on-esc no-close-on-backdrop hide-header-close title="Ups ...">
        <p>Ein oder mehrere Joker konnten nicht ausgewertet werden. Habt ihr etwa alle den Mehrheitsjoker genutzt? Oder euch gegenseitig die Antworten kopiert? </p>
        <p>Alle Antworten wurden zurückgesetzt. <br> Die Frage wird neu gespielt.</p>
        <b-button block class="buttonStandard" @click="resetQuestionConfirm">Ok</b-button>              
    </b-modal>

    
    <b-modal ref="endQuizModal" hide-footer title="Quiz vorzeitig beenden">        
        <p>Keine Lust mehr?</p>
        <p>Nicht gespielte Fragen können beim nächsten Quiz erneut eingereicht werden.</p>
        <b-button variant="danger" block @click="evaluateQuizButtonConfirm">Ja, Quiz beenden.</b-button>              
    </b-modal>

    <b-modal ref="rankingModal" size="xl" hide-footer title="Spielstand">
        <Ranking :quiz="quiz" :columns="columns" :evolve="true"/>           
    </b-modal> 
</div>    
</template>

<script>
import api from '@/services/api'
import Ranking from '@/components/Ranking.vue';
import QuestionStatus from '@/components/questions/QuestionStatus.vue';
export default {
    name: 'LeadQuiz',
    components: { Ranking , QuestionStatus },
    // directives: { DirectiveName },
    data() {
        return {
            ranking : null,
            quiz : null,
            question : null,
            questionStatus : null,
            
            dispWelcomeScreen : false,
            dispQuestion : false,
            dispQuizEvaluation : false,
            dispEndQuizButton : false,
            
            questionIsOpen : false,
            questionIsOpenOrClosed : false,
            questionIsClosed : false,
            questionIsEvaluated : false,

            timeOut : false,
            lobbyTimeOut : false,

            dispAnswerCorrectA : false,
            dispAnswerCorrectB : false,
            dispAnswerCorrectC : false,
            dispAnswerCorrectD : false,

            userToBeDeRegistered : null,
            deregisterUserHeaderSring : '',
            deregisterUserText : '',

            randomPreName : null,            

            // Ranking Columns
            columns : ['numberJokersAvailable' , 'pointsPostedQuestions' ,  'pointsCorrectMultipleChoiceAnswers' , 'pointsGoodEstimations', 'pointsTotal'],
            
        };
    },

    methods: {
        goHome(){
            this.$router.push('/');
        },

        async updateCurrentQuestion(){
            await api.get('quizNight/currentQuestion/' + this.quiz._id).then(async (res) => {                                  
                this.question = res.data;
            });
        },

        async updateLobbyStatus(){
            if (this.quiz.status === 'notStarted'){
                await api.get('quiz/' + this.quiz._id).then(async (res) => {                      
                    this.quiz = res.data;            
                });
                this.lobbyTimeOut = setTimeout(this.updateLobbyStatus,100);
            } 
            else {
                clearTimeout(this.lobbyTimeOut);
            }
        },
        
        async updateCurrentQuestionStatus(){
            await api.get('quizNight/currentQuestionStatus/' + this.quiz._id).then(async (res) => {                    
                this.questionStatus = res.data;
                this.updateQuestionStatusVariables();         
            }); 
            if (this.questionStatus.status === 'open'){
                this.timeOut = setTimeout(this.updateCurrentQuestionStatus,500);
            }
        },

        playAllAnswersReceivedChime(){
            let chime = new Audio(require("../assets/audio/Bell.mp3"));            
            chime.play();
        },

        updateQuestionStatusVariables(){ 
            let _questionIsClosed = this.questionIsClosed;
            if (this.questionStatus){                
                this.questionIsOpen = this.questionStatus.status === 'open' ? true : false;
                this.questionIsClosed = this.questionStatus.status === 'closed' ? true : false;
                this.questionIsEvaluated = this.questionStatus.status === 'evaluated' ? true : false;
                this.questionIsOpenOrClosed = (this.questionStatus.status === 'open' || this.questionStatus.status === 'closed') ? true : false;
            }
            else {
                this.questionIsOpen = false;                                
                this.questionIsClosed = false;
                this.questionIsEvaluated = false;
                this.questionIsOpenOrClosed = false;
            }

            if (!_questionIsClosed && this.questionIsClosed){
                this.playAllAnswersReceivedChime();
            }
        },

        deregisterUserButtonClick(user){
            console.log(user)
            this.userToBeDeRegistered = user;
            this.deregisterUserHeaderSring = 'Wo ist ' + user.prename + '?';
            this.deregisterUserText = 'Soll ' + user.prename + ' ' + user.surname +' vom Quiz abgemeldet werden?'
            this.$refs['deregisterUserModal'].show();
        },

        async deregisterUserConfirm(){            
            api.get('deregisterCurrentQuiz/' + this.userToBeDeRegistered._id)
            .then(async res => {
                api.get('quizRanking/' + this.quiz._id).then(async (res) => {                    
                    this.ranking = res.data;            
                });
                this.$refs['deregisterUserModal'].hide();
            })
            .catch(error => console.log(error.response)); 

        },

        numberOfpostedQuestions(userId){
            let userQuestions = this.questions.filter(question => String(question.user) === String(userId));
            return userQuestions.length;
        },

        userIsInLobby(userId){
            if (this.quiz && this.quiz.usersInLobby && this.quiz.usersInLobby.indexOf(userId) > -1){
                return true;
            }
            else {
                return false;
            }
        },

        async startQuizButtonClick(){
            await api.get('quizNight/startQuiz/' + this.quiz._id)
            .then(async (res) => {                    
                console.log('res.data:', res.data)
                this.quiz = res.data;
                console.log('Quiz:', this.quiz)
                clearTimeout(this.lobbyTimeOut);
                await this.updateCurrentQuestion();
                await this.updateCurrentQuestionStatus();
                await this.updateRanking();         
                this.updateQuestionStatusVariables();
                this.updateDispCorrectAnswer();
                this.dispWelcomeScreen = false;
                this.dispQuestion = true;   
            })            
        },

        async updateCurrentQuestionStatus(){            
            await api.get('quizNight/currentQuestionStatus/' + this.quiz._id)
            .then(async (res) => {                    
                this.questionStatus = res.data;
                this.updateQuestionStatusVariables();         
            })
            .catch(error => console.log(error.response)); 

            if (this.questionStatus.status === 'invalidJokers'){             
                this.$refs['invalidJokerModal'].show();            
            }            
            if (this.questionStatus.status === 'open'){
                this.timeOut = setTimeout(this.updateCurrentQuestionStatus,500);
            }
        },
        
        resetQuestionButtonClick(){
            this.$refs['resetQuestionModal'].show();
        },

        async resetQuestionConfirm(){
            await api.get('quizNight/resetCurrentQuestion/' + this.quiz._id);            
            await this.updateCurrentQuestionStatus();
            // this.updateCurrentQuestionStatus();
            this.updateDispCorrectAnswer();
            this.$refs['resetQuestionModal'].hide();
            this.$refs['invalidJokerModal'].hide()
        },

        closeQuestionButtonClick(){
            this.$refs['closeQuestionModal'].show();
        },

        async closeQuestionConfirm(){
            await api.get('quizNight/closeCurrentQuestion/' + this.quiz._id);
            await api.get('quizNight/currentQuestionStatus/' + this.quiz._id).then(async (res) => {                    
                this.questionStatus = res.data;            
            }); 
            this.$refs['closeQuestionModal'].hide();
        },

        async nextQuestionButtonClick(){
            await api.get('quizNight/nextQuestion/' + this.quiz._id).then(async (res) => {                    
                this.quiz = res.data;
                await this.updateCurrentQuestion();
                await this.updateCurrentQuestionStatus();
                this.updateDispCorrectAnswer();                            
                this.updateQuestionStatusVariables();
                
            })
            .catch(error => console.log(error.response)); 
        },

        async previousQuestionButtonClick(){
            await api.get('quizNight/previousQuestion/' + this.quiz._id).then(async (res) => {                    
                this.quiz = res.data;
                await this.updateCurrentQuestion();
                await this.updateCurrentQuestionStatus();
                this.updateDispCorrectAnswer();                            
                this.updateQuestionStatusVariables();                
            })
            .catch(error => console.log(error.response)); 
        },

        async evaluateQuestionButtonClick(){
            // clearTimeout(this.timeOut);
            await api.get('quizNight/evaluateCurrentQuestion/' + this.quiz._id).then(async (res) => {
                // console.log('data', res.data)             
                this.question = res.data.question;
                this.ranking = res.data.results;                
                if (this.question.status === 'open'){
                    this.$refs['invalidJokerModal'].show();
                }
                await this.updateCurrentQuestionStatus();
                this.updateQuestionStatusVariables();
                this.updateDispCorrectAnswer();           
            });
        },

        async updateRanking(){
            await api.get('quizRanking/' + this.quiz._id).then(async (res) => {
                this.ranking = res.data;
            })
            .catch(error => console.log(error.response));
        },

        async evaluateQuizButtonConfirm(){
            await api.get('quizNight/evaluateQuiz/' + this.quiz._id).then(async (res) => {
                this.quiz = res.data;
                this.dispQuestion = false;
                this.dispQuizEvaluation = true;
            })
            .catch(error => console.log(error.response)); 
            setTimeout(this.endQuizButtonConfirm , 300000);
        },

        async endQuizButtonConfirm (){
            await api.get('quizNight/endQuiz/' + this.quiz._id).then(async (res) => {
                this.quiz = res.data;                
                this.$router.push('/quiz/' + this.quiz._id)
            })
            .catch(error => console.log(error.response)); 
        },

        updateDispCorrectAnswer() {
            if (!this.questionStatus || this.questionStatus.status !== 'evaluated' || !this.questionIsMultipleChoice){
                this.dispAnswerCorrectA = false;
                this.dispAnswerCorrectB = false;
                this.dispAnswerCorrectC = false;
                this.dispAnswerCorrectD = false;            
            }
            else {                     
                let answerA = this.question.multipleChoiceAnswerDisplayOrder.indexOf(0)+1;
                let answerB = this.question.multipleChoiceAnswerDisplayOrder.indexOf(1)+1;
                let answerC = this.question.multipleChoiceAnswerDisplayOrder.indexOf(2)+1;
                let answerD = this.question.multipleChoiceAnswerDisplayOrder.indexOf(3)+1;
                this.dispAnswerCorrectA = answerA === this.question.multipleChoiceCorrectAnswer ? true : false;
                this.dispAnswerCorrectB = answerB === this.question.multipleChoiceCorrectAnswer ? true : false;
                this.dispAnswerCorrectC = answerC === this.question.multipleChoiceCorrectAnswer ? true : false;
                this.dispAnswerCorrectD = answerD === this.question.multipleChoiceCorrectAnswer ? true : false;
            }
        },

        homeButtonClick(){
            this.$router.push('/')
        },

        showRankingButtonClick(){
            this.$refs['rankingModal'].show();
        },

        endQuizButtonClick(){
            this.$refs['endQuizModal'].show();
        }

        
    },

    computed: {
        questionIsMultipleChoice : function() {
            if (this.quiz.currentQuestion && this.quiz.currentQuestion.questionType === "multipleChoice") {
                return true;
            } else {
                return false;
            }
        },

        receivedAnswers : function (){
            if (this.questionStatus && this.questionStatus.receivedAnswers) {
                return this.questionStatus.receivedAnswers;
            }
            else {
                return null;
            }
        },


        questionIsEstimation : function() {
            if (this.quiz.currentQuestion.questionType === "estimation") {
                return true;
            } else {
                return false;
            }
        },
        
        
        multipleChoieAnswerArray : function(){
            if (this.question && this.question.questionType === "multipleChoice") {                             
                let answerArray = [];
                let displayOrder = this.question.multipleChoiceAnswerDisplayOrder;
                for (let i=0 ; i < displayOrder.length; i++){
                    answerArray[ displayOrder[i] ] = this.question.multipleChoiceAnswers[i];
                }
                return answerArray;

            }
            else {
                return ['','','',''];
            }
        },

        userPrenameToBeDeRegistered : function (){
            if (this.userToBeDeRegistered){
                return this.userToBeDeRegistered.prename;
            }
            else {
                return ''
            }
        },

        dispMissingAnswers : function (){
            if (this.questionIsOpen && this.questionStatus && this.questionStatus.missingAnswers && this.questionStatus.missingAnswers.length < 4){
                return true;
            } else {
                return false;
            }
        },

        dispNextQuestionButton : function () {
            if (this.questionIsEvaluated && this.quiz && this.quiz.currentQuestionNumber < this.quiz.totalNumberOfQuestions){
                return true;
            }
            else {
                return false;
            }
        },

        dispToQuizEvaluationButton : function (){
            if (this.questionIsEvaluated && this.quiz && this.quiz.currentQuestionNumber === this.quiz.totalNumberOfQuestions){
                return true;
            }
            else {
                return false;
            }
        },

        dispPrevQuestionOption : function(){
            return this.quiz.currentQuestionNumber > 1 ? true : false;
        },

        dispNextQuestionOption : function (){
            return this.quiz.currentQuestionNumber !== this.quiz.totalNumberOfQuestions ? true : false;
        },

        numberOfReceivedAnswers : function (){
            if (this.questionStatus && this.questionStatus.receivedAnswers){
                return (this.questionStatus.receivedAnswers.length);
            } 
            else {
                return 0;
            }
        },

        lastQuestionIsPlayed : function (){
            if (this.quiz && this.quiz.currentQuestionNumber === this.quiz.totalNumberOfQuestions && this.question && this.question.status === 'evaluated'){
                return true;
            }
            else {
                return false;
            }
        },

        registeredUsers : function (){
            if (this.ranking.length > 0){
                return this.ranking.sort((a ,b) => {                
                    if ( a.user.prename > b.user.prename ){return +1;}
                    if ( a.user.prename < b.user.prename ){return -1;}
                });
            } 
            else {
                return null;
            }
        },

        breakActive : function(){
            return (this.quiz && this.quiz.breakActive) ? true : false;
        }

    },


    async mounted() {        

        if (!this.$store.getters.isGroupAdmin){
            await this.$router.push('/');
        }        
        await api.get('currentQuiz').then(async (res) => {                    
            this.quiz = res.data;            
        });

        if (!this.quiz){
            this.$router.push('/');
        }
        else {
            await api.get('quizRanking/' + this.quiz._id).then(async (res) => {                    
                this.ranking = res.data;            
            });

            if (this.quiz.status === 'notStarted') {
                await api.get("question?quiz=" + String(this.quiz._id))
                .then(res => this.questions = res.data)
                .catch(error => console.log(error.response));
                let nRandom = Math.round(Math.random() * this.ranking.length -0.5);      
                this.randomPreName = this.ranking[nRandom].user.prename; 
                this.dispWelcomeScreen = true;
            } 

            if (this.quiz.status === 'active'){
                await this.updateCurrentQuestion();
                await this.updateCurrentQuestionStatus();   
                await this.updateRanking();         
                this.updateQuestionStatusVariables();
                this.updateDispCorrectAnswer();
                this.dispQuestion = true;
            }

            if (this.quiz.status === 'evaluation'){
                await this.updateRanking();
                this.dispQuizEvaluation = true;
            }
        }

        if (this.quiz.status === 'notStarted'){
            this.updateLobbyStatus();
        }
        

    },

    beforeDestroy(){        
        clearTimeout(this.timeOut);
        clearTimeout(this.lobbyTimeOut);
    }
   
};
</script>

<style lang="scss" scoped>

</style>