import api from '@/services/api'
import openapi from '@/services/openapi'

// STATES
const state = {
  user: null,
  initials: null,
  groupName: null,  
  groupId: null,
  isGroupAdmin: false,
  loginError: null,
  routerView : 0,
  group : null,
};

// GETTERS
const getters = {
  isAuthenticated: (state) => !!state.user, 
  isGroupAdmin: (state) => state.isGroupAdmin,  
  user: (state) => state.user,
  userInitials: (state) => state.initials,
  groupName: (state) => state.groupName,  
  currentGroupId: (state) => state.groupId,  
  currentGroup: (state) => state.group,  
  loginError: (state) => state.loginError,
  routerView: (state) => state.routerView,
  navBar: (state) => state.navBar,

};

// ACTIONS
const actions = {

  async updateCurrentGroupAction({commit}) {
    let group;
    let currentUserIsAdmin;
    await api.get("currentGroup")
    .then(async (res) => {      
      group = res.data.group;
      currentUserIsAdmin = res.data.currentUserIsAdmin
      await commit("setGroupName", group.name);         
      await commit("setGroupId", group._id);
      await commit("setGroup", group);    
      await commit("setGroupAdminInfo", currentUserIsAdmin);      
    })
    .catch(async (error) => {
      await commit("setGroupName", null);         
      await commit("setGroupId", null); 
      await commit("setGroup", null); 
      await commit("setGroupAdminInfo", false);     
    })
    
    // console.log('Group State commited:', group);
  },

  async loginAction({commit}, credentials) {
    // console.log(credentials)
    await openapi.post("login", credentials)
    .then(async (res) => {      
      console.log(res)
      let user = res.data;      
      await commit("setUser", user);

      let initials = user.prename.charAt(0) + user.surname.charAt(0);
      await commit("setInitials", initials);

      await commit("setLoginError", null);      
      // console.log('User State commited:', user);
      await this.dispatch('updateCurrentGroupAction');  
      await this.dispatch('updateMyGroupsAction'); 
      // await this.dispatch('updateCurrentQuiz'); 
      
      // await this.dispatch('updateNavBar'); 

    })
    .catch(async (error) => {      
      try {
        await commit("setLoginError", error.response.data.errors);
      }
      catch (error) {
        console.log(error);
      }            
    });
  },

  async saveLoginInfo({commit}, user){
    await commit("setUser", user);
    // console.log('User State commited:', user);
    let initials = user.prename.charAt(0) + user.surname.charAt(0);
    await commit("setInitials", initials);    
    await this.dispatch('updateCurrentGroupAction');    
    await this.dispatch('updateMyGroupsAction'); 
    await this.dispatch('updateCurrentQuiz'); 
    // await this.dispatch('updateNavBar'); 
  },

  async logoutAction({ commit }) {
    await openapi.get("logout")
    let user = null;    
    await commit("logout", user)
    await commit("setGroupName", false);   
    await commit("setGroupAdminInfo", false);     
  },

  async updateRouterView({commit}){
    let routerView = this.getters.routerView + 1;
    await commit("updateRouterView",routerView);
  },

  // async updateNavBar({commit}){
  //   let navBar = this.getters.navBar + 1;
  //   await commit("updateRouterView",navBar);
  // }
};

// MUTATIONS
const mutations = {
  setUser(state, user) {
    state.user = user;
  },

  setInitials(state, initials){
    state.initials = initials;
  },

  setGroupName(state, groupName) {
    state.groupName = groupName;
  },
  
  setGroupId(state, groupId) {
    state.groupId = groupId;
  },

  setGroup(state, group) {
    state.group = group;
  },

  setGroupAdminInfo(state, isGroupAdmin) {
    state.isGroupAdmin = isGroupAdmin;
  },

  logout(state, user) {
    state.user = user;
  },

  setLoginError(state, error) {
    state.loginError = error;
  },

  updateRouterView(state, routerView){
    state.routerView = routerView;
  },

  

  // updateRouterView(state, navBar){
  //   state.navBar = navBar;
  // }
};


// EXPORT
export default {
  state,
  getters,
  actions,
  mutations,
};
