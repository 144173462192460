<template>

     <div v-if="this.displayStats">



       

            <StatsCard
                :stats="dataTable('multipleChoiceCorrectRate','des')"
                :header="'Am besten multiple-choost hat ...'"
                :unit="'%'"
                :toFixed="0"                
            />

            <StatsCard
                :stats="dataTable('estimationPointsMean','des')"
                :header="'Am besten geschätzt hat ...'"
                :unit="'P'"
                :toFixed="1"                
            />

            <StatsCard
                :stats="dataTable('answersCopiedSum','des')"
                :header="'Am häufigsten wurden die Antworten kopiert von ... '"
                :unit="''"
                :toFixed="0"                
            />

            <StatsCard
                :stats="dataTable('correctAnswersToMyQuestionsMean','asc')"
                :header="'Nur die Wenigsten wussten die richtige Antwort auf die Fragen von ...'"
                :unit="'%'"
                :toFixed="0"
                :explanation="'richtige Antworten'"
            />

            <StatsCard
                :stats="dataTable('fastestCorrectAnswerTime','asc')"
                :header="'Die schnellste korrekte Antwort kam von ...'"
                :unit="'s'"
                :toFixed="1"
            />

            <StatsCard
                :stats="dataTable('meanAnswerTime','des')"
                :header="'Durchschnittlich am längsten für eine Antwort brauchte ...'"
                :unit="'s'"
                :toFixed="0"                
            />


         
        
    </div>
                
</template>

<script>
import api from '@/services/api'
import StatsCard from '@/components/quizzes/StatsCard.vue'

export default {
    name: 'QuizStats',
    components : {StatsCard},
    
    props:['quiz'],


    data() {
        return {
            stats : null,
            displayStats : false,

        };
    },

    methods: {


        dataTable (fieldname, order) {
            let data = [];
            let i=0; 
            let j=0;
            while (i < this.stats.length){
                if (this.stats[i].hasOwnProperty(fieldname) && this.stats[i][fieldname] !== null){
                    data[j] = {
                        name : this.stats[i].name,
                        initials : this.stats[i].user.prename.charAt(0) + this.stats[i].user.surname.charAt(0),
                        value : this.stats[i][fieldname]
                    }                    
                    j++;
                }
                i++;
            }
            if (order === 'asc'){
                data.sort((a,b) => {return (a.value-b.value)});
            } else {
                data.sort((a,b) => {return (b.value-a.value)});
            }        
            return data;
        },
        
    },

    computed : {
    
    },

    async mounted() {      
        
        await api.get('quizStats/' + this.quiz._id)
        // await api.get('quizStats/')
        .then(res => {
            this.stats = res.data;               
        })
        .catch(error => console.log(error.response));
        this.stats.forEach(stat => stat.name = stat.user.prename + ' ' + stat.user.surname)        

        
        this.displayStats = true;

    },
}
</script>

<style>

</style>