<template>
<div>   
    <div>    
        <b-card class="cardHome" >
        <template #header>
            <span class="cardHeader">Medaillenspiegel</span>
        </template>
        
        <!-- Header Row -->
        <b-row class="mb-1"> 
            <b-col cols = 9>
            </b-col>
            <b-col cols = 1 class="text-center p-0">
                <b-img :src="require('@/assets/medals/icons8-1st-place-medal-48.png')" style="width:30px;height:30px;" ></b-img>
            </b-col>
            <b-col cols = 1 class="text-center p-0">
                <b-img :src="require('@/assets/medals/icons8-2nd-place-medal-48.png')" style="width:30px;height:30px;" ></b-img>
            </b-col>
            <b-col cols = 1 class="text-center p-0">
                <b-img :src="require('@/assets/medals/icons8-3rd-place-medal-48.png')" style="width:30px;height:30px;" ></b-img>
            </b-col>
        </b-row>

        <!-- Rable Rows -->
        <div v-if="showTable">
            <b-row class="mb-1" v-for="user in medalTableData" :key="user.name">
            <b-col align-self="center" cols="9">
                <b-avatar size="30px" class="avatarAdminSmall mr-1">
                    {{user.initials}}
                </b-avatar>
                {{user.name}}
            </b-col>                        
            <b-col align-self="center" cols = 1 class="text-center p-0">
                {{user.numQuizWin1st}}
            </b-col>
            <b-col align-self="center" cols = 1 class="text-center p-0">
                {{user.numQuizWin2nd}}
            </b-col>
            <b-col align-self="center" cols = 1 class="text-center p-0">
                {{user.numQuizWin3rd}}
            </b-col>
        </b-row>  
        </div>
        
        <div v-else>
            <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </div>
        

        
    </b-card>
    </div>

</div>
    
</template>

<script>
export default {
    name: 'MedalTable',
    props : ['stats'],

    data() {
        return {
            medalTableData : [],
            showTable : false,
        };
    },

    mounted() {      
        
        for (let i = 0; i < this.stats.length; i++){
            this.medalTableData[i] = {
                name : this.stats[i].name,
                initials : this.stats[i].user.prename.charAt(0) + this.stats[i].user.surname.charAt(0),
                numQuizzesPlayed : this.stats[i].numQuizzesPlayed,
                numQuizWin1st : this.stats[i].numQuizWin1st,
                numQuizWin2nd : this.stats[i].numQuizWin2nd,
                numQuizWin3rd : this.stats[i].numQuizWin3rd,
            }
        }

        this.medalTableData.sort((a,b) => {            
           if (a.numQuizWin1st - b.numQuizWin1st > 0) {
               return -1;
           }
           if (b.numQuizWin1st - a.numQuizWin1st > 0) {
               return 1;
           }
           if (a.numQuizWin2nd - b.numQuizWin2nd > 0) {
               return -1;
           }
           if (b.numQuizWin2nd - a.numQuizWin2nd > 0) {
               return 1;
           }
           if (a.numQuizWin3rd - b.numQuizWin3rd > 0) {
               return -1;
           }
           if (b.numQuizWin3rd - a.numQuizWin3rd > 0) {
               return 1;
           }
           if (a.numQuizzesPlayed - b.numQuizzesPlayed > 0) {
               return -1;
           }
           if (b.numQuizzesPlayed - a.numQuizzesPlayed > 0) {
               return 1;
           }

           return 0;
        }); 

        this.medalTableData = this.medalTableData.filter(data => (data.numQuizWin1st + data.numQuizWin2nd + data.numQuizWin3rd) > 0)

        this.showTable = true;

    },     


    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>