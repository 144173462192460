<template>
    <div>        
        <div>
            <b-table                              
                :items="tableData" 
                responsive
                :fields="fields"
                small                       
                ></b-table>
        </div>
        <div v-if="dispPlayButton">
            <b-icon @click="playClick" icon="play-circle-fill" class="buttonStandard" style="width:35px;height:35px;"></b-icon>
        </div>
        <div v-if="dispFordwardButton">
            <b-icon @click="forwardClick" icon="skip-forward-circle-fill" class="buttonStandard" style="width:35px;height:35px;"></b-icon>
        </div>
    </div>
</template>

<script>
import api from '@/services/api'
export default {
    name: 'Ranking',
    
    props:['quiz', 'columns', 'update', 'highlightUserId', 'evolve'],

    data() {
        return {
            ranking : null,
            fields : null,
            tableData : null,

            evolveActive : false,
            evolveIndex : null,
            evolveAutomaticallyUntilPosititon : 3,
            evolveSlowly : 3000,
            evolveFastly : 300,
            

            labelLookUpKey :    ['numberJokersAvailable' ,  'numberPostedQuestionsPlayed',  'numberCorrectMultipleChoiceAnswers',   'pointsCorrectMultipleChoiceAnswers', 'pointsGoodEstimations' , 'pointsPostedQuestions', 'pointsTotal'],
            labelLookUpLabel :  ['Joker verfügbar' ,       'Eingereichte Fragen',          'Korrekte Antworten',                   'Multiple Choice Punkte'              , 'Schätzfragen Punkte' ,  'Eigene Fragen Punkte', 'Gesamt Punkte'],                
        
        };
    },

    computed : {
              
    },
    
    methods: {
        async updateRanking() {
            await api.get('quizRanking/' + this.quiz._id)
            .then(async (res) => {                    
                this.ranking = res.data.filter(result => result.position !== null); 
                this.ranking.name = this.ranking.user.prename + ' ' + this.ranking.user.surname
            })
            .catch(error => console.log(error.response)); ; 
        },

        updateTableData(){    
            this.tableData = this.ranking;
            this.tableData.forEach(item => {
                item.name = item.user.prename + ' ' + item.user.surname;
                item.numberJokersAvailable = item.jokersAvailable.length;
                item.highlight = this.highlightUserId === item.user._id ? true : false;
                item._rowVariant = item.highlight ? 'primary' : null ;
            });

        },

        completeTableData(item){            
            item.name = item.user.prename + ' ' + item.user.surname;
            item.numberJokersAvailable = item.jokersAvailable.length;
            item.highlight = this.highlightUserId === item.user._id ? true : false;
            item._rowVariant = item.highlight ? 'primary' : null ;
            return item;
        },

        labelLookUp(key){
            let i = this.labelLookUpKey.indexOf(key);
            if (i > -1){
                return this.labelLookUpLabel[i];
            } else {
                return undefined;
            }
        },

        async evolveTable(){            
            // First execution
            if (this.tableData === null) {
                let n = this.ranking.length;
                this.tableData = [];
                for (let i = 0; i<n; i++){
                    this.tableData.push({
                        name : '',
                        numberJokersAvailable : '',
                        highlight : false,
                        _rowVariant : null,
                        pointsPostedQuestions : '',
                        numberMultipleChoiceAnswers : '',
                        numberCorrectMultipleChoiceAnswers : '',
                        pointsCorrectMultipleChoiceAnswers : '',
                        numberEstimations : '',
                        numberGoodEstimations : '',
                        pointsGoodEstimations : '',
                        pointsTotal : '',
                        position : '',                        
                    })
                }
                this.evolveIndex = n-1;                
            }

            else if (this.evolveIndex > -1) {
                let item = this.completeTableData(this.ranking[this.evolveIndex]);
                this.tableData.splice(this.evolveIndex,1,item)
                if (this.evolveIndex === 0){
                    await this.$emit('evolutionComplete');
                }
                this.evolveIndex -= 1;                
            }

            if (this.evolveActive && this.evolveIndex > -1 && 
                (this.ranking[this.evolveIndex].position === this.ranking[this.evolveIndex + 1].position ||
                this.ranking[this.evolveIndex].position === 1))
            {                    
                setTimeout(this.evolveTable,this.evolveFastly);
            } 
            else if (this.evolveActive && this.evolveIndex + 1 > this.evolveAutomaticallyUntilPosititon)
            {
                setTimeout(this.evolveTable,this.evolveSlowly);
            }
            
        },

        forwardClick(){            
            if (this.evolveIndex + 1 <= this.evolveAutomaticallyUntilPosititon){
                this.evolveTable();
            }
        },

        playClick(){            
            this.evolveActive = true;   
            this.evolveTable();         
        }
    },

    computed : {
        dispFordwardButton : function (){
            if (this.evolve && this.evolveIndex + 1 <= this.evolveAutomaticallyUntilPosititon && this.evolveIndex > -1){
                return true;
            } else {
                return false;
            }
        },

        dispPlayButton : function (){
            if (this.evolve && this.evolveActive === false){
                return true;
            } else {
                return false;
            }
        }

    },



    async mounted() {       
        // set up headings
        this.fields = [
            {key: 'position', label: 'Pos' },
            {key: 'name', label: 'Spieler:in' , stickyColumn : true}];
        if (this.columns){
            this.columns.forEach(key => {
                if (key === 'pointsTotal'){
                    // this.fields.push({key: key, label: this.labelLookUp(key)})
                    this.fields.push({key: key, label: this.labelLookUp(key), variant : 'primary'})
                }
                else {
                    this.fields.push({key: key, label: this.labelLookUp(key)})
                }                
            });
        }

        await this.updateRanking();
        if (this.evolve === true){
            this.evolveTable();
        }
        else {
            this.updateTableData();
        }

        


    },
}
</script>

<style lang="scss" scoped>

</style>