<template>
    <div>
        <div class="container h-100" style="margin-top: 40px">
            <div class="d-flex justify-content-center h-100">
                <div class="user_card">
                    <div class="d-flex justify-content-center">
                        <div class="brand_logo_container">
                            <img src="../assets/QLogo.png" class="brand_logo" alt="Logo">                            
                        </div>
                    </div>


                    <div v-if="mailAvailable" class="d-flex justify-content-center form_container">
                        
                        <b-form v-on:submit.prevent="onSubmit">
                            <div class="input-group mb-2">
                                <div class="input-group-append">
                                    <span class="input-group-text"><i class="fas fa-user"></i></span>
                                </div>    
                                <b-form-input class="form-control input_user" v-model="email" placeholder="E-Mail" :state="validationState"></b-form-input>
                            </div>
                            

                            <div class="d-flex justify-content-center mt-3 login_container">                               
                                <button type="button" name="button" class="btn login_btn" @click="onSubmit">Neues Passwort anfordern</button>
                            </div>
                            <div>
                                <p>{{validationErrorString}} </p>
                            </div>                        
                        </b-form>
                    </div>
                    <div v-else class="d-flex justify-content-center form_container">
                        Frag Felix ...
                    </div>
                </div>
            </div>        
        </div>

        <b-modal ref="my-modal" hide-footer no-close-on-esc no-close-on-backdrop hide-header>
            <div class="d-block text-center">
                <p>Schau mal in deine Mails, da sollte dein neues Passwort sein ...</p>
                <b-button class="btn login_btn" @click="$router.push('login')">Zur Login Seite</b-button>
            </div>
        </b-modal>
        
  </div>
</template>

<script>
import openapi from '@/services/openapi'

export default {    

    data() {
        return {
            email : '',                        

            validationState : null,
            validationErrorString : '',
            
            mailAvailable : true    
        };
    },
    computed: {

    },
    
    mounted() {
        
    },

    methods: {

        async onSubmit () {
            console.log('Submit fired');
            if (this.email){
                this.validationState = null;
                const body = {email : this.email};

                await openapi.post("resetpassword", body)
                .then((res) => {                      
                    this.$refs['my-modal'].show();            
                    console.log(res);         
                })
                .catch(error => {
                    this.validationState = false;
                    // this.validationErrorString = 'E-Mail Adresse nicht registriert.'; 
                    console.log(error.response.data)
                })

            } else {
                this.validationState = false;  
                this.validationErrorString = '';                
            }            
        }
        
       
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>