<template>
<b-container fluid class="containerHome">

        <b-row>
            <b-col>
                <div class="mt-5 mb-5 ml-2 mr-2 cardHeader" style="font-size:1.8rem; text-align: left;">
                    <b>Unsere Quizabende</b>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="dispNoQuizzes" class="mt-5 mb-5 ml-2 mr-2" style="font-size:1rem; text-align: left;">
                <div>
                    <p>Bisher wurden keine Quizabende in deiner Quizgruppe <b>{{$store.getters.groupName}}</b> gespielt.</p>
                </div>    
            </b-col>
            <b-col v-else>
                <div v-for="quiz in endedQuizzes" :key="quiz._id">
                    <QuizCard v-bind:quiz="quiz"/>
                </div>
        
            </b-col>
            
            
        </b-row>
</b-container>
</template>

<script>
import api from '@/services/api'
import QuizCard from "@/components/QuizCard.vue";

export default {
    name : 'Quizzes',
    components: {QuizCard  },        
    // directives: { DirectiveName },
    data() {
        return {
            endedQuizzes : null,
            
            dispEndedQuizzes : false,
            dispNoQuizzes : false,        
            

        };
    },    
    methods: {
            
            
   

        
    },

    async mounted() {
        await api.get("quizzes")
        .then(async res => {
            let allQuizzes = res.data;
            this.endedQuizzes = allQuizzes.filter(quiz => quiz.status === 'over');
            
            // Sort by Date
            this.endedQuizzes.sort((a ,b) => {                
                    if ( a.quizDate > b.quizDate ){return -1;}
                    if ( a.quizDate < b.quizDate ){return +1;}
                });

            if (this.endedQuizzes.length > 0){                
                await this.updateTableData();
                setTimeout(() => {
                    this.dispEndedQuizzes = true; 
                }, 500);
            } 
            else {
                this.dispNoQuizzes = true;
            }
            
        })
        .catch(error => console.log(error.response));
        
    },
};
</script>

<style lang="scss" scoped>

</style>