<template>
    <div>
        <div v-if="showCmp">
            <b-row @click="showQuizQuestions=!showQuizQuestions">
                <b-col>
                    <div class="mt-3 mb-1 ml-1 mr-1 cardHeader" style="font-size:1.4rem; text-align: left;">
                        <b>{{quizHeader}}</b>
                    </div>
                </b-col>
                <b-col cols="auto" class="mt-3 mb-1 ml-1 mr-1" style="text-align: right;">
                    <b-icon-chevron-down v-if="!showQuizQuestions"></b-icon-chevron-down>
                    <b-icon-chevron-up v-if="showQuizQuestions"></b-icon-chevron-up>
                </b-col>
            </b-row>
                <div v-show="showQuizQuestions">
                    <QuizQuestions 
                        :quiz="quiz"
                        :myQuestionsOnly="showMyQuestionsOnly"
                    />
                </div>
            </div>
                

    </div>
</template>

<script>
import QuizQuestions from '@/components/quizzes/QuizQuestions.vue';

export default {
    name: 'QuizQuestionsCmp',
    props: ['quiz','showMyQuestionsOnly'],
    components: { QuizQuestions },
    

    data() {
        return {
            showQuizQuestions : false,
            showCmp : false,
        };
    },

    mounted() {
        let numberMyQuestions = this.quiz.questions.filter(question => String(question.user._id) === String(this.$store.getters.user._id)).length;
        if (numberMyQuestions > 0){
            this.showCmp = true;
        }
    },

    computed : {
        quizHeader: function() {
            let now = new Date();
            let quizDate = new Date(this.quiz.quizDate);         
            const dateOptions = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'};            
            let quizDateString = quizDate.toLocaleDateString('de-DE', dateOptions); 
            return quizDateString; 
        },
    },

    methods: {
       
    },
};
</script>

<style lang="scss" scoped>

</style>