<template>
    <div>        
    </div>
</template>

<script>
export default {
    name: 'GroupInvitation',
    // components: { GroupInvitation },
    // directives: { DirectiveName },
    data() {
        return {
            
        };
    },
    async mounted() {
        await this.$store.dispatch('updateGroupInvitation', this.$route.params.id);
        this.$router.push('/')
    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>