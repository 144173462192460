import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth";
import invitiation from "./modules/invitiation";
import quiz from "./modules/quiz";
import groups from "./modules/groups"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    groups,
    invitiation,
    quiz,
    
  },
  // plugins: [createPersistedState()],
})
